import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { theme } from 'styles/theme';

const ModalDefault = ({
                          isOpen,
                          closeModal,
                          closeButton,
                          label,
                          children,
                          centered= true,
                          size= "",
                          backdrop = true,
                          keyboard = true,
}) => {
    return (
        <Modal  isOpen={isOpen} toggle={closeModal} centered={centered} backdrop={backdrop} size={size} keyboard={keyboard}>
            <ModalHeader className="border-0">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <p className="ff-bold fs-4 px-3"
                           style={{color: theme.black, margin: 0, paddingBottom: 0, marginLeft: 0}}>
                            { label }
                        </p>
                    </div>
                    <div>
                        { closeButton &&
                            <span role="button" className="position-absolute btn-close"
                                  onClick={() => closeModal()}
                                  style={{ right: "1rem", top: "1rem"}}
                            />
                        }
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="py-4 pt-0 d-grid gap-3 rounded-bottom" >
                { children }
            </ModalBody>
        </Modal>
    );
}

export default ModalDefault;