import { useState } from "react";

import { CardCollect, CardContentText } from "./styles";
import { useApp } from "../../web3/providers/AppContext";
import { calcPriceBrlNft, limitarString } from "utils/utils";
import { useNavigate } from "react-router-dom";
import { networkParams } from "web3/networks/networks";
import ModalDefault from "../ModalDefault";
import { Share } from "../Share";
import { UncontrolledTooltip } from "reactstrap";
import EthereumImg from "../../assets/img/svg/ethereum.svg";
import PolygonImg from "../../assets/img/svg/polygon.svg";
import { useTranslation } from 'react-i18next';

const CardCollection = ({ collection }) => {
  const { symbolImage, name, shortDescription } = collection;
  const { t, i18n } = useTranslation();
  const { wallet, connectWallet } = useApp();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const network = networkParams[collection.network].crypto;

  const BRL = calcPriceBrlNft(1, network, collection.nftEtherValue);

  const toggleModal = async () => {
    if (!wallet) {
      const connection = await connectWallet();
      if (connection.address) {
        setIsOpen(!isOpen);
      }
    } else {
      setIsOpen(!isOpen);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleRedirectToCollection = () => {
    if(collection.status === "submitted" || collection.status === "blank" || collection.status === "denied"){
      return
    }
    navigate("/collection/" + collection.id);
  };

  

  return (
    <CardCollect>
      <div className="card">
        <div className="card-image mx-2 mt-2">
          <img
            src={symbolImage}
            className="img-fluid rounded-4 border-3 w-100"
            alt=""
            style={{ height: "400px", objectFit: "cover" }}
            role="button"
            onClick={() => handleRedirectToCollection()}
          />
          <UncontrolledTooltip
            placement="right"
            target={`chain_${collection?.id}`}
          >
            Rede: {networkParams[collection.network].network_name}
          </UncontrolledTooltip>

          <span className="chain" role="button" id={`chain_${collection?.id}`}>
            <img
              src={network === "ETH" ? EthereumImg : PolygonImg}
              width={25}
              alt=""
            />
          </span>
        </div>

        <div className="card-body d-grid gap-0 py-2">
          <div
            style={{display:'flex', justifyContent:'space-between'}}
            role="button"
            onClick={() => handleRedirectToCollection()}
          >
            <strong>{name}</strong>
            {collection.status === "submitted" && <strong>{t('review')}</strong>}
            {collection.status === "denied" && <strong style={{color:'tomato',fontSize:'12px',marginTop:'4px',display:'block'}}>{t('collectionDenied')}</strong>}
          </div>

          <p className="ff-bold">
            <span className="ff-normal">
              {shortDescription.length > 75
                ? limitarString(shortDescription, 75)
                : shortDescription}
            </span>
          </p>

          <CardContentText className="pb-0">
            <span className="ff-bold w-50">
              <div className="mb-4">
                <span className="ff-bold">{t('price')}</span>
                <h4 className="ff-medium lh-1">
                  {collection.nftEtherValue} {network}
                </h4>
                <h4 className="ff-medium lh-1">{BRL}</h4>
              </div>

              <small >
                {t('donationFund')}:{" "}
                {collection.crowd_fund_first_sale} %
              </small>
            </span>
            <div className="w-50 d-flex flex-column gap-3 float-end">
              <button
                className="btn py-3 btn-secondary rounded-pill"
                onClick={() => handleRedirectToCollection()}
              >
               {t('buy')}
              </button>
              <button
                className="btn btn-outline-secondary rounded-pill py-3"
                onClick={toggleModal}
              >
                share and earn
              </button>
            </div>
          </CardContentText>
        </div>
      </div>
      <ModalDefault
        isOpen={isOpen}
        closeModal={closeModal}
        label={t('share')}
        centered={true}
        size="sm"
      >
        <Share collectionId={collection.id} />
      </ModalDefault>
    </CardCollect>
  );
};
export default CardCollection;
