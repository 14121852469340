import React, {createContext, useContext, useEffect, useState} from "react";

import { Loading } from "components/Loading";
import {useParams} from "react-router-dom";
import {getCollection, getImage} from "../../services/collection.service";
import {networkParams} from "../networks/networks";

export const CollectionContext = createContext(0);

export const CollectionProvider = (props) => {

    const [loading, setLoading] = useState(true)
    const [collectionData, setCollectionData] = useState({})
    const [nft, setNftData] = useState({})

    const { collection, id } = useParams()

    useEffect(() => {
        const request = async () => {
            const response = await getCollection(collection);
            setCollectionData(response)            
            const data = await getImage(id, response);            
            setNftData(data);
        }
        request().then(() => cancelLoading())
    }, [])
    
    const crypto = networkParams[collectionData?.network]?.crypto

    const cancelLoading = () => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    if (loading) return <Loading />

    return (
        <CollectionContext.Provider
            value={{
                collectionData,
                nft,
                crypto
            }}>
            {props.children}
        </CollectionContext.Provider>
    )
}

export const useCollection = () => useContext(CollectionContext);
