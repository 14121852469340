import api from "./api";

export const createRfq = (data) => {
    return api.post('/createOrder', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const updateRfq = (data) => {
    return api.post('/createOrder', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const generateEmailCode = (data) => {
    return api.post('/generateEmailCode', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const checkEmailCode = (data) => {
    return api.post('/checkEmailCode', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const createInvoice = (data) => {
    return api.post('/createPix', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}


export const checkPixStatus = (data) => {
    return api.post('/checkPixStatus', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}