import api from "./api";

export const newsletter = (data) => {
    return api.post('/newsletter', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const getEmails = () => {
    return api.get('/getNewsletter')
    .then(response => response.data)
    .catch(error => console.log(error))
}

