import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

export const ModalAdmin = ({
  isOpen,
  closeModal,
  label,
  children,
  location,
}) => {
  return (
    <Modal
      isOpen={true}
      size="lg"
      toggle={closeModal}
      backdrop={true}
      id="modal"
    >
      {label && (
        <ModalHeader toggle={closeModal}>
          <span className="fw-bold fs-4">{label}</span>
        </ModalHeader>
      )}
      <ModalBody className="py-4 d-grid gap-3 bg-grey-800 rounded-bottom">
        {children}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-3 align-content-between">
          {location === 'dashboard' ? (
            <button className="btn btn-outline-primary" onClick={closeModal}>Fechar</button>
          ) : (
            <Link className="btn btn-outline-primary" to={`/admin/${location}`}>
              Fechar
            </Link>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
