import styled from 'styled-components';

export const Container = styled.section `
    .d-info-card{
        background-color: #12121a;
        border-top: 4px solid #d63384;
        // min-height: 180px;
        height: 100%;

        display: flex;
        gap: 1rem;
        flex-direction: row;
    }

    .d-info-card h3 > span{
        font-size: 60%;
        font-weight: normal;
    }

    .d-section-title h2{
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        margin: 0;
    }
`;

export const Content = styled.div`
    .card {
        background-color: ${({theme}) => theme.grey300};
        border-radius: 20px;
    }
    
    .card .icon-head-card {
        height: 1.4em;
    }
    
    .change-image {
        font-size: 12px;
        display: none;
        text-align: center;
        margin-top: 15px;
        cursor: pointer;
    }
    
    .image-account {
        align-self: center;
        width: 100px;
        height: 100px;
        border-radius: 20px; 
        margin-top: -48px; 
        background-color: #333333;
        outline: 4px solid #FFFFFF;
        transition: 0.3s;
    }
    
     .image-account:hover {
          .change-image {
            display: block;
          }
        background-color: #232323;
     }
    
    .sidebar {
        background-color: ${({theme}) => theme.grey300};
        border-radius: 20px;
    }
    
    .nav-link {
        color: #FFFFFF;
    }
    
    .nav-link i {
        font-size: 24px;
    }
    
    .nav-link:hover i {
        color: ${({theme}) => theme.primary};
    }
    
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: ${({theme}) => theme.primary}10;
        font-weight: bold;
    }
    
    .nav-pills .nav-link.active i, .nav-pills .show > .nav-link i {
        color: ${({theme}) => theme.primary};
    }
    
    .nav-pills .nav-item .nav-link {
        align-items: center;
        display: flex;
        gap: 10px;
        border-radius: 0;
    }

    
`;

export const HomeContent = styled.div`
     .grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        margin-bottom: 40px;

        @media (max-width: 768px) { 
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .item{
        background-color: #30353f;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        color: white;
        height: 150px;
        width:350px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`
