import { Outlet } from 'react-router-dom'

export const LayoutLogin = () => {
    return (
        <div className="bg-light">
            <div className="container">
                <div className="row min-vh-100 flex-center g-0 justify-content-center align-items-center">
                    <div className="col-12 col-md-5">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>

    )
}
