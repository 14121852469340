import * as S from '../styles'
import { useEffect, useState,useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isInteger } from 'lodash';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {checkPixStatus} from "../../../services/pix.service";
import { updatePinata } from "services/contract.service";

import { useApp } from "web3/providers/AppContext";
import { ErrorStep } from './errorStep';
import { useParams, useLocation } from "react-router-dom";
export const  SixthStepPix = ({rfqId,nft,changeStep, nftBought,collectionData,setIsOpenToBuy, closeModal}) => {

const {  wallet } = useApp();

const [countDown, setCountDown] = useState(300);
const [reqStatus, setReqStatus] = useState('');
const [activeStep, setActiveStep] = useState(1);
const [pixApiError, setpixApiError] = useState(false);
const timerId = useRef();
const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;
const pixRecivded = reqStatus === "RFQ_STATUS_FULFILLED" ? true : false
const processValidation = reqStatus === "RFQ_STATUS_SETTLEMENT" || "RFQ_STATUS_FULFILLED"  ? true : false
const processed = reqStatus === "RFQ_STATUS_SETTLEMENT" || "RFQ_STATUS_FULFILLED"  ? true : false
const validated = reqStatus === "RFQ_STATUS_SETTLEMENT" || "RFQ_STATUS_FULFILLED"  ? true : false
const rfqFirestore =  process.env.REACT_APP_RFQS_FB;
const location = useLocation();

const affiliate = ( wallet ) => {
  const queryParams = new URLSearchParams(location.search);
  const affiliateAddress = queryParams.get("address");

  return {
      wallet:  affiliateAddress ? affiliateAddress : wallet,
      type: affiliateAddress ? "2" : "1"
  }
};

useEffect(() => {
    timerId.current = setInterval( () => {
    setCountDown( (prev) =>  { 

      if(isInteger(prev/5)) {
        const checkStatus =  async () => {
            const response = await checkPixStatus({
              rfqId: rfqId.id,
              network: nft.network,
              rfqFb: rfqFirestore
            })
            
            if(response.rfq.status === "RFQ_STATUS_SETTLEMENT") {
              setReqStatus("RFQ_STATUS_SETTLEMENT");

            }

            if(response.rfq.status === "RFQ_STATUS_REJECTED") {
              setpixApiError(true);
            }

            if(response.rfq.status === "RFQ_STATUS_FULFILLED") {
              const checkAfiliation = affiliate(wallet);

              setReqStatus("RFQ_STATUS_FULFILLED");
              await updatePinata(
                JSON.stringify({
                    collection: nft.collection,
                    image: nft.imageId,
                    wallet: wallet,
                    rfqId: rfqId.id,    
                    nfts: nftBought,
                    pix: true,
                    imagesFB: imagesFirestore,
                    affiliateWallet: checkAfiliation.wallet,
                    affiliateType: checkAfiliation.type
                }),
              );
              localStorage.removeItem(nft?.imageId);  
              setIsOpenToBuy(true);
              setTimeout(() => {
                changeStep();
              }
              , 3000);
            }
        };
        checkStatus();
      } 
      
      return prev - 1

    });

    const activeStepInterval = setInterval(() => {
              setActiveStep(
                (prev) => prev + 1
              );
            }
            , 6000);

            if(activeStep > 3 ){
              clearInterval(activeStepInterval);
          }
    
  }, 1000);
  
  return () => clearInterval(timerId.current);

}, []);

useEffect(() => {
  if(countDown <= 0){
    clearInterval(timerId.current);
  }    
}, [countDown]);

if(pixApiError){
  return(
    <ErrorStep errorMsg={"Problema na transação da blockchain. Seu pix será estornado !"} buttonLabel={'Refazer compra'} changeStep={closeModal}/>
  )
}

return(
    <S.CheckoutContainer>
      <h4>
        Pedido {rfqId.id}
      </h4>

      <S.PixContent>
        <h3>{nft?.symbol}</h3>
        <span>{collectionData?.name}</span>
        <S.CheckStatus>
          <FontAwesomeIcon icon={faCheck} size='lg' className={validated && activeStep >= 1 ? "default active" : "default"} />
          <S.Separator/>
          <FontAwesomeIcon icon={faCheck} size='lg' className={ processValidation && activeStep >= 2 ? "default active" : "default"} />
          <S.Separator/>
          <FontAwesomeIcon icon={faCheck} size='lg' className={ processed  && activeStep >= 3 ? "default active" : "default"} />
          <S.Separator/>
          <FontAwesomeIcon icon={faCheck} size='lg' className={pixRecivded ? "default active" : "default"} />
        </S.CheckStatus>
      </S.PixContent>

      <S.StatusContent >
        <span className={validated && activeStep >= 1 && "active"}>Validando na Rede</span>
        <span className={processed &&  activeStep >= 2 &&"active"}>Pedido Validado</span>
        <span className={processValidation && activeStep >= 3 && "active"}>Processando seu pedido</span>
        <span className={pixRecivded &&  "active"}>Pix recebido com sucesso</span>
      </S.StatusContent>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}