
import {darkTheme} from "../styles/theme";
import GlobalStyle from "../styles/globalStyle";
import Navbar from "../components/Navbar";

import Footer from "../components/Footer";
import {ThemeProvider} from "styled-components";

import { Outlet } from 'react-router-dom'
import SideBarAccount from "components/SidebarAccount";

export const LayoutApp = () => {

    return (
        <ThemeProvider theme={darkTheme}>
            <GlobalStyle />
            <Navbar/>
            <SideBarAccount />
            <div className="container-page">
                <div className="wrapper">
                    <Outlet />
                </div>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}
