
import { createRef, useEffect, useState } from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { walletFormat } from "utils/utils";
import {auth} from '../../firebase'

import {useApp} from "../../web3/providers/AppContext";
import useAuth from "hooks/useAuth";
import { ConnectModal } from "components/ConnectModal";
import { GoogleConectButton } from "components/ConnectModal/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from 'react-i18next';
import brIcon from '../../assets/img/flags/br.png'

import Metamask from "../../assets/img/svg/metamask.svg";
import { ButtonConnectContainerNav, FlagIcon, NavContainer } from "./styles";
import { LanguageSelector } from "components/LeanguageSelecor";


const headerRef = createRef();

const Navbar = () => {
    const {googleAccount,setGoogleAccount} = useAuth()
    const { opacity, layoutHeader, wallet,disconnectWallet,buttonText } = useApp()
    const { pathname } = useLocation();
    const  [showModal,setShowModal] = useState(false)
    const { t, i18n } = useTranslation();
    
    const showConnectButton = !wallet && !googleAccount?.email


    useEffect(() => {
        const headerHeight = headerRef.current.clientHeight;
        const range = 200;
        const offset = headerHeight / 2;

        const didScrollPage = e => {
            let calc = 1 - (window.scrollY - offset + range) / range;

            let status = 1;
            if (calc < 0) {
                status = 0;
            }
            if (pathname === '/') {
                layoutHeader(status);
            }
        };

        if (pathname === '/') {
            window.addEventListener("scroll", didScrollPage);

            return () => {
                window.removeEventListener("keydown", didScrollPage);
            };
        } else {
            window.removeEventListener("scroll", didScrollPage);
            return () => {
                window.removeEventListener("scroll", didScrollPage);
                window.removeEventListener("keydown", didScrollPage);
            };
        }

    }, [pathname, layoutHeader]);

    const handleToggleModal = () => {
        setShowModal(state => !state)
    }

    const handleDisconnectWallet = () => {
        disconnectWallet()
        setShowModal(false)
    }

    const handleLogout = async () => {
        setGoogleAccount(null)
        disconnectWallet()
        await auth.signOut()
    }


    return (
        <NavContainer
            className={`navbar navbar-expand-lg pt-3  fixed-top navbar-dark 
            ${ opacity === 1 ? 'navbar-bg' : opacity === 0 ? 'navbar-bg-50' : 'navbar-default'}`}
            aria-label="navbar"
            ref={headerRef}
        >
            <div className="container">
                <Link to="/" className="navbar-brand">

                </Link>
                <button style={{border: '1px solid #ee2b7a'}} className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" ></span>
                </button>

                <div className="collapse navbar-collapse justify-content-center align-itens-center" id="navbarsExample05">

                    {/*<ul className="navbar-nav me-auto mb-2 mb-lg-0">*/}
                    <ul className="navbar-nav w-100 justify-content-center" style={{paddingTop: "0.5rem"}}>
                        <li className="nav-item">
                            <HashLink
                                smooth
                                to="/#project"
                                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
                                className="nav-link ff-bold"
                                aria-current="page"
                            >
                                {t('projectsNav')}
                            </HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink
                                smooth
                                to={'/#sharing-economy'}
                                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center'})}
                                className="nav-link text-center ff-bold"
                            >
                                share and earn
                            </HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink
                                smooth
                                to={'/#afm'}
                                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                                className="nav-link ff-bold"
                            >
                                {t('fundAfm')}
                            </HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink
                                to={'/#create-project'}
                                smooth
                                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                                className="nav-link ff-bold"
                            >
                               {t('createYourProject')}
                            </HashLink>
                        </li>
                        <li className="nav-item">
                            <LanguageSelector i18n={i18n} />
                        </li>
                    </ul>

                    <ul className="navbar-nav ms-auto align-items-center ">
                        <li className="nav-item" >
                          
                            {showConnectButton ? 
                            (<button className="btn btn-lg btn-primary rounded-pill btn-connect ff-normal" onClick={handleToggleModal}>{buttonText}</button>) 
                            : (
                                !googleAccount ? (
                                    <button className="btn btn-lg btn-primary rounded-pill btn-connect ff-normal" type="button"  onClick={handleDisconnectWallet}>
                                        <ButtonConnectContainerNav>
                                        <span>{walletFormat(wallet)} <img src={Metamask} alt="Metamask icon" /></span>
                                        </ButtonConnectContainerNav>
                                    </button>
                                ):
                                (
                                    <GoogleConectButton className="loged-button" onClick={handleLogout}>{walletFormat(wallet)} <FontAwesomeIcon icon={faGoogle} /></GoogleConectButton>
                                )
                            )}

                            
                          
                         <ConnectModal showModal={showModal} handleToggleModal={handleToggleModal} label={t('mint.connectLabel')} setShowModal={setShowModal} />
                          
                           
                           
                        </li>
                      
                        <>
                            { wallet  || googleAccount ? (
                                <li className="nav-item">
                                    <span className="nav-link py-0" data-bs-toggle="offcanvas" data-bs-target="#sidebarAccount" aria-controls="sidebarAccount">
                                        <span role="button" className="btn-account mx-auto"/>
                                        <span className="ff-normal">{t('account')}</span>
                                    </span>
                                </li>
                            ) : (
                                <li className="nav-item">
                                    
                                </li>
                            )}
                        </>
                        
                    </ul>
                </div>
            </div>
        </NavContainer>
    )
}

export default Navbar
