import styled, {css} from "styled-components";

const dragFocused = css`
    border-color: #5590c8;
`;

const dragActive = css`
    border-color: #5590c8;
`;

const dragReject = css`
    border-color: #e57878;
`;

const dragAccept = css`
    border-color: #78e5d5;
`;

export const DropContainer = styled.div.attrs({
    className:"dropzone"
})`
    border: 2px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: ${({theme}) => theme.primary};
    transition: height 0.2s ease;
    
    p {
        margin-bottom: 0;
    }
    
    ${props => props.isActive && dragActive};
    ${props => props.isFocused && dragFocused};
    ${props => props.isDragReject && dragReject};
    ${props => props.isDragAccept && dragAccept};
`;

const messageColors = {
    default: '#000',
    error: '#ee2b7a',
    success: '#2bb673'
}

export const UploadMessage = styled.p`
    font-family: 'Figtree Font Light';
    display: grid;
    color: ${props => messageColors[props.type || 'default']};
    font-weight: 500;
    text-align: center;
`;

export const IconColor = styled.div `
    text-align: center;
    font-size: 2.8em;
    margin-top: 20px; 
    color: ${props => messageColors[props.type || 'default']};
`

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    transition: 0.5s;
    position: relative;
  
    .div-img {
        transition: 0.5s;
    }
    
    .overlay {
        left: 50%;
        opacity: 0;
        transition: 0.2s;
    }
    
    &:hover {
        .overlay { 
         opacity: 1;
        }
        
        .div-img {
        opacity: 0.35;
        }
    }
`