export default {
    categories: [],
    categoriesList: [],
    benefits: '',
    network: "",
    name: "",
    url: "",
    description: "",
    short_description: "",
    external_link: "",
    symbol: "",
    discord: "",
    twitter: "",
    instagram: "",
    value: "",
    price_brl: "",

    amount: "",
    amount_wallet: "",

    file_reasons_to_believe: [],

    banner_collection: [],
    image_symbol: [],
    imagesNft: [],


    image_collection: [],
    image: [],
    file: "",

    whitelist: false,
    royalties: false,
    freemint: false,
    contract_paused: false,
    whilelist_file: "",
    value_royalties: 0,
    quantity_freemint: 0,



    wallet_artist: "",
    wallet_creator: "",
    reasons_to_believe: "",
    wallet_artist_first_sale: 0,
    wallet_creator_first_sale: 0,
    wallet_creator_second_sale: 0,
    wallet_artist_second_sale: 0,
    crowd_fund_first_sale: 1,
    begin_waitlist_date: "",
    end_waitlist_date: "",
    start_public_sale: "",
    waitlist_price: "",
    total_percentage: 15,
    total_percentage_second: 1,

    terms: false,
};
