import {useEffect, useState} from "react";

import { Content } from "./styles";
import {Link, useNavigate, useParams} from "react-router-dom";

import { getCreator } from "services/creator.service";

import { Loading } from "components/Loading";
import CardCollection from "components/CardCollection";

import {useApp} from "../../web3/providers/AppContext";
import {truncateAddress} from "../../utils/utils";
import EmptyMessage from "../../components/EmptyMessage/EmptyMessage";

export const CreatorPage = () => {

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const { layoutHeader } = useApp()
    const { param } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        layoutHeader(2)
    }, [layoutHeader])

    useEffect(() => {
        const request = async () => {
            setLoading(true)
            const response = await getCreator(param);

            if (!response.address) {
                navigate('/404')
            } else {
                setLoading(false)
                setData(response)
            }
        }
        request()
    }, [])


    if (loading) return <Loading />

    return (
        <Content>
            <div className="banner-collection bg-light">
                { data.profile_banner ?
                    <img
                        src={data.profile_banner}
                        className="img-fluid"
                        alt="banner"
                    />

                    : (
                        <div style={{height: "250px", background: "#333"}}/>
                    )
                }
                <div className="container">
                    <div className="image-collection bg-light shadow-sm rounded-4">
                        { data.profile_avatar ?
                            <img src={ data.profile_avatar }
                                className="shadow-sm"
                                alt="symbol"
                            />

                            : <div className="image-avatar-default" />
                        }
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="d-flex justify-content-between">
                    <div className="mt-4">
                        <h1 className="ff-bold">{ data.name ? data.name : truncateAddress(data.address) }</h1>

                        { data.nickname &&
                            <span className="fs-5 text-primary">{'@' + data.nickname}</span>
                        }
                    </div>

                    <div className="icons">
                        <Link to="#">
                            <i className="fa fa-globe"/>
                        </Link>
                        <Link to="#">
                            <i className="fa-brands fa-instagram"/>
                        </Link>
                        <Link to="#">
                            <i className="fa-brands fa-discord"/>
                        </Link>
                        <Link to="#">
                            <i className="fa-brands fa-twitter"/>
                        </Link>
                        <Link to="#">
                            <i className="fa fa-share-nodes"/>
                        </Link>
                    </div>
                </div>

                <div className="d-flex gap-3 mt-3 fs-6">
                    <span>Coleções <b>{ data?.collections?.length }</b></span>
                    ·
                    <span>Criador desde
                        <b className="ms-1 date-format"> Fev 2023</b>
                    </span>
                </div>

                <div className="about my-4">
                    <p>{ data?.biography } </p>
                </div>

                <hr />

                <div className="row g-3 mt-3">

                    { data.collections?.length === 0 && <EmptyMessage message="Este criador ainda não criou nenhuma coleção"/> }

                    { data?.collections?.map((collection) =>
                        <div key={collection.collection} className="col-12 col-md-4 col-xl-4">
                            <CardCollection
                                collection={collection}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Content>

    )
}