import styled from "styled-components";

export const ProfileContent = styled.div`
    .nav {
        gap: 1rem;
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: transparent;
        border-radius: 0;
        //margin: 0 10px; 
        border-bottom: 3px solid ${props => props.theme["pink-500"]};
    }
    
     .nav-pills .nav-link {
        display: flex;
        align-items: center;
        gap: 5px;
        
        line-height: 18px;
        color: ${props => props.theme.black};
     }

`;