import {useAuth} from "admin/contexts/Auth/AuthProvider";
import {useNavigate} from "react-router-dom";

export const HeaderAdmin = ({ setOpen, open, toggleTheme, theme }) => {

    const { logout, user } = useAuth()
    const navigate = useNavigate()

    const handleLogout = () => {
        logout();
        navigate('/login')
    }

    return (
        <header className="header shadow-sm" id="header">
            <div className={`header_toggle  ${open && 'body-pd'}`}>
                <span className="circle-icon fs-5" role="button" onClick={() => setOpen(!open)}>
                    { !open
                        ? <i className='bi bi-list' />
                        : <i className="bi bi-x" />
                    }
                </span>
            </div>

            <div className="d-flex gap-4 align-items-center">
                <span className="circle-icon fs-5" role="button" onClick={toggleTheme}>
                    { theme === 'light' ? <i className="bi bi-moon text-blue"/> : <i className="bi bi-brightness-high text-warning"/> }
                </span>


            <div className="icon-user">
                <div className="dropdown">
                    <span className="circle-icon fs-5" role="button" data-bs-toggle="dropdown"
                          aria-expanded="false">
                        <i className="bi bi-person text-primary" />
                    </span>

                    <ul className="dropdown-menu p-0">
                        <li className="p-3">
                            <div className="d-flex gap-2">
                                <div className="bg-dark p-4 rounded-circle"/>
                                <div className="d-grid">
                                    <span className="fw-bolder">{ user?.name }</span>
                                    <span>{ user?.email }</span>
                                </div>
                            </div>
                        </li>

                        <li className="border-top p-3">
                            <button className="btn btn-outline-primary w-100" type="button" onClick={handleLogout}>
                                <i className="bi bi-box-arrow-right me-2"/>  Sair
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
        </header>
    )
}