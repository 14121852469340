import AdminStyles from "../styles/adminStyles";
import {useEffect, useState} from "react";
import {HeaderAdmin} from "../admin/components/Header";
import {SidebarAdmin} from "../admin/components/Sidebar";
import {Outlet, useNavigate} from "react-router-dom";
import {lightTheme, darkTheme} from "../styles/theme";
import {ThemeProvider} from "styled-components";
import { ReactQueryDevtools } from 'react-query/devtools'

export const LayoutAdmin = () => {

    const [open, setOpen] = useState(false)
    const [theme, setTheme] = useState(localStorage.getItem("theme") ?? 'dark');

    const toggleTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
            localStorage.setItem("theme", "dark");
        } else {
            setTheme('light');
            localStorage.setItem("theme", "light");
        }
    }

    const navigate = useNavigate()

    useEffect(() => {
        navigate('dashboard')
    }, [])

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <AdminStyles />
            <ReactQueryDevtools />
            <HeaderAdmin
                setOpen={setOpen}
                open={open}
                toggleTheme={toggleTheme}
                theme={theme}
            />
            <SidebarAdmin open={open} />

            <div className={`mb-5 pt-5 ${open && 'body-pd'}`}>
                <Outlet />
            </div>
        </ThemeProvider>
    )
}