const { ModalWrapper, ModalContent, CloseButton } = require("./styles");

export const BottomModal = ({ isOpen, toggleModal, children }) => {

    const handleWrapperClick = (event) => {
      if (event.target === event.currentTarget) {
        toggleModal();
      }
    };
  
    return (
      <ModalWrapper isOpen={isOpen} onClick={handleWrapperClick}>
        <ModalContent>
        <CloseButton onClick={toggleModal}>X</CloseButton>
          {children}
        </ModalContent>
      </ModalWrapper>
    );
  };