import { PreviewContainer} from "./styles";
import { Link } from "react-router-dom";
import { truncateAddress } from "utils/utils";

import { networkParams } from "web3/networks/networks";
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import { useApp } from "web3/providers/AppContext";
import { ListNFTPreview } from "../ListNFTPreview";
import { useTranslation } from 'react-i18next';

export const PreviewCollection = ({data = {}}) => {
    const { creator } = useApp()
    const { t, i18n } = useTranslation();

    const date = new Date();
    const categories = data.categoriesList?.filter(option => data.categories.includes(option.value))

    const totalQuantity = data.imagesNft.reduce((preview, current) => {
        return preview + Number(current.quantity ? current.quantity : 1);
    }, 0);

    return (
        <PreviewContainer>
            <div className="banner-collection">
                { data.banner_collection.length > 0
                    && <img src={URL.createObjectURL(data.banner_collection[0])} className="img-fluid" alt="banner" />}
                <div className="container px-4">
                    <div className="image-collection bg-light rounded-4 shadow-sm">
                        { data.image_symbol.length > 0
                            ? <img src={URL.createObjectURL(data.image_symbol[0])} className="shadow-sm" alt="symbol" />
                            : <div className="image-collection-default bg-default" />
                        }
                    </div>
                </div>
            </div>
            <div className="container pt-5 px-4">
                <div className="d-flex gap-3 flex-wrap justify-content-between align-items-center mt-4">
                    <div>
                        <h1 className="ff-bold">{data.name}</h1>

                        <span className="fs-5 ff-light">
                            {t('createdBy')}
                            <span role="button" className="ff-bold ms-2">
                                {creator && creator?.nickname ? `@${creator.nickname}` : truncateAddress(creator?.address)}
                            </span>
                        </span>
                    </div>

                    <div className="icons">
                        { data.external_link &&
                            <Link to={data.external_link} target="_blank">
                                <i className="fa fa-globe"/>
                            </Link>
                        }
                        { data.instagram &&
                            <Link to={data.instagram} target="_blank">
                                <i className="fa-brands fa-instagram"/>
                            </Link>
                        }
                        { data.discord &&
                            <Link to={data.discord} target="_blank">
                                <i className="fa-brands fa-discord"/>
                            </Link>
                        }
                        { data.twitter &&
                            <Link to={data.twitter}target="_blank">
                                <i className="fa-brands fa-twitter"/>
                            </Link>
                        }
                        <Link to="#" target="_blank">
                            <i className="fa fa-share-nodes"/>
                        </Link>
                    </div>
                </div>

                <div className="d-flex flex-wrap gap-3 mt-3 fs-6">
                    <span className="ff-normal">
                        Itens <span className="ff-bold">{totalQuantity}</span>
                    </span>
                    ·
                    <span className="ff-normal">
                       {t('createdAt')}
                        <b className="ms-1 date-format">{format(date, "MMM yyyy", { locale: ptBR })}</b>
                    </span>
                    ·
                    <span className="ff-normal categories">
                        {t('category')}{ categories.length > 1 && 's' }
                        { categories.length > 0 && categories.map(category => (
                            <span className="ff-bold" key={category.label}>
                                {category.label}
                            </span>
                        ))}
                    </span>
                    ·
                    <span className="ff-normal">
                        <span className="ff-bold">{networkParams[data?.network]?.network_name}</span>
                    </span>
                </div>

                <div className="about my-4">
                    <p className="ff-normal" style={{maxWidth:'600px',overflow:'hidden'}}>{data?.short_description} </p>
                </div>

                <div className="about my-4" >
                    <p className="ff-normal" style={{maxWidth:'600px',overflow:'hidden'}}>{data?.description} </p>
                </div>

                <hr />
            </div>

            <ListNFTPreview
                nfts={data.imagesNft}
                network={data.network}
            />
        </PreviewContainer>
    );
};
