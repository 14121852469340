const isDark = true;

export const darkTheme = {
    body: '#363a44',
    bg: '#363a44',
    bgNav: '#272b34',
    bg100: '#30353f',
    bg200: '#12121a',
    color: '#FAFAFA',
    border: '#272b34',

    default: '#474747',


    primary: '#2e3091',
    "green-500": "#2bb673",
    "pink-500": "#ee2b7a",
    "blue-200": "#068DD2FF",
    black: "#000",
    white: '#FFF',


    secondary: '#ffffff',

    primaryOpacity: '#ff66afc2',

    bg300: '#222222',
    bg400: '#565656',

    grey300: '#424242',
    grey800: '#222222',
    grey900: '#151515',

    blue200: '#00c4ff',



    inputBg: '#222222',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
}

export const lightTheme = {
    // body: '#f8f9fa',
    // bg: '#f8f9fa',
    // bgNav: '#FFFFFF',
    // color: '#363537',
    // bg100: '#FFFFFF',
    // bg200: '#112d4e',
    //
    // primary: '#2e3091',
    //
    // border: '#e7e7e7',
    //
    // bg300: '#d5d5d5',
    // bg400: '#c9c9c9',
    //
    // inputBg: '#ffffff',
    // text: '#363537',
    // toggleBorder: '#FFF',
    // gradient: 'linear-gradient(#39598A, #79D7ED)',
}

export const theme = isDark ? darkTheme : lightTheme;