import React, {useEffect} from "react";

import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {UploadFiles} from "../../components/UploadFiles";
import {useApp} from "../../web3/providers/AppContext";

export const FormFunds = () => {

    const {layoutHeader, wallet} = useApp()

    useEffect(() => {
        layoutHeader(2)
    }, [layoutHeader])

    const SignupSchema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        description: Yup.string().required(),
        phone: Yup.string().required(),
        document: Yup.string().required(),
        wallet: Yup.string().required(),
        value: Yup.string().required(),
    });

    const handleRemoveFile = (fileIndex, name, setFieldValue, values) => {
        const copyFiles = [...values.files]
        const newFiles = copyFiles.filter((_, index) => index !== fileIndex)
        setFieldValue(name, newFiles)
    };


    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-8">
                    <div className="card">
                        <div className="card-body p-4">
                            <Formik
                                initialValues={{
                                    name: '',
                                    description: '',
                                    email: '',
                                    phone: '',
                                    document: '',
                                    wallet: wallet,
                                    value: '',
                                    files: []
                                }}
                                validationSchema={SignupSchema}
                                onSubmit={values => {
                                    // same shape as initial values
                                    console.log(values);
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      setFieldValue,
                                      setFieldError,
                                      setFieldTouched
                                  }) => (
                                    <Form>
                                        <div className="mb-2">
                                            <label className="form-label">Nome</label>
                                            <Field
                                                name="name"
                                                className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">Motivo da solicitação</label>
                                            <Field
                                                as="textarea"
                                                name="description"
                                                className={`form-control ${errors.description && touched.description ? 'is-invalid' : ''}`}
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">Valor</label>
                                            <Field
                                                name="document"
                                                className={`form-control ${errors.value && touched.value ? 'is-invalid' : ''}`}
                                            />
                                        </div>


                                        <div>
                                            <label className="form-label">Arquivos</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-5">

                                                <UploadFiles
                                                    setFieldValue={setFieldValue}
                                                    setFieldError={setFieldError}
                                                    setFieldTouched={setFieldTouched}
                                                    name="files"
                                                />
                                            </div>

                                            <div className="col-12 col-md-7">
                                                <div style={{height: "158px", overflowX: "auto"}}
                                                     className="px-3 py-2 card">
                                                    {values.files.length === 0 ?
                                                        <div
                                                            className="d-flex justify-content-center align-items-center h-100">
                                                            <h5 className="ff-bold text-center mb-0">Nenhum arquivo foi
                                                                selecionado</h5>
                                                        </div>
                                                        : values.files?.map((file, index) => (
                                                            <div className="d-flex justify-content-between mb-1"
                                                                 key={index}>
                                                                {file.name}
                                                                <button
                                                                    className="btn btn-sm btn-danger"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        handleRemoveFile(index, 'files', setFieldValue, values)
                                                                    }
                                                                >
                                                                    <i className="bi bi-trash"/>
                                                                </button>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-12 col-md-6">
                                                <label className="form-label">E-mail</label>
                                                <Field
                                                    name="email"
                                                    type="email"
                                                    className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label className="form-label">Celular</label>
                                                <Field
                                                    name="phone"
                                                    type="tel"
                                                    className={`form-control ${errors.phone && touched.phone ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">CPF</label>
                                            <Field
                                                name="document"
                                                className={`form-control ${errors.document && touched.document ? 'is-invalid' : ''}`}
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">Carteira</label>
                                            <Field
                                                name="wallet"
                                                className={`form-control ${errors.wallet && touched.wallet ? 'is-invalid' : ''}`}
                                                readOnly
                                            />
                                        </div>

                                        <div className="float-end mt-4">
                                            <button className="btn btn-secondary btn-lg px-5" type="submit">Enviar
                                            </button>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}