import {Field} from 'formik';
import {CustomSelect} from "components/CustomSelect";
import {networkParams} from "web3/networks/networks";
import {Button} from "reactstrap";
import React, {useState, useEffect} from "react";
import { useApp } from "web3/providers/AppContext";
import {getCategories} from "services/categories.service";
import {
    RequireMessage,
    getPrice,
    InfoCollection,
    allowOnlyNumbersAndDots,
} from "../utils";
import {UploadFiles} from "../../../../../components/UploadFiles";
import { UrlInput, UrlInputContainer } from './styles';
import { replaceWithDashes } from 'utils/utils';
import { checkDuplicateURL } from 'services/contract.service';
import { useTranslation } from 'react-i18next';

export const StepOne = ({formik}) => {
    const { t, i18n } = useTranslation();
    const [categories, setCategories] = useState([])
    const [testError, setTestError] = useState('')
    const [urlValue, setUrlValue] = useState('')
    const { wallet } = useApp();
    const [validAmount, setValidAmount] = useState(false)
    const {setFieldValue, setFieldError, setFieldTouched, values, errors, touched} = formik

    useEffect(() => {
        async function categories() {
            const categories = await getCategories({status: true});
            createListCategory(categories.data);
        }
        categories();
        
        if(!values.network ){
            setFieldValue("network", networkParams[137].chainId)
        }
    
    }, []);


    const handleUpdateName = async () => {
        if(!values.name) return

        const originalName = replaceWithDashes(values.name);
        let newName = originalName;
        let numberSuffix = 2;
      
        const accentMap = {
          á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u',
          Á: 'A', É: 'E', Í: 'I', Ó: 'O', Ú: 'U',
          à: 'a', è: 'e', ì: 'i', ò: 'o', ù: 'u',
          À: 'A', È: 'E', Ì: 'I', Ò: 'O', Ù: 'U',
          ã: 'a', õ: 'o',
          Ã: 'A', Õ: 'O',
          â: 'a', ê: 'e', î: 'i', ô: 'o', û: 'u',
          Â: 'A', Ê: 'E', Î: 'I', Ô: 'O', Û: 'U',
          ç: 'c', Ç: 'C'
        };
      
        newName = newName.replace(/[^A-Za-z0-9_\-]/g, c => accentMap[c] || c);
      
        while (true) {
          const collectionUrlExists = await checkDuplicateURL({ url: replaceWithDashes(newName.toLowerCase()) });
          
      
          if (collectionUrlExists) {
            const suggestedName = `${originalName}-${numberSuffix}`;
            const suggestedUrl = replaceWithDashes(suggestedName.toLowerCase());
      
            if (suggestedUrl !== replaceWithDashes(originalName.toLowerCase()) && !(await checkDuplicateURL({ url: suggestedUrl }))) {
              newName = suggestedName;
              break;
            }
      
            numberSuffix++;
          } else {
            break;
          }
        }
      
        if (newName !== originalName) {
          setTestError(`${t('urlAlreadyExists')} ${newName}`);
          setUrlValue(replaceWithDashes(newName.toLowerCase()));
          setFieldValue("url", replaceWithDashes(newName.toLowerCase()));
        } else {
          setTestError("");
          setUrlValue(replaceWithDashes(originalName.toLowerCase()));
          setFieldValue("url", replaceWithDashes(originalName.toLowerCase()));
        }
      };
      
      

      useEffect(() => {
        if(values.name){
        handleUpdateName();
        }
        }, [values.name]);
      
      

    const createListCategory = (response = []) => {
        const listCategories = response.map(category => {
            if(category.id === "9CMUcBDEadfGqsXquvJR" && wallet != "0x2735cdf94d99e006ae2b654506b33da9c9987227") {
                return null;
            }
            return {value: category.id, label: category.name};
        });

        const resultadoFinal = listCategories.filter(objeto => objeto !== null);
        setFieldValue('categoriesList', resultadoFinal)
        setCategories(resultadoFinal);
    };

    const handleRemoveFile = (fileIndex, name) => {
        const copyFiles = [...values.file_reasons_to_believe]
        const newFiles  =  copyFiles.filter((_, index) => index !== fileIndex)
        setFieldValue(name, newFiles)
    };

    return (
        <div className="px-4">
            <div className="input-control col-12 mb-3">
                <label className="form-label">{t('category') + 's'}</label>
                <CustomSelect
                    options={categories}
                    value={values.categories}
                    onChange={value => setFieldValue("categories", value)}
                    error={errors.categories && touched.categories}
                />
                {errors.categories && touched.categories && <RequireMessage message={t(errors.categories)}/>}
            </div>

            <div className="row">
                <div className="col-12 col-md-8">
                    <div className="input-control mb-2">
                        <label className="form-label"> {t('collectionName')} </label>
                        <Field
                            className={`form-control 
                                ${errors.name && touched.name ? "is-invalid" : ""}`}
                            name="name"
                            onBlur={handleUpdateName}
                        />
                        {errors.name && touched.name && <RequireMessage message={t(errors.name)}/>}
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="input-control mb-2">
                        <label className="form-label"> {t('collectionSimbol')} </label>
                        <Field
                            className={`form-control 
                                ${errors.symbol && touched.symbol ? "is-invalid" : ""}`}
                            name="symbol"
                        />
                        {errors.symbol && touched.symbol && <RequireMessage message={t(errors.symbol)}/>}
                    </div>
                </div>
            </div>

            <div className="row">
            <div className="col-12">
                    <div style={{display:'flex',flexDirection:'column',marginBottom:'10px'}}>
                        <label className="form-label" style={{display:'flex' ,justifyContent:'space-between',alignItems:'flex-end'}}> {t('collectionUrl')} <span style={{color:'tomato',fontSize:'12px'}}>{testError}</span> </label>
                        <UrlInputContainer> 
                            <div style={{width:'105px' ,padding:'0 10px',fontFamily:'Figtree Font Medium'}} >collection /</div>
                        <UrlInput
                        name="url"
                        value={replaceWithDashes(urlValue)}
                        disabled
                        />
                        </UrlInputContainer>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="input-control mb-3">
                        <label className="form-label">{t('chain')}<Button id="network" type="button" className="link bg-transparent border-0 p-0">
                                <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
                            </Button>
                            <InfoCollection target="network" message={t('tooltipStepOne1')}/></label>
                        <Field
                            as="select"
                            
                            className={`form-select 
                                ${errors.network && touched.network ? "is-invalid" : ""}`}
                            name="network"
                            onChange={e => {
                                setFieldValue("network", e.target.value);
                                setFieldValue("value", "");
                                setFieldValue("price_brl", "R$ 0,00")
                            }}>
                           <option value={networkParams[137].chainId}>{networkParams[137].name_select}</option>
                            {Object.values(networkParams).map((network) =>
                            (network.chainId !== 137 && network.production) &&( <option key={network.chainId} value={network.chainId}>{network.name_select}</option>)
                            )}
                        </Field>
                        {errors.network && touched.network && <RequireMessage message={errors.network}/>}
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="input-control mb-3">
                        <label className="form-label">
                         {t('price')} {values.network !== "" ? networkParams[values?.network].crypto : "ETH"}

                            <Button id="priceWeb3" type="button" className="link bg-transparent border-0 p-0">
                                <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
                            </Button>
                            <InfoCollection target="priceWeb3" message={t('tooltipStepOne2')}/>
                        </label>

                        <Field
                            className={`form-control 
                                ${errors.value && touched.value ? "is-invalid" : ""}`}
                            name="value"
                            type="text"
                            value={values.value}
                            onKeyPress={allowOnlyNumbersAndDots}
                            onChange={e => setFieldValue("value", e.target.value)}
                            onBlur={(e) => getPrice(values.network, e.target.value, setFieldValue, 'price_brl', setValidAmount)}
                        />
                        {!values.network && touched.value
                            ? <RequireMessage message={errors.network}/>
                            : errors.value && touched.value && <RequireMessage message={t(errors.value)}/>}
                            { validAmount && (
                                <p className="text-dark pt-1 mb-0 small text-start" style={{ textAlign: "center" }}>
                                    {t('minValuePix')}
                                </p> )
                                
                            }
                            

                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="input-control mb-3">
                        <label className="form-label">{t('price')} BRL</label>
                        <Field
                            name="price_brl"
                            className="form-control"
                            disabled={true}
                            placeholder="R$ 0,00"
                        />
                    </div>
                </div>
            </div>

            <div className="input-control mb-3">
                <label className="form-label"> {t('shortDescription')}
                  <Button id="shortDesc" type="button" className="link bg-transparent border-0 p-0">
                        <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
                 </Button>
                        <InfoCollection target="shortDesc" message={t('tooltipStepOne3')}/>
                </label>
                <Field
                    as="textarea"
                    className={`form-control 
                        ${errors.short_description && touched.short_description ? "is-invalid" : ""}`}
                    name="short_description"
                    rows="3"
                />
                {errors.short_description && touched.short_description && <RequireMessage message={t(errors.short_description)}/>}
            </div>

            <div className="input-control mb-3">
                <label className="form-label">{t('longDescription')}
                    <Button id="longDesc" type="button" className="link bg-transparent border-0 p-0">
                        <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
                    </Button>
                     <InfoCollection target="longDesc" message={t('tooltipStepOne4')}/>      
                 </label>
                <Field
                    as="textarea"
                    className={`form-control 
                        ${errors.description && touched.description ? "is-invalid" : ""}`}
                    name="description"
                    rows="6"
                />
                {errors.description && touched.description && <RequireMessage message={t(errors.description)}/>}
            </div>

            <div className="input-control mb-3">
                <label className="form-label"> {t('benefits')}
                <Button id="benefitsToBuyers" type="button" className="link bg-transparent border-0 p-0">
                        <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
                    </Button>
                     <InfoCollection target="benefitsToBuyers" message={t('tooltipStepOne5')} />      
                 </label>
                <Field as="textarea" className="form-control" name='benefits' />
                {errors.benefits && touched.benefits && <RequireMessage />}
            </div>

            <div className="input-control mb-3">
                <label className="form-label"> {t('reasons')}
                    <Button id="reasons" type="button" className="link bg-transparent border-0 p-0">
                        <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
                    </Button>
                    <InfoCollection target="reasons" message={t('tooltipStepOne6')} />      
                 </label>
                <Field as="textarea" className="form-control" name="reasons_to_believe" />
                {errors.reasons_to_believe && touched.reasons_to_believe && <RequireMessage />}
            </div>

            <div className="input-control mb-1">
                <label className="form-label"> {t('reasonsFile')} </label>
            </div>

            <div className="row">
                <div className="col-12 col-md-5">
                    <UploadFiles
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                        name="file_reasons_to_believe"
                    />
                </div>

                <div className="col-12 col-md-7">
                    <div style={{height: "176px", overflowX: "auto"}} className="px-3 py-2 card">
                        { values.file_reasons_to_believe.length === 0 ?
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <h5 className="ff-bold text-center mb-0">{t('withoutFile')}</h5>
                            </div>
                        : values.file_reasons_to_believe?.map((file, index) => (
                            <div className="d-flex justify-content-between mb-1" key={index}>
                                { file.name }
                                <button
                                    className="btn btn-sm btn-danger"
                                    type="button"
                                    onClick={() => handleRemoveFile(index, 'file_reasons_to_believe')}
                                >
                                    <i className="bi bi-trash" />
                                </button>
                            </div>
                        )) }
                    </div>
                </div>
            </div>

            <h5 className="mt-3">{t('projectMedias')}</h5>

            <div className="input-control mb-3">
                <label className="form-label"> {t('yourSite')} </label>
                <div className="input-group mb-3">
                        <span className="input-group-text">
                            <i className="bi bi-globe"/>
                        </span>
                    <Field className="form-control" name="external_link" />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <div className="input-control mb-2">
                        <label className="form-label"> Twitter </label>
                        <div className="input-group mb-3">
                                <span className="input-group-text">
                                    <i className="bi bi-twitter"/>
                                </span>
                            <Field className="form-control" name="twitter" />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="input-control mb-2">
                        <label className="form-label"> Discord </label>
                        <div className="input-group mb-3">
                                <span className="input-group-text">
                                    <i className="bi bi-discord"/>
                                </span>
                            <Field className="form-control" name="discord" />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="input-control mb-2">
                        <label className="form-label"> Instagram </label>
                        <div className="input-group mb-3">
                                <span className="input-group-text">
                                    <i className="bi bi-instagram"/>
                                </span>
                            <Field className="form-control" name="instagram" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
