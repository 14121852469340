import * as S from '../styles'
import { useState } from 'react'
import {checkEmailCode} from "../../../services/pix.service";

export const  ThirdStepPix = ({changeStep,emailValue}) => {
    const [value, setValue] = useState('');
    const [isValidCode, setIsValidCode] = useState(true);

    const checkCode = async () => {
      
      
      const email = {
        email: emailValue,
        code: parseInt(value)
    }
      const response = await checkEmailCode(email)
      if(!response.success){
        setIsValidCode(false)
        return
      }
      changeStep();
    }
return(
    <S.CheckoutContainer>
      <S.EmailDetails>
        Enviamos um código de verificação para o seu e-mail.
      </S.EmailDetails>

      <S.InputContainer>
        <label htmlFor="code">Código de verificação</label>
        <input type="text" id="code" style={{padding:'12px'}}  onChange={(e) => setValue(e.target.value)}  placeholder='Digite o seu código de verificação' value={value}/>
        {!isValidCode && <span className='error'>Código inválido</span>}
        <span >Verifique sua caixa de entrada ou sua caixa de SPAM</span>
      </S.InputContainer>

      <S.ContinueButton onClick={checkCode}>
        Continuar
      </S.ContinueButton>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}