import { useApp } from 'web3/providers/AppContext';
import { truncateAddress } from 'utils/utils';
import Polygon from 'assets/img/matic.png';
import ETH from 'assets/img/ethereum.png';

import { useState } from 'react';
import { Tooltip } from 'reactstrap';
import {NavLink, useNavigate} from 'react-router-dom';
import { ContentSidebarAccount, ListMenuSideBar } from './styles';
import { networkParams } from "web3/networks/networks";
import useAuth from 'hooks/useAuth';
import { BottomModal } from 'components/BottomModal';
import { useTranslation } from 'react-i18next';
import { ModalButtonsContainer, ModalContainer, ModalTextsContainer } from 'components/BottomModal/styles';
import { getPrivateKey } from 'services/creator.service';
import Swal from 'sweetalert2';
import { LoadingModal } from 'components/Loading';

const SideBarAccount = () => {
  const { wallet, balance, creator, disconnectWallet,disconnectGoogle, network,walletAddress } = useApp();
  const {googleAccount} = useAuth()
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [textCopy, setTextCopy] = useState('Copiar');
  const [isModalOpen, setModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);

  const storedLang = localStorage.getItem('i18n@borderless');

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const copyAddress = (text) => {
    setTextCopy('Copiado');

    setTimeout(() => {
      setTextCopy('Copiar');
    }, 500);

    navigator.clipboard.writeText(text);
  };

  const disconnect = async () => {
    if(googleAccount){
      await disconnectGoogle()
    }else{
      await disconnectWallet()
    }
    navigate('/')
  }

  const handleEmailChange = e => {
    const email = e.target.value;
    setUserEmail(email);
    setInputChanged(true);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    setEmailValid(emailRegex.test(email));
  };

  const handleGeneratePrivateKey = async () => {
    Swal.fire({
        title: t('privateKey.waiting'),
        text: t('privateKey.processing'),
        didOpen: () => {
            Swal.showLoading();
        },
        allowOutsideClick: () => !Swal.isLoading()
    });
    try {
        await getPrivateKey({email: userEmail, lang: i18n.language });

        if(userEmail != creator?.email){
          Swal.close();
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: t('privateKey.errorExportEmail'),
              confirmButtonText: t('privateKey.confirmButton')
          });
          return;
        }

        setModalOpen(false);

        Swal.close();
        Swal.fire({
            icon: 'success',
            title: t('success'),
            text: t('privateKey.successExport'),
            showConfirmButton: true,
            confirmButtonText: t('privateKey.closeButton')
        });
        setUserEmail("");
    } catch (error) {
        Swal.close();
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: t('privateKey.errorExport'),
            confirmButtonText: t('privateKey.confirmButton')
        });
      }
  }

  return (
    <>
        <ContentSidebarAccount>
      <div
        className='offcanvas offcanvas-end collapse d-lg-block sidebar collapse'
        tabIndex='-1'
        id='sidebarAccount'
        aria-labelledby='offcanvasRightLabel'
      >
        <div className='offcanvas-body p-0'>
          <div className='d-flex p-4 align-items-center justify-content-between'>
            <div className='d-flex gap-3 align-items-center'>
              <div className='avatar'>
                { creator?.profile_avatar &&
                    <img
                        src={creator.profile_avatar}
                        alt=''
                        width={50}
                    />
                }
              </div>
              <div>
                <h5 className='mb-0 fw-bolder'>{creator?.nickname}</h5>
                <span>
                  <Tooltip
                    placement='top'
                    isOpen={tooltipOpen}
                    target={'Tooltip-copy'}
                    toggle={toggle}
                  >
                    {textCopy}
                  </Tooltip>

                  <span
                    className=''
                    id='Tooltip-copy'
                    role='button'
                    onClick={() => copyAddress(walletAddress)}
                  >
                    {truncateAddress(wallet)} <i className='fa-solid fa-copy' />
                  </span>
                </span>
              </div>
            </div>
            <span role='button' data-bs-dismiss='offcanvas' aria-label='Close'>
              <i className='bi bi-x fs-1' />
            </span>
          </div>

          <div className='px-4'>
          { !googleAccount && 
                (
            <div className='card p-3 bg-light'>
             
                <div className='d-flex justify-content-between align-items-center'>
                  <img src={ (network === 1 || network === 5) ? ETH : Polygon} alt={((network !== undefined) && networkParams.hasOwnProperty(network)) ? networkParams[network].network_name : ''}  width={32} />
                  <span> {parseFloat(balance).toFixed(4)} {((network !== undefined) && networkParams.hasOwnProperty(network)) ? networkParams[network].crypto : ''}</span>
                </div>
                
            </div>
            )
          }
            {wallet && (
              <div className='py-4'>
                <ListMenuSideBar className="nav nav-pills flex-column mb-auto">
                  <li data-bs-dismiss='offcanvas'>
                    <NavLink to='/profile/creator'>
                      <i className='bi bi-person-circle' /> {t('myProfile')}
                    </NavLink>
                  </li>

                  <li data-bs-dismiss='offcanvas'>
                    <NavLink to='/profile/my-projects'>
                      <i className='bi bi-tag' /> {t('myProjects')}
                    </NavLink>
                  </li>

                  <li data-bs-dismiss='offcanvas'>
                    <NavLink to='/profile/my-nfts'>
                      <i className='bi bi-grid' /> {t('myNft')}
                    </NavLink>
                  </li>

                  <li data-bs-dismiss='offcanvas'>
                    <NavLink to='/create'>
                      <i className='bi bi-plus-circle' /> {t('createCollection')}
                    </NavLink>
                  </li>
                </ListMenuSideBar>
              </div>
            )}
          </div>
          <div className="position-absolute bottom-0 w-100">
            <div className="m-4">
              
              {googleAccount && (<p data-bs-toggle="offcanvas" data-bs-target="#sidebarAccount" aria-controls="sidebarAccount" style={{fontFamily:'Figtree Font',textAlign:'center',fontSize:'14px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={() => toggleModal()}>{t('privateKey.generateTitle')}</p>)}

              <button
                  onClick={disconnect}
                  className='btn btn-primary btn-lg rounded-pill position-relative text-white py-3'
                  data-bs-dismiss='offcanvas'
                  style={{width: "100%"}}
              >
                <i className='bi bi-box-arrow-right me-2' /> {t('disconnect')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ContentSidebarAccount>
    <BottomModal isOpen={isModalOpen} toggleModal={toggleModal}>
      <ModalContainer>
        <ModalTextsContainer>
          <p style={{fontSize: '24px',fontWeight:'bold',fontFamily:'Figtree Font',margin:'0'}}>{t('privateKey.generateTitle')}</p>
          <p style={{fontSize: '16px',fontWeight:'bold',fontFamily:'Figtree Font',textAlign:'center'}}>{t('privateKey.description')}</p>
        </ModalTextsContainer>
        <ModalButtonsContainer>
          <label style={{fontSize: '16px',fontWeight:'bold',fontFamily:'Figtree Font'}}>{t('privateKey.yourEmail')}</label>
          <input
            type="email"
            value={userEmail}
            onChange={handleEmailChange}
            placeholder='email@email.com'
            style={{
                padding:'10px',
                borderRadius:'20px',
                border: (inputChanged && !emailValid) ? '1px solid red' : '1px solid gray'
            }}
        />
        {inputChanged && !emailValid && <span style={{color: 'red', fontSize: '13px', fontWeight: 'bolder'}}>{t('privateKey.invalidFeedback')}</span>}
        <button
            className="btn btn-primary rounded-pill btn-lg px-5 py-2 ff-normal"
            onClick={handleGeneratePrivateKey}
            disabled={!emailValid}
        >
            {t('privateKey.generateTitle')}
        </button>
        </ModalButtonsContainer>
      </ModalContainer>
    </BottomModal>
    </>
  );
};
export default SideBarAccount;
