import styled from "styled-components";
import {Field} from 'formik';

export const UrlInputContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    background-color: #e9ecef;
    border-radius: 8px;
`

export const UrlInput = styled(Field)`
    border: none;
    padding: 10px;
    width: 85%;
    background-color: #fff;
    font-family:'Figtree Font Medium',
`