import { ContentCreator } from './styles';
import {Link} from "react-router-dom";
import DefaultUser from "../../../../assets/img/default-user.jpg";

export const CardCreator = ({ creator }) => {

    const { name, address, nickname, profile_avatar, profile_banner } = creator

    return (
        <ContentCreator>
            <Link to={`/creator/${nickname ?? address}`} className="text-decoration-none">
                <div className="card">

                    <div className="card-image mx-3 mt-3">
                        <img src={profile_banner} className="img-fluid rounded-4 border-3 w-100" alt="" />
                        <div className="avatar-creator">
                            <img src={ profile_avatar ?? DefaultUser } alt="" />
                        </div>
                    </div>

                    <div className="card-body mt-4 mx-2">
                        <div className="text-center">
                            <h2 className="fs-5 fw-bold text-white">{ name }</h2>
                            <span>@{nickname}</span>
                        </div>
                    </div>
                </div>
            </Link>
        </ContentCreator>
    )
}
