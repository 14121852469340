import {HeaderPage} from "../../components/HeaderPage";
import { HomeContent, Container } from "./styles";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where, orderBy, limit, getDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import { getCollections } from "services/collection.service";
import { Table } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import Sales from "./components/Sales";
import Projects from "./components/Projects";

import Web3 from "web3";

const Dashboard = () => {
    const [totalSalesMatic, setTotalSalesMatic] = useState(0);
    const [totalSalesEther, setTotalSalesEther] = useState(0);
    const [totalPixPurchases, setTotalPixPurchases] = useState(0);
    const [googleWalletCount, setGoogleWalletCount] = useState(0);
    const [metamaskAccount, setMetamaskAccount] = useState(0);
    const [pixCount, setpixCount] = useState(0);
    const [etherCount, setetherCount] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [collections,setCollections] = useState([])
    const [filterText, setFilterText] = useState("");
    const [filteredCollections, setFilteredCollections] = useState([]);
    const [lastPurchases, setLastPurchases] = useState([]);
    const [walletFund, setWalletFund] = useState(0);
    const [isTableLoading, setTableLoading] = useState(true);

    const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
    const rfqFirestore = process.env.REACT_APP_RFQS_FB;
    const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;
    const creatorsFirestore =  process.env.REACT_APP_CREATORS_FB;    

    const fetchCreators = async () => {
        const creatorsCollection = collection(db, creatorsFirestore);

        // Pegando todos os documentos da coleção 'creators'
        const allDocsSnapshot = await getDocs(creatorsCollection);
        let googleWalletCountTemp = 0;
        let metamaskCountTemp = 0;

        allDocsSnapshot.forEach((doc) => {
            if (doc.data().isGoogleWallet) {
                googleWalletCountTemp++;
            } else if (!doc.data().hasOwnProperty('isGoogleWallet')) {
                metamaskCountTemp++;
            }
        });

        setGoogleWalletCount(googleWalletCountTemp);
        setMetamaskAccount(metamaskCountTemp);
    };

    const fetchPixPurchases = async () => {
        const pixPurchasesQuery = query(
            collection(db, rfqFirestore),
            where("emailSuccessPurchase", "==", true)
        );
    
        const querySnapshot = await getDocs(pixPurchasesQuery);
        
        let sumPixPurchases = 0;
        let total = 0
        querySnapshot.forEach(async (document) => {
            const data = document.data();
            if (data.rfq && data.rfq.order.quote.result.cash?.value) { 
                sumPixPurchases += parseFloat(data.rfq.order.quote.result.cash?.value);
                total++;
            }
        });
        setpixCount(total);
        setTotalPixPurchases(sumPixPurchases);
    };

    const fetchSales = async () => {
        const salesQuery = query(collection(db, imagesFirestore), where("status", "==", 1));
        const querySnapshot = await getDocs(salesQuery);
        
        let sumMatic = 0;
        let sumEther = 0;
        let total = 0
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.nftValue) { 
                const value = parseFloat(data.nftValue);                
                total++;
                if(parseInt(data.network) === 80001 || parseInt(data.network) === 137) {                    
                    sumMatic =  sumMatic + value;
                } else if(data.network === "1") {
                    sumEther += value;
                }
            }
        });
        
        setetherCount(total);
        setTotalSalesMatic(sumMatic);
        setTotalSalesEther(sumEther);
    };

    const requestCollections = async () => {
        const response = await getCollections('', '', '',true)
        const colecaoRef = collection(db, collectionFirestore); // Substitua 'suaColecao' pelo nome da sua coleção

        try {
            const snapshot = await getDocs(colecaoRef);            
            setTotalProjects(snapshot.size);            
        } catch (error) {
            console.error('Erro ao contar documentos:', error);
        }
        
        setCollections(response);
    };

    const fetchLastCollectionsCreated = async () => {
        
        const colecaoRef = collection(db, collectionFirestore); 

        const q = query(colecaoRef, orderBy('created_at', 'desc'), limit(10)); 
        const collections = [];
        try {
            const snapshot = await getDocs(q);
            snapshot.forEach(async (document) => {
                const data = document.data();
                data.collectionId = document.id;
                const created = new Date(data.created_at.seconds * 1000);

                // Converta os nanossegundos em milissegundos e adicione à data
                created.setMilliseconds(created.getMilliseconds() + Math.round(data.created_at.nanoseconds / 1000000));

                const day = created.getDate().toString().padStart(2, '0');
                const month = (created.getMonth() + 1).toString().padStart(2, '0');
                const year = created.getFullYear();
                const hours = created.getHours().toString().padStart(2, '0');
                const minutes = created.getMinutes().toString().padStart(2, '0');
          
                data.created = `${day}/${month}/${year} ${hours}:${minutes}`;

                const documentoRef = doc(db, creatorsFirestore, data.wallet);
                const documentoSnapshot = await getDoc(documentoRef);
                data.CreatorName = data.wallet;
                if (documentoSnapshot.exists()) {
                    const dadosDoDocumento = documentoSnapshot.data();
                    data.CreatorName = dadosDoDocumento.name;
                    data.CreatorWallet = dadosDoDocumento.address;
                    data.CreatorEmail = dadosDoDocumento.email;
                    data.CreatorPhone = dadosDoDocumento.celular;
                    data.CreatorGoogleWallet = dadosDoDocumento.isGoogleWallet;
                    data.CreatorCpf = dadosDoDocumento.cpf;
                } 
                collections.push(data);
            });
        } catch (error) {
            console.error('Erro ao obter os últimos documentos:', error);
        }
        
        setFilteredCollections(collections);
    };

    const fetchLastPurchases = async () => {
        
        const colecaoRef = collection(db, imagesFirestore); 

        const q = query(colecaoRef, orderBy('purchaseAt', 'desc'), limit(10)); 
        const purchases = [];
        try {
            const snapshot = await getDocs(q);
            snapshot.forEach(async (document) => {
                const data = document.data();

                const purchaseAt = new Date(data.purchaseAt.seconds * 1000);

                // Converta os nanossegundos em milissegundos e adicione à data
                purchaseAt.setMilliseconds(purchaseAt.getMilliseconds() + Math.round(data.purchaseAt.nanoseconds / 1000000));

                const day = purchaseAt.getDate().toString().padStart(2, '0');
                const month = (purchaseAt.getMonth() + 1).toString().padStart(2, '0');
                const year = purchaseAt.getFullYear();
                const hours = purchaseAt.getHours().toString().padStart(2, '0');
                const minutes = purchaseAt.getMinutes().toString().padStart(2, '0');
          
                data.purchased = `${day}/${month}/${year} ${hours}:${minutes}`;

                const documentoRef = doc(db, creatorsFirestore, data.owner);
                const documentoSnapshot = await getDoc(documentoRef);
                data.CreatorName = data.owner;
                if (documentoSnapshot.exists()) {
                    const dadosDoDocumento = documentoSnapshot.data();
                    data.CreatorName   = dadosDoDocumento.name;
                    data.CreatorWallet = dadosDoDocumento.address;
                    data.CreatorEmail = dadosDoDocumento.email;
                    data.CreatorPhone = dadosDoDocumento.celular;
                    data.CreatorGoogleWallet = dadosDoDocumento.isGoogleWallet;
                    data.CreatorCpf = dadosDoDocumento.cpf;
                    data.CreatorNickname = dadosDoDocumento.nickname;
                } 

                const colecaoRef = doc(db, collectionFirestore, data.collectionId);
                const colecaoSnapshot = await getDoc(colecaoRef);
                data.collectionName = data.collectionId;
                if (colecaoSnapshot.exists()) {
                    const dadosDoDocumento = colecaoSnapshot.data();
                    data.collectionName = dadosDoDocumento.name
                }

                if (data.pix && data.rfqId) {
                    const rfqDoc = await getDoc(doc(db,rfqFirestore, data.rfqId));
                    if (rfqDoc.exists()) {
                        const rfqData = rfqDoc.data();
                        if (rfqData && rfqData.rfq && rfqData.rfq.order && rfqData.rfq.order.quote && rfqData.rfq.order.quote.result && rfqData.rfq.order.quote.result.cash && rfqData.rfq.order.quote.result.cash.value) {
                            data.realValue = rfqData.rfq.order.quote.result.cash.value;
                        }
                    }
                }
                setTableLoading(false);
                purchases.push(data);
            });
        } catch (error) {
            console.error('Erro ao obter os últimos documentos:', error);
        }
        setLastPurchases(purchases);
    };

    const balanceFund = async () => {
        const web3 = new Web3('https://polygon-mainnet.infura.io/v3/3ac68be0e4f44833bce00d483f3bd104'); 

        web3.eth.getBalance("0xD53dA08799d5c201287BC59B1E4Be6a2091f57FB", (err, balance) => {
            if (err) {
              console.error('Erro ao obter saldo da carteira:', err);
            } else {
              
              const saldoEmEther = web3.utils.fromWei(balance, 'ether');
              setWalletFund(parseFloat(saldoEmEther).toFixed(2));
            }
          });
    };

    /* const filteredItems = collections.filter(item => 
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    ); */

    useEffect(() => {
        fetchSales();
        fetchPixPurchases()
        fetchCreators()
        requestCollections()
        fetchLastCollectionsCreated()
        fetchLastPurchases()
        balanceFund()
    }, []);

    const statusMap = {
        denied: 'Negado',
        blank: 'Não finalizado',
        approved: 'Aprovado',
        submitted: 'Aguardando aprovação'
    };
    
    const columns = [
        {
            name: 'Projeto',
            selector: 'name',
            sortable:true
        },
        {
            name: 'Criado por',
            selector: row => row.creator.name,
            sortable:true
        },
        {
            name: 'Data',
            selector: row => new Date(row.createdAt.seconds * 1000).toLocaleDateString(),
            sortable:true
        },
        {
            name: 'Status',
            selector: row => statusMap[row.status] || row.status,
            sortable:true
        },
    ];

    const customStyles = {
        table: {
          style: {
            padding: '20px',
            backgroundColor: '#30353f',
            borderRadius: '8px',
            overflow: 'hidden',
          },
        },
        headCells: {
          style: {
            fontSize: '1rem',
            backgroundColor: '#30353f',
            color: 'white',
            padding: '10px 18px',
            fontWeight:'bold',
            fontSize:'20px'
          },
        },
        rows: {
          style: {
            fontSize: '1rem',
            backgroundColor: '#30353f',
            borderColor: '#333',
            color: 'white',
            padding: '10px 0',
          },
          hoverStyle: {},
        },
        cells: {
          style: {
            backgroundColor: '#30353f',
            color: 'white',
            padding: '10px 20px',
          },
        },
        pagination: {
          style: {
            backgroundColor: '#30353f',
            color: '#ffffff !important',
            padding: '20px',
            borderRadius: '10px',
            marginTop: '10px',
          },
          pageButtonsStyle: {
            color: '#ffffff',
            borderColor: '#ffffff',
            borderRadius: '10px',
            backgroundColor: '#ffffff',
            hoverBackgroundColor: '#ffffff',
            hoverBorderColor: '#ffffff',
            activeBackgroundColor: 'transparent',
            activeBorderColor: 'transparent',
            marginRight: '5px',
            marginLeft: '5px',
          },
          hoverStyle: {}
        },
      };


    return (
        <Container>
            <div className="container-fluid ms-0 px-0">
                <HeaderPage
                    label="Dashboard"
                    icon="bi bi-house"
                    description=""
                />
                <HomeContent>

                    <div className="d-cards">
                        <div className="row">
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" data-name="Layer 1" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title/><polygon fill="#fff" points="99.17 22.77 81.72 32.96 81.72 53.45 28.96 22.77 11.51 32.96 11.51 95.04 28.96 105.23 46.28 95.04 46.28 73.59 63.8 83.58 81.72 72.88 81.72 95.04 99.17 105.23 116.49 95.04 116.49 32.96 99.17 22.77"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{totalSalesMatic.toFixed(2)} <span>matics</span></h3>
                                        <span className="text-muted small fw-semibold">Valor total de vendas (Polygon)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{totalPixPurchases.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h3>
                                        <span className="text-muted small fw-semibold">Valor total de vendas no PIX</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <strong style={{fontSize:'26px'}}>{totalSalesEther.toFixed(3)}</strong>
                                    <span style={{fontSize:'18px'}}>Valor total de vendas (Ethereum)</span>
                                </div>
                            </div> */}
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" id="Layer_1" version="1.1" viewBox="0 0 56.6934 56.6934"><path fill="#fff" d="M51.981,24.4812c-7.7173-0.0038-15.4346-0.0019-23.1518-0.001c0.001,3.2009-0.0038,6.4018,0.0019,9.6017  c4.4693-0.001,8.9386-0.0019,13.407,0c-0.5179,3.0673-2.3408,5.8723-4.9258,7.5991c-1.625,1.0926-3.492,1.8018-5.4168,2.139  c-1.9372,0.3306-3.9389,0.3729-5.8713-0.0183c-1.9651-0.3921-3.8409-1.2108-5.4773-2.3649  c-2.6166-1.8383-4.6135-4.5279-5.6388-7.5549c-1.0484-3.0788-1.0561-6.5046,0.0048-9.5805  c0.7361-2.1679,1.9613-4.1705,3.5708-5.8002c1.9853-2.0324,4.5664-3.4853,7.3473-4.0811c2.3812-0.5083,4.8921-0.4113,7.2234,0.294  c1.9815,0.6016,3.8082,1.6874,5.3044,3.1163c1.5125-1.5039,3.0173-3.0164,4.527-4.5231c0.7918-0.811,1.624-1.5865,2.3908-2.4196  c-2.2928-2.1218-4.9805-3.8274-7.9172-4.9056C32.0723,4.0363,26.1097,3.995,20.7871,5.8372  C14.7889,7.8907,9.6815,12.3763,6.8497,18.0459c-0.9859,1.9536-1.7057,4.0388-2.1381,6.1836  C3.6238,29.5732,4.382,35.2707,6.8468,40.1378c1.6019,3.1768,3.8985,6.001,6.6843,8.215c2.6282,2.0958,5.6916,3.6439,8.9396,4.5078  c4.0984,1.0993,8.461,1.0743,12.5864,0.1355c3.7284-0.8581,7.256-2.6397,10.0725-5.24c2.977-2.7358,5.1006-6.3403,6.2249-10.2138  C52.5807,33.3171,52.7498,28.8064,51.981,24.4812z"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{googleWalletCount}</h3>
                                        <span className="text-muted small fw-semibold">Total de usuários Google</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><path fill="#fff" d="M95.5,104h320a87.73,87.73,0,0,1,11.18.71,66,66,0,0,0-77.51-55.56L86,94.08l-.3,0a66,66,0,0,0-41.07,26.13A87.57,87.57,0,0,1,95.5,104Z"/><path fill="#fff" d="M415.5,128H95.5a64.07,64.07,0,0,0-64,64V384a64.07,64.07,0,0,0,64,64h320a64.07,64.07,0,0,0,64-64V192A64.07,64.07,0,0,0,415.5,128ZM368,320a32,32,0,1,1,32-32A32,32,0,0,1,368,320Z"/><path fill="#fff" d="M32,259.5V160c0-21.67,12-58,53.65-65.87C121,87.5,156,87.5,156,87.5s23,16,4,16S141.5,128,160,128s0,23.5,0,23.5L85.5,236Z"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{metamaskAccount}</h3>
                                        <span className="text-muted small fw-semibold">Total de usuários Web3</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" data-name="Layer 1" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title/><polygon fill="#fff" points="44.55 54.54 44.55 63.38 54.31 69.13 54.31 103.5 25.22 85.59 25.22 43.16 17.74 38.76 17.74 89.62 61.78 116.73 61.78 64.7 44.55 54.54"/><polygon fill="#fff" points="66.22 64.7 66.22 84.07 73.08 80.06 73.08 68.61 103.14 50.91 103.14 85.62 66.22 108.48 66.22 116.73 110.26 89.62 110.26 38.76 66.22 64.7"/><polygon fill="#fff" points="73.61 46.98 64 52.64 34.6 35.33 64 19.48 100.69 39.25 107.97 34.97 64 11.27 20.03 34.97 64 60.86 80.51 51.14 73.61 46.98"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{totalProjects}</h3>
                                        <span className="text-muted small fw-semibold">Total de projetos criados</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" data-name="Layer 1" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title/><path fill="#fff" d="M83.57,73.88c.12-8.22-8.35-14-19.28-15.14-6.42-.65-9.91-2.48-9.91-6.72,0-4.05,4.12-7.06,8.71-6.81,8.34.47,9.55,5.63,9.55,8.78,0,0,9.08.1,9.08,0,0-7.67-4.42-14.42-13.45-16.41V28.5h-9v8.76c-9.33,1.48-14.5,9-14.59,15.4-.13,8.47,6.95,13.18,18.7,14.88,7.3,1,10.55,3.79,10.48,7.49-.06,4-2.67,7.83-10.19,7.83-7.14,0-10.07-4.41-10.07-9.06H43.8c0,8.34,4.46,14.46,15.42,15.88v8.74h9V89.77C80.94,88.16,83.49,78.6,83.57,73.88Z"/><path fill="#fff" d="M20.41,64A43.59,43.59,0,0,1,50.12,22.68V13.09a52.77,52.77,0,0,0,0,101.82v-9.59A43.59,43.59,0,0,1,20.41,64Z"/><path fill="#fff" d="M77.37,12.94V22.5a43.61,43.61,0,0,1,0,83v9.56a52.79,52.79,0,0,0,0-102.12Z"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{etherCount}</h3>
                                        <span className="text-muted small fw-semibold">Total de itens vendidos cripto</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{pixCount}</h3>
                                        <span className="text-muted small fw-semibold">Total de itens vendidos PIX</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mb-3">
                                <div className="card p-3 d-info-card">
                                    <div>
                                        <svg width="26" data-name="Layer 1" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title/><path fill="#fff" d="M22,11V7.83L12,2.06,2,7.83V11H4v8H2v2H22V19H20V11ZM4,9l8-4.62L20,9H4ZM6,19V11H8v8Zm4,0V11h4v8Zm8,0H16V11h2Z"/></svg>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="fw-bold fs-4 m-0 text-white">{walletFund} <span>matics</span></h3>
                                        <span className="text-muted small fw-semibold">Saldo do fundo <a className="small" href="https://polygonscan.com/address/0xD53dA08799d5c201287BC59B1E4Be6a2091f57FB" target="_blank" rel="noopener noreferrer"><i className="bi bi-box-arrow-up-right"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="d-resume-list mt-5">
                        <div className="d-section-title d-flex align-items-center justify-content-between mb-4">
                            <h2>Últimas vendas</h2>
                            <Link className="fw-bold" to="/admin/sales">ver mais</Link>
                        </div>

                        <div className="d-table w-100">
                            <div className="table-responsive">
                                <Sales dados={lastPurchases} loading={isTableLoading} />
                            </div>
                        </div>
                    </div>

                    <div className="d-resume-list mt-5">
                        <div className="d-section-title d-flex align-items-center justify-content-between mb-4">
                            <h2>Últimos projetos</h2>
                            <Link className="fw-bold" to="/admin/collections">ver mais</Link>
                        </div>
                        
                        <div className="d-table w-100">
                            <div className="table-responsive">
                                <Projects dados={filteredCollections} loading={isTableLoading} />
                            </div>
                        </div>                        
                    </div>

                </HomeContent>
            </div>
        </Container>
    )
}

export default Dashboard