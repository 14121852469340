import AppRouter from "./Router";
import {BrowserRouter} from "react-router-dom";
import {AppProvider} from "./web3/providers/AppContext";
import React from "react";
import {AuthProvider} from "./admin/contexts/Auth/AuthProvider";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <BrowserRouter>
            <ToastContainer />
            <AppProvider>
                <AuthProvider>
                    <AppRouter/>
                </AuthProvider>
            </AppProvider>
        </BrowserRouter>
    )
}

export default App;



