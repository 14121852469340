import styled from 'styled-components';

export const CardContent = styled.div`
    .card {
        background-color: ;
        border-color: ${props => props.theme.black};
        border-radius: 20px;
        transition: transform 0.5s;
    }
    
   .card:hover {
        transform: translate(0, -8px);
    }

    .card .avatar-creator {
        width:100%;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 50%;
    }

    .card .avatar-creator span {
        font-size: 1.2rem;
    }
    
    .avatar-creator img {
        border-radius: 50%;
        object-fit: cover;
        width: 2.5em;
        height: 2.5em;
    }
    
    .card-image img {
        width: 100%;
        height: 18rem;
        object-fit: cover;
        background-color: transparent;
    }
    
    .card-image span {
        position: absolute;
        top: 1rem;
        left: 1rem;
        
        padding: 0.2rem 0.5rem;
        border-radius: 8px;
        
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
    }
    
  
`;
