import {useAuth} from "./AuthProvider";
import {Navigate} from "react-router-dom";

export const RequireAuth = ({children}) => {
    const { user } = useAuth()

    if (!user) {
        return <Navigate to="/login" />
    }
    return children
}