import { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { DropContainer, UploadMessage } from "./styles";
import { FileList, FileListRejected } from "../FileList";
import React from "react";
import { useTranslation } from 'react-i18next';


export const FileInput = ({
                            name,
                            limit,
                            files,
                            withListFiles = true,
                            renameImages = false,
                            collectionName,
                            multiple = false,
                            priceNft,
                            showList= false,
                            setFieldError = () => {},
                            setFieldValue,
                            setFieldTouched,
                            handleModal
}) => {
    const { t, i18n } = useTranslation();

    const onDrop = useCallback(
        droppedFiles => {

            //Imagens NFT's da coleção
            if (renameImages) {
                const localFiles = [...files];
                
                droppedFiles.forEach(file => {
                    const fileNft = new File([file], `${collectionName} #${localFiles.length + 1}`, { type: file.type });
                    fileNft.price = priceNft;
                    fileNft.quantity = 1;
                    localFiles.push(fileNft)
                });
                

                setFieldValue(name, localFiles)

                handleModal()

            } else {
                //Imagem do Banner ou do Perfil
                setFieldValue(name, droppedFiles)
            }
        },
        [renameImages, priceNft],
    );

    const handleRemoveFile = fileIndex => {
        //setFileList(current => current.filter((_, index) => index !== fileIndex));
      //  setImage(current => current.filter((_, index) => index !== fileIndex));

        const copyFiles = [...files]
        const newFiles  =  copyFiles.filter((_, index) => index !== fileIndex)
        setFieldValue(name, newFiles)
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections, isFocused } = useDropzone({
        onDrop,
        accept: multiple
            ? {
                  "image/png": [".png", ".jpeg", ".jpg"],
                  "video/mp4": [".mp4"],
              }
            :  { "image/png": [".png", ".jpeg", ".jpg"] },
        maxFiles: limit,
        multiple: multiple,
       // name: type === "symbol" ? "image_symbol" : type === "banner" ? "banner_collection" : "imagesNft",
    });

    useEffect(() => {
        if(fileRejections.length > 0) {
            setFieldError(name, 'Arquivo nao suportado')
            setFieldTouched(name, true, false)
        }
    }, [fileRejections])

    const renderDragMessage = (isDragActive, isDragReject) => {
        if (!isDragActive) {
            return (
                <UploadMessage>
                    <i className="fa-sharp fa-solid fa-file-arrow-up fs-1 mb-3" />
                    {t('dragDrop')}
                </UploadMessage>
            );
        }
        if (isDragReject) {
            return (
                <UploadMessage type="error">
                    <i className="fa-sharp fa-solid fa-file-arrow-up fs-1 mb-3" />
                    Arquivo não suportado
                </UploadMessage>
            );
        }
        return (
            <UploadMessage type="success">
                <i className="fa-sharp fa-solid fa-file-arrow-up fs-1 mb-3" />
                Solte os arquivos aqui
            </UploadMessage>
        );
    };

    return (
        <div className="mb-4">
            <div {...getRootProps()} type="file" role="button" aria-label="File Upload" id={name}>
                <input {...getInputProps()} />
                <DropContainer
                    isDragActive={isDragActive}
                    isFocused={isFocused}
                    isDragReject={isDragReject}
                    isDragAccept={isDragAccept}
                >
                    <div className="d-grid align-items-center text-center p-4">
                        {renderDragMessage(isDragActive, isDragReject)}
                    </div>
                </DropContainer>
            </div>

            { showList && (
                <>
                    {withListFiles && !files.length && (
                        <div className="mt-3 border rounded-3" style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}>
                            <div className="text-center my-3">Nenhuma imagem selecionada</div>
                        </div>
                    )}

                    { withListFiles && files?.length > 0 && (
                        <div className="accordion border accordion-flush mt-4" id={`accordion_${name}`}>
                            <div className="accordion-item">
                                <h2 className="accordion-header border-0" id={`collapse_${name}_heading`}>
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${name}`} aria-expanded="true" aria-controls={`collapse_${name}`}>
                                        {t('files')}: {files?.length}
                                    </button>
                                </h2>
                                <div id={`collapse_${name}`} className="accordion-collapse collapse show" aria-labelledby={`collapse_${name}_heading`} data-bs-parent={`#accordion_${name}`}>
                                    <div
                                        className="accordion-body  px-0"
                                        style={{
                                            maxHeight: 400,
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                        }}>
                                        <FileList files={files} handleDelete={handleRemoveFile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    { withListFiles && fileRejections.length > 0 && (
                        <div className="accordion accordion-flush mt-4" id={`accordion_rejected_${name}`}>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`collapse_rejected_${name}_heading`}>
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse_rejected_${name}`}
                                        aria-expanded="true"
                                        aria-controls={`collapse_rejected_${name}`}>
                                        Arquivos inválidos: {fileRejections?.length}
                                    </button>
                                </h2>
                                <div
                                    id={`collapse_rejected_${name}`}
                                    className="accordion-collapse collapse show"
                                    aria-labelledby={`collapse_rejected_${name}_heading`}
                                    data-bs-parent={`#accordion_rejected_${name}`}>
                                    <div
                                        className="accordion-body"
                                        style={{
                                            maxHeight: 400,
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                        }}>
                                        <FileListRejected files={fileRejections} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
