import api from "./api";

const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;

export const getCreator = (address) => {

    return api.get(`/getCreator?address=${address}&collectionFB=${collectionFirestore}&imagesFB=${imagesFirestore}`)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export const createCreator = (account) => {

    const data = {
        "name": "",
        "birth_date": "",
        "nickname": "",
        "profile_avatar": "",
        "email": "" ,
        "celular": "",
        "cpf": "",
        'address': account,
    }
    return api.post('/createCreator', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export const createCreatorGoogle = (email) => {

    const data = {
        "name": "",
        "birth_date": "",
        "nickname": "",
        "profile_avatar": "",
        "email": email ,
        "celular": "",
        "cpf": "",
        "address": "",
        "isGoogleWallet": true
    }
    return api.post('/createCreator', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}


export const updateCreator = (data) => {
    return api.post('/updateCreator', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export const listCreators = () => {
    return api.get(`/listCreators`, )
        .then(response => response.data)
        .catch(error => console.log(error))
}


export const getPrivateKey = (data) => {
    return api.post('/getPrivateKey', { email: data.email, lang: data.lang })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("error ao buscar private key", error);
      throw error;
  });
}
