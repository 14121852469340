import {Field} from "formik";
import { allowOnlyNumbersAndDots } from "../../../../CreateNFT/components/FormCreate/utils";
import InputMask from "react-input-mask";
import React, {useEffect} from "react";
import {InfoCollection, RequireMessage} from "../utils";
import {Button} from "reactstrap";
import { useTranslation } from 'react-i18next';

export const StepThree = ({formik}) => {
    const { t, i18n } = useTranslation();

    const calculaTotal = (creator = 0, artist, fund, setFieldValue) => {
        const newCreator = Number.isInteger(parseInt(creator)) ? creator : 0;
        const newArtist = Number.isInteger(parseInt(artist)) ? artist : 0;
        const newFund = Number.isInteger(parseInt(fund)) ? fund : 0;
        const newValue = parseInt(newCreator) + parseInt(newArtist) + parseInt(newFund) + 14;

        setFieldValue('total_percentage', newValue, setFieldValue);
    }

    const calculaTotalSecundaria = (creator, artist) => {
        const newCreator = Number.isInteger(parseInt(creator)) ? creator : 0;
        const newArtist = Number.isInteger(parseInt(artist)) ? artist : 0;
        const newValue = parseInt(newCreator) + parseInt(newArtist) + 1;

        setFieldValue('total_percentage_second', newValue)
    }

    const { setFieldValue, values, errors, touched } = formik

    const totalQuantity = values.imagesNft.reduce((preview, current) => {
        return preview + Number(current.quantity ? current.quantity : 1);
    }, 0);

    useEffect(() => {
        setFieldValue('amount', totalQuantity)
    },[totalQuantity])


    const ButtonInfo = ({targetInfo, message}) => {
        return (
            <>
                <Button id={targetInfo} type="button"
                        className="link bg-transparent border-0 py-0 p-0 ps-2" style={{marginTop: "-4px"}}>
                    <i className="fa-sharp fa-solid fa-circle-info text-info"/>
                </Button>
                <InfoCollection target={targetInfo} message={message}/>
            </>
        )
    }

    return (
        <>
            <div id="pills-contact">
                <div className="row g-4 px-4">
                    <div className="col-12 col-md-6">
                        <div className="input-control mb-2">
                            <label className="form-label"> {t('quantityCollection')} </label>
                            <Field
                                className="form-control-plaintext"
                                name="amount"
                                type="number"
                                min="1"
                                readOnly={true}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="input-control mb-2">
                            <label className="form-label"> {t('quantityMints')} </label>
                            <Field
                                className={`form-control  
                                    ${errors.amount_wallet && touched.amount_wallet ? "is-invalid" : ""}`}
                                name="amount_wallet"
                                type="number"
                                min="1"
                            />
                            {errors.amount_wallet && touched.amount_wallet
                                && <RequireMessage message={t(errors.amount_wallet)} />}
                        </div>
                    </div>
                </div>

                <div className="row g-4 bg-light border-top border-bottom my-2 mx-0 p-3">
                    <div className="col-12 col-md-6">
                        <div className="input-control mb-2">
                            <label className="form-label"> {t('creatorWallet')} </label>
                            <Field
                                className={`form-control  
                                    ${errors.wallet_creator && touched.wallet_creator ? "is-invalid" : ""}`}
                                name="wallet_creator"
                            />
                            {errors.wallet_creator && touched.wallet_creator
                                && <RequireMessage message={t(errors.wallet_creator)} />}
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('primarySale')}
                                <ButtonInfo targetInfo="firstCreator" message={t('tooltipStepTres1')}/>
                            </label>
                            <Field
                                className={`form-control  
                                    ${errors.wallet_creator_first_sale && touched.wallet_creator_first_sale ? "is-invalid" : ""}`}
                                name="wallet_creator_first_sale"
                                type="text"
                                onChange={e => {
                                    setFieldValue("wallet_creator_first_sale", e.target.value)
                                    calculaTotal(
                                        e.target.value,
                                        values.wallet_artist_first_sale,
                                        values.crowd_fund_first_sale,
                                        setFieldValue
                                    );
                                }}
                               onKeyPress={event => {
                                   if (!/[0-9]/.test(event.key)) {
                                       event.preventDefault();
                                   }
                               }}
                            />
                            {errors.wallet_creator_first_sale && touched.wallet_creator_first_sale && (
                                <RequireMessage message={errors.wallet_creator_first_sale} />
                            )}
                        </div>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('secondarySale')}
                                <ButtonInfo targetInfo="secondaryCreator" message={t('tooltipStepTres2')}/>
                            </label>
                            <Field
                                className={`form-control  
                                    ${errors.wallet_creator_second_sale && touched.wallet_creator_second_sale ? "is-invalid" : ""}`}
                                name="wallet_creator_second_sale"
                                type="text"
                                onChange={e => {
                                setFieldValue("wallet_creator_second_sale", e.target.value)
                                    calculaTotalSecundaria(
                                    e.target.value,
                                    values.wallet_artist_second_sale,
                                    setFieldValue
                                    );
                                }}
                               onKeyPress={event => {
                                   if (!/[0-9]/.test(event.key)) {
                                       event.preventDefault();
                                   }
                               }}
                            />
                            {errors.wallet_creator_second_sale && touched.wallet_creator_second_sale && (
                                <RequireMessage message={errors.wallet_creator_second_sale} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="row g-4 px-4 py-3">
                    <div className="col-12 col-md-6">
                        <div className="input-control mb-2">
                            <label className="form-label"> {t('wallet2')} </label>
                            <Field
                                className={`form-control  
                                    ${errors.wallet_artist && touched.wallet_artist ? "is-invalid" : ""}`}
                                name="wallet_artist"
                            />
                            {errors.wallet_artist && touched.wallet_artist &&
                                <RequireMessage message={t(errors.wallet_artist)} />}
                        </div>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('primarySale')}
                                <ButtonInfo targetInfo="firstArtist" message={t('tooltipStepTres3')}/>
                            </label>
                            <Field
                                className={`form-control  
                                    ${errors.wallet_artist_first_sale && touched.wallet_artist_first_sale ? "is-invalid" : ""}`}
                                name="wallet_artist_first_sale"
                                type="text"
                                onChange={e => {
                                setFieldValue("wallet_artist_first_sale", e.target.value)
                                calculaTotal(
                                    values.wallet_creator_first_sale,
                                    e.target.value,
                                    values.crowd_fund_first_sale,
                                    setFieldValue
                                    );
                                }}
                                onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                    }
                                }}
                            />
                            {errors.wallet_artist_first_sale && touched.wallet_artist_first_sale && (
                                <RequireMessage message={t(errors.wallet_artist_first_sale)} />
                            )}
                        </div>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('secondarySale')}
                                <ButtonInfo targetInfo="secondaryArtist" message={t('tooltipStepTres4')}/>
                            </label>
                            <Field
                                className={`form-control  
                                    ${errors.wallet_artist_second_sale && touched.wallet_artist_second_sale ? "is-invalid" : ""}`}
                                name="wallet_artist_second_sale"
                                type="text"
                                onChange={e => {
                                setFieldValue("wallet_artist_second_sale", e.target.value)
                                    calculaTotalSecundaria(
                                    values.wallet_creator_second_sale,
                                    e.target.value,
                                    setFieldValue
                                    );
                                }}
                                onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                    }
                                }}
                            />
                            {errors.wallet_artist_second_sale && touched.wallet_artist_second_sale && (
                                <RequireMessage message={errors.wallet_artist_second_sale} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="row g-4 bg-light border-top border-bottom my-2 mx-0 p-3">
                    <div className="col-12 col-md-6">
                        <div className="input-control mb-2">
                            <label className="form-label"> Fundo Para Adiar o Fim Do Mundo </label>
                            <Field
                                className="form-control-plaintext mt-1"
                                name="crowd_fund_wallet"
                                value={"0x9fD17B94d6AF41FBAd13cd04fF1309433A63249e"}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('primarySale')}
                                <ButtonInfo targetInfo="firstCrowd" message={t('tooltipStepTres5')}/>
                            </label>
                            <Field
                                className={`form-control  
                                    ${errors.crowd_fund_first_sale && touched.crowd_fund_first_sale ? "is-invalid" : ""}`}
                                name="crowd_fund_first_sale"
                                type="text"
                                onChange={e => {
                                setFieldValue("crowd_fund_first_sale", e.target.value)
                                    calculaTotal(
                                    values.wallet_creator_first_sale,
                                    values.wallet_artist_first_sale,
                                    e.target.value,
                                    setFieldValue
                                    );
                                }}
                                onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                    }
                                }}
                            />
                            {errors.crowd_fund_first_sale && touched.crowd_fund_first_sale && (
                                <RequireMessage message={errors.crowd_fund_first_sale} />
                            )}
                        </div>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('secondarySale')}
                                <ButtonInfo targetInfo="secondCrowd" message={t('tooltipStepTres6')}/>
                            </label>
                            <Field
                                className="form-control-plaintext mt-1"
                                name="wallet_fund_second_sale"
                                value="1"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="row g-4 px-4 py-3">
                    <div className="col-12 col-md-6">
                        <div className="input-control mb-2">
                            <label className="form-label"> Promoter Link </label>
                            <Field
                                className="form-control-plaintext"
                                name="crowd_project_wallet"
                                value={"0x000000000000000000000000000000000"}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('primarySale')}
                                <ButtonInfo targetInfo="firstPromoterLink" message={t('tooltipStepTres7')}/>
                            </label>
                            <Field
                                className="form-control-plaintext"
                                name="crowd_project_first_sale"
                                value="7"
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('secondarySale')}
                                <ButtonInfo targetInfo="secondaryPromoterLink" message={t('tooltipStepTres8')}/>
                            </label>
                            <Field
                                className="form-control-plaintext"
                                name="wallet_project_second_sale"
                                value="0"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="row g-4 bg-light border-top border-bottom p-3 mx-0 my-2">
                    <div className="col-12 col-md-6">
                        <div className="input-control mb-2">
                            <label className="form-label"> crowd project </label>
                            <Field
                                className=" form-control-plaintext"
                                name="crowd_project_wallet"
                                value={"0xB2178260FAEdFd86dB1BC8Cd0d4E91466Ca82eE9"}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('primarySale')}
                                <ButtonInfo targetInfo="firstCrowdProject" message={t('tooltipStepTres9')}/>
                            </label>
                            <Field
                                className="form-control-plaintext"
                                name="crowd_project_first_sale"
                                value="7"
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('secondarySale')}
                                <ButtonInfo targetInfo="secondaryCrowdProject" message={t('tooltipStepTres10')}/>
                            </label>
                            <Field
                                className="form-control-plaintext"
                                name="wallet_project_second_sale"
                                value="0"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="row g-2 border-bottom py-2">
                    <div className="col-12 col-lg-3 offset-lg-6">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap"> {t('total1')}
                                <ButtonInfo targetInfo="primaryTotal" message={t('tooltipStepTres11')}/>
                            </label>
                            <Field
                                className="form-control border-0 bg-transparent fs-3 ff-bold"
                                name="total_percentage"
                                disabled={true}
                                style={{}}
                            />

                            { errors.total_percentage && (
                                <RequireMessage message={t(errors.total_percentage)} />
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="input-control mb-2">
                            <label className="form-label text-nowrap">
                            {t('total2')}
                                <ButtonInfo targetInfo="secondaryTotal" message={t('tooltipStepTres12')}/>
                            </label>
                            <Field
                                className="form-control border-0 bg-transparent fs-3 ff-bold"
                                name="total_percentage_second"
                                disabled={true}
                            />
                            { errors.total_percentage_second && (
                                <RequireMessage message={t(errors.total_percentage_second)} />
                            )}
                        </div>
                    </div>
                </div>
                    { /*
                <div className="d-flex justify-content-between mt-4 mx-4">
                    <label className="form-label fs-6" htmlFor="whitelist">
                        Você fará estratégia de waitlist?
                    </label>
                    <div className="form-check form-switch">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="whitelist"
                            name="whitelist"
                        />
                    </div>
                </div>

               { values.whitelist && (
                    <div className="mx-4">
                        <div className="mt-2">
                            <div className="input-control mb-3">
                                <label className="form-label">Arquivo</label>
                                <Field
                                     className={`form-control  
                                        ${errors.whilelist_file && touched.whilelist_file ? "is-invalid" : ""}`}
                                    type="file"
                                    value={values.whilelist_file?.files }
                                    onChange={(e) => setFieldValue('whilelist_file', e.currentTarget.files[0])}
                                />
                                {errors.whilelist_file && touched.whilelist_file && <RequireMessage message="Selecione o arquivo" />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <div className="input-control mb-3">
                                    <label className="form-label"> Inicio waitlist </label>
                                    <InputMask
                                         className={`form-control 
                                            ${errors.begin_waitlist_date && touched.begin_waitlist_date ? "is-invalid" : ""}`}
                                        mask="99/99/9999 99:99"
                                        name="begin_waitlist_date"
                                        type="text"
                                        maskChar={null}
                                        placeholder="01/01/2023 10:10"
                                        value={values.begin_waitlist_date}
                                        onChange={e => setFieldValue("begin_waitlist_date", e.target.value)}
                                    />
                                    {errors.begin_waitlist_date && touched.begin_waitlist_date && <RequireMessage message={errors.begin_waitlist_date} />}
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="input-control mb-3">
                                    <label className="form-label"> Fim waitlist </label>
                                    <InputMask
                                        className={`form-control                                                                
                                             ${errors.end_waitlist_date && touched.end_waitlist_date ? "is-invalid" : ""}`}
                                        mask="99/99/9999 99:99"
                                        name="end_waitlist_date"
                                        type="text"
                                        maskChar={null}
                                        placeholder="01/01/2023 10:10"
                                        value={values.end_waitlist_date}
                                        onChange={e => setFieldValue("end_waitlist_date", e.target.value)}
                                    />
                                    {errors.end_waitlist_date && touched.end_waitlist_date && <RequireMessage message={errors.end_waitlist_date} />}
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="input-control mb-3">
                                    <label className="form-label"> Inicio do mint público </label>
                                    <InputMask
                                        className={`form-control                                                             
                                             ${errors.start_public_sale && touched.start_public_sale ? "is-invalid" : ""}`}
                                        mask="99/99/9999 99:99"
                                        name="start_public_sale"
                                        type="text"
                                        maskChar={null}
                                        placeholder="01/01/2023 10:10"
                                        value={values.start_public_sale}
                                        onChange={e => setFieldValue("start_public_sale", e.target.value)}
                                    />
                                    {errors.start_public_sale && touched.start_public_sale && <RequireMessage message={errors.start_public_sale} />}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <div className="input-control mb-3">
                                        <label className="form-label"> Valor waitlist </label>
                                        <Field
                                            className={`form-control                                                                   
                                                 ${errors.waitlist_price && touched.waitlist_price ? "is-invalid" : ""}`}
                                            name="waitlist_price"
                                            type="text"
                                            value={values.waitlist_price}
                                            onKeyPress={allowOnlyNumbersAndDots}
                                            onChange={e => setFieldValue("waitlist_price", e.target.value)}
                                        />
                                        {errors.waitlist_price && touched.waitlist_price
                                            && <RequireMessage message={errors.waitlist_price} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               )}
                                        */ }
                {/* <div className="d-flex justify-content-between mt-4 mx-4">
                    <label className="form-check-label" htmlFor="freemint">
                        Permitir mintar NFT's gratuitamente?
                    </label>
                    <div className="form-check form-switch">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="freemint"
                            name="freemint"
                        />
                    </div>
                </div>

                { values.freemint && (
                    <div className="mt-2 mx-4">
                        <div className="input-control mb-3">
                            <label className="form-label">Quantidade</label>
                            <Field
                                className={`form-control                                                                   
                                     ${errors.quantity_freemint && touched.quantity_freemint ? "is-invalid" : ""}`}
                                name="quantity_freemint"
                                onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {errors.quantity_freemint && touched.quantity_freemint &&
                                <RequireMessage message={errors.quantity_freemint} />}
                        </div>
                    </div>
                )} */}
    { /*
                <div className="d-flex justify-content-between mt-4 mx-4">
                    <label className="form-check-label" htmlFor="whitelist">
                        Iniciar contrato pausado?
                    </label>
                    <div className="form-check form-switch">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="contract_paused"
                            name="contract_paused"
                        />
                    </div>
                            </div> */ }
                {/*
                                                        <div className="d-flex justify-content-between mt-4">
                                                            <label className="form-check-label" htmlFor="whitelist">
                                                                Concordo com os termos crowdproject
                                                            </label>
                                                            <div className="form-check form-switch">
                                                                <Field className="form-check-input" type="checkbox" role="switch" id="agreement" name="agreement" required />
                                                            </div>
                                                        </div> */}
            </div>
        </>
    )

}
