import {useEffect, useState} from "react";

import {Carousel} from "../Carousel";

import {getCollections} from "services/collection.service";
import {getCategories} from "services/categories.service";
import {PlaceholderCardsHome} from "components/Placeholder";
import { useTranslation } from 'react-i18next';

export const Projects = () => {
    const [collections, setCollections] = useState([]);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false)

     const categoryTranslated = {
        "DAO":'DAO',
        'Arte': t('artC'),
        'Impacto':t('impactC'),
        'Fotografia':t('photographyC'),
        'Comunidade':t('comunityC'),
        'MetAmazonia': 'MetAmazonia'
    }
        

    useEffect(() => {
        const requestFilter = async () => {
            setLoading(true)
            const categories = await getCategories()

            let collectionsCategories = [];

            await Promise.all(
                await categories.data.map(async (category) => {
                    const response = await getCollections(category.id)
                    if(response.length > 0) {
                        collectionsCategories.push({category: category.name, data: response})
                    }
                })
            )
            setCollections(collectionsCategories);

        }
        requestFilter().then(() => setLoading(false))

    },[])


    return (
        <>

            { loading ?
                <div className="container mt-4 px-5" style={{display:'flex',gap:'20px',flexDirection:'column'}}>
                    <PlaceholderCardsHome />
                    <PlaceholderCardsHome />
                </div>
                :
                collections.map((collection, index) => (
                    <div key={index}>
                        <div className="container px-lg-2 mt-5">
                            <div className="mb-5">
                                <h2 className="label-custom mb-0">{categoryTranslated[collection.category]}</h2>
                                <Carousel
                                    collections={collection.data}
                                />
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}