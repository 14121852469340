import {db} from "../firebase";
import {doc, collection, getDoc, where, query, getDocs, orderBy, startAt, limit} from "firebase/firestore";

const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
const creatorsFirestore = process.env.REACT_APP_CREATORS_FB;
const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;

export const getTeste = async () => {
    const creatorRef = doc(db, "users", "JZv8xAtn96JYoSufdO70");

    const creator = await (await getDoc(creatorRef)).data();
    
}

export const getImage = async (idImage, collectionData) => {
  const imageRef = doc(db, imagesFirestore, idImage);
  const imageData = await (await getDoc(imageRef)).data();

  const images = {
    collectionId: imageData.collectionId,
    contract: collectionData.contract,
    reasonsToBelieve : collectionData.reasonsToBelieve,
    description : collectionData.description,
    shortDescription : collectionData.shortDescription,
    reasons_to_believe_files : collectionData.reasons_to_believe_files,
    symbol : collectionData.symbol,
    grouped : false,
    crowd_fund_first_sale : collectionData.crowd_fund_first_sale,
    imageId: idImage,
    locked: imageData.locked,
    index: imageData.index,
    name: imageData.name,
    network: imageData.network,
    nftValue: collectionData.nftEtherValue,
    owner: imageData.owner,
    status: imageData.status,
    url: imageData.url,
    traits: imageData.traits,
    external_url: imageData.external_url

  };

    
  return images;
}
 
export const getCollection = async (id,isApproved = true) => {    

    
    const docref = doc(db, collectionFirestore , id);
    const response = await getDoc(docref);

    if (!response.exists()) {        
        return {success: false, error: "coleção não encontrada"};
    }

    const collectionRef = response.data();

    if(isApproved){
        if(collectionRef.status !== "approved"){
            return []
        }
    }

    collectionRef.id = id;
    const creatorRef = doc(db,creatorsFirestore, collectionRef.wallet);

    const creator = await (await getDoc(creatorRef)).data();
    if (creator !== undefined) {
        collectionRef.creator = creator;
    }

    collectionRef.categories = await Promise.all(collectionRef.categories.map(async (element, index) => {
        const categorieRef = doc(db, "categories", element);
        const categorie = await (await getDoc(categorieRef)).data();

        return {
        name: categorie.name,
        icon: categorie.icon
        };
    })).finally();

    const {price, isOpenPublic, isOpenWhitelist} = priceForSale(collectionRef);

    collectionRef.nftEtherValue = price;
    collectionRef.isOpenPublic = isOpenPublic;
    collectionRef.isOpenWhitelist = isOpenWhitelist;

    const images = await generateNfts(collectionRef, docref.id, price);
    
    collectionRef.images = images;
    
    return collectionRef
}

export const getCollections = async (filterCategory = '', name = '', status = '', isAdmin = false) => {
  const filter = [];
  const search = [];

  if (filterCategory !== '' && filterCategory !== "all") {
    filter.push(filterCategory);
  }

  if (isAdmin) {
    if (name !== '') {
      search.push(where("name", ">=", name));
      search.push(where("name", "<=", name + '~'));
    }
  }

  if (filter.length > 0) {
    search.push(where("categories", "array-contains-any", filter));
  }

  if (!isAdmin) {
    search.push(where("status", "==", "approved"));

    if (status !== '') {
      search.push(where("status", "==", status));
    }
  }

  const docref = collection(db, collectionFirestore);
  const q = await query(docref, ...search);
  const querySnapshot = await getDocs(q);

  const response = [];
  querySnapshot.forEach((doc) => {
    const collectionRef = doc.data();
    response.push({
      id: doc.id,
      name: collectionRef.name,
      shortDescription: collectionRef.shortDescription,
      crowd_fund_first_sale: collectionRef.crowd_fund_first_sale,
      nftEtherValue: collectionRef.nftEtherValue,
      network: collectionRef.network,
      symbolImage: collectionRef.symbolImage,
      wallet: collectionRef.wallet,
      categories: collectionRef.categories,
      status: collectionRef.status,
      createdAt: collectionRef.created_at,
    });
  });

  const final = await Promise.all(response.map(async (element) => {
    const creatorRef = doc(db, creatorsFirestore, element.wallet);
    const creator = await (await getDoc(creatorRef)).data();
    if (creator !== undefined) {
      element.creator = creator;
    }

    element.categories = await Promise.all(element.categories.map(async (category) => {
      const categorieRef = doc(db, "categories", category);
      const categorie = await (await getDoc(categorieRef)).data();

      return {
        name: categorie.name,
        icon: categorie.icon
      };
    }));

    return element;
  }));

  return final;
};


export const getCollectionsHome = async () => {

    const docref = collection(db, collectionFirestore);
    const q = await query(docref, where("home", "==", true));
    const querySnapshot = await getDocs(q);
    const response = [];
    querySnapshot.forEach((doc) => {
        const collectionRef = doc.data();
        response.push({
            id: doc.id,
            name: collectionRef.name,
            nftEtherValue: collectionRef.nftEtherValue,
            network: collectionRef.network,
            symbolImage: collectionRef.symbolImage
        });
        
      });
    
    return response;
}

export const priceForSale = (collectionData) => {

    if(!collectionData.onlyWhitelisted) {
      return {
        price: collectionData.nftEtherValue,
        isOpenWhitelist: false,
        isOpenPublic: true
      };
    }

    const begin = new Date(collectionData.begin_waitlist_date * 1000);
    const end = new Date(collectionData.end_waitlist_date * 1000);
    const publicMint = new Date(collectionData.end_waitlist_date * 1000);
    const today = new Date();

    if(today >= begin && today <= end ) {
      return {
        price: collectionData.nftEtherWaitlistValue,
        isOpenWhitelist: true,
        isOpenPublic: false
      }      
    }
    
    if(today > publicMint ) {
      return {
        price: collectionData.nftEtherValue,
        isOpenWhitelist: false,
        isOpenPublic: true
      };
    }

    return {
      price: collectionData.nftEtherValue,
      isOpenWhitelist: false,
      isOpenPublic: false
    };
  }

export const  generateNfts = async (dataCollection, collectionId, priceNFt) => {

    if (Object.prototype.hasOwnProperty.call(dataCollection, "collectionGroup")) {

      const images =  await Promise.all(dataCollection.collectionGroup.map(async (element, index) => {
        
        const docRef = doc(db, collectionFirestore, element);        
        const collectionData = await (await getDoc(docRef)).data();
        
        const nfts = await getNfts(element, collectionData, 0 ,collectionData.symbol, true);

        return nfts[0];
      })).finally();
      return images.flat();
    }

    
    const nfts = await getNfts(collectionId, dataCollection, priceNFt, dataCollection.symbol);
 
    return nfts;
}
export const getNfts = async (collectionId, dataContract, priceNFt = 0, symbol, grouped = false, start = 0) => {
    const docrefImages = collection(db, imagesFirestore);
    let qImages;
    if(grouped) {      
      qImages = query(docrefImages, where("status", "==", 0), where("collectionId", "==", collectionId), orderBy("index", "asc"), startAt(start), limit(100));
    } else {
      qImages = query(docrefImages, where("collectionId", "==", collectionId), orderBy("index", "asc"), startAt(start), limit(100));
    }
    

    const imagesData = await getDocs(qImages);  
    const images = [];
    imagesData.forEach(async (doc) => {
      const info = doc.data();
      info.nftValue = ( priceNFt !== 0) ? priceNFt : info.nftValue ;
      info.contract = dataContract.contract;
      info.reasonsToBelieve = dataContract.reasonsToBelieve;
      info.description = dataContract.description;
      info.shortDescription = dataContract.shortDescription;
      info.reasons_to_believe_files = dataContract.reasons_to_believe_files;
      info.symbol = symbol;
      info.grouped = grouped;
      info.crowd_fund_first_sale = dataContract.crowd_fund_first_sale;
      images.push({...info , imageId: doc.id});
    });

    return images;
  }

  export const loadMoreNfts = async (collectionId, collectionData, priceNFT, symbol, grouped, start = 0 ) => {

    return await getNfts(collectionId, collectionData, priceNFT, symbol, grouped, start );
  }