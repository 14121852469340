import { useEffect, useState } from "react";

import { useApp } from "web3/providers/AppContext";

import { readContract, MintCollection, addTokenMetamask, switchNetwork, getPhase, getConditionPrice } from "web3/contracts/functions";
import { checkConditions } from "web3/contracts/utils";
import {db} from "../../firebase";
import {doc, updateDoc} from "firebase/firestore";

import { updatePinata } from "services/contract.service";

import { useParams, useLocation } from "react-router-dom";

import ModalDefault from "../ModalDefault";
import { useCollection } from "../../web3/providers/CollectionContext";

import { generatePinata } from "../../utils/utils";
import {ProcessMint} from "../ProcessMint";
import { FormProfileComponent } from "components/FormProfile";
import {useNavigate} from "react-router-dom";
import { ConnectModal } from "components/ConnectModal";
import { getCreator } from "services/creator.service";
import { useTranslation } from 'react-i18next';
const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;

export const ButtonMint = ({ children = "Comprar", amount, isOpenToBuy, setIsOpenToBuy}) => {
    const { nft, collectionData} = useCollection();
    const { t, i18n } = useTranslation();
    const stepsModal = [t('buy'), t('mint.processingRequest'), t('mint.waitingConfirm'), t('success'), 'Oops', t('mint.errorAddingToken')]

    const { signer, wallet, connection, creator,updateDataCreator } = useApp();

    const {network} = collectionData;

    const { collection } = useParams();

    const navigate = useNavigate()

    const location = useLocation();
    const [modal, setModal] = useState(false);
    const [connectModal, setConnectModal] = useState(false);     
    const [currentStepModal, setCurrentStepModal] = useState(null)
    const [currentMessageModal, setCurrentMessageModal] = useState(null)
    const [messageError, setMessageError] = useState(null)
    const [updatedCreator, setUpdatedCreator] = useState(null)
    const [showModal,setShowModal] = useState(false)
    const [txId, setTxId] = useState(false);

    const toggle = () => setModal(!modal);

    const handleToggleModal = () => {
        setShowModal(state => !state)
    }

    const handleCloseModal = () => {
        setConnectModal(false)
    }

    const affiliate = ( wallet ) => {
        const queryParams = new URLSearchParams(location.search);
        const affiliateAddress = queryParams.get("address");

        return {
            wallet:  affiliateAddress ? affiliateAddress : wallet,
            type: affiliateAddress ? "2" : "1"
        }
    };

    const initProcessBuy = async () => {
        try {
          if (!wallet) {
            setConnectModal(true);
          } else {
            localStorage.setItem("userWallet", wallet);
            await updateDoc(doc(db, imagesFirestore, nft.imageId), {
              locked: true,
            });
            setCurrentStepModal(0);
            toggle();
          }
        } catch (error) {
          console.error('Erro ao atualizar a propriedade "locked":', error);
        }
      };

      useEffect(() => {
        if(wallet){
            setConnectModal(false)
            }
    }, [wallet])
      

    const handleConnect = async () => {

        handleToggleModal()
        
    }
    
    const handleAddTokenToMetamask = () => {
        addTokenMetamask(nft.contract, nft.symbol, nft.url,connection)

            .then((r) => {

                

                if (!r) {
                    setCurrentStepModal(4)
                    setCurrentMessageModal(5)
                } else {
                    setCurrentStepModal(3)
                    setCurrentMessageModal(6)
                }
            })
    }

    const mint = async () => {

        setCurrentStepModal(1)
        setCurrentMessageModal(0)
        setMessageError(null);

        let provider = signer;
        
        let deployConnection = connection;
        let walletDeploy = wallet;
        let providerDeploy = provider;
        let signerDeploy = signer;
      
        const switchAcc = await switchNetwork(deployConnection, network, setCurrentMessageModal, 1);

        if (!switchAcc) {
            setCurrentStepModal(4)
            setCurrentMessageModal(1)
            return;
        }

        setCurrentStepModal(1)
        setCurrentMessageModal(2)

        try {
            const contractForMint = readContract(signerDeploy, nft.contract);
            
            const phase = await getPhase(contractForMint);
            
            await checkConditions(contractForMint, phase, collectionData.whitelist_addresses, walletDeploy);
            const nftEtherValue = await getConditionPrice(contractForMint);
            
            //const nftEtherValue = await getNftEtherValue(contractForMint);
            
            const affiliateLink = affiliate(walletDeploy);
            const {ipfs, nfts} = await generatePinata(collection, nft.imageId)
            const gasPrice = await providerDeploy.getGasPrice();
         

            setCurrentStepModal(2)
            const response = await MintCollection(contractForMint, walletDeploy, affiliateLink, amount, nftEtherValue, ipfs, collectionData, gasPrice, phase);

            const txId = response.transactionHash;
            setTxId(txId);

            await updatePinata(
                JSON.stringify({
                    collection: collection,
                    image: nft.imageId,
                    wallet: wallet,
                    txId: txId,
                    nfts: nfts,
                    imagesFB: imagesFirestore,
                    affiliateWallet: affiliateLink.wallet,
                    affiliateType: affiliateLink.type
                }),
            );

            setCurrentStepModal(3)
            setCurrentMessageModal(4)

            const updatedCreator = await getCreator(wallet)
            updateDataCreator(updatedCreator)
            setIsOpenToBuy(true)

            
        } catch(e) {

            setMessageError(t(e.message));     
            console.log("EOKEOEEKOK -> ", e.message);      

            setCurrentStepModal(4)
            setCurrentMessageModal(3)
        }
    };
    

    useEffect(() => {
        const userWallet = localStorage.getItem("userWallet");
      
        if (!modal && (userWallet === wallet)) {
          const docRef = doc(db, imagesFirestore, nft.imageId);
          updateDoc(docRef, {
            locked: false,
          })
            .then(() => {
             
            })
            .catch((error) => {
              console.error('Erro ao atualizar a propriedade "locked":', error);
            });
        }
      }, [modal, nft.imageId, wallet]);

    const cantbuy = nft.status || isOpenToBuy

    return (
        <>
            <button type="button" className="btn btn-secondary rounded-pill btn-lg w-100" onClick={initProcessBuy}
                    disabled={(!nft.grouped) ? cantbuy : false}
            >
                {children}
            </button>

            <ModalDefault
                isOpen={modal}
                closeModal={toggle}
                size={!creator?.completedProfile && 'lg' }
                label={stepsModal[currentStepModal]}
                closeButton={!currentStepModal}
            >
            {
            !creator?.completedProfile ? <FormProfileComponent subTitle={t('mint.completeRegister')} isMintModal={true} closeModal={toggle} /> : (
                <ProcessMint
                setModal={setModal}
                setIsOpenToBuy={setIsOpenToBuy}
                isOpenToBuy={isOpenToBuy}
                mint={mint}
                handleModal={toggle}
                currentStepModal={currentStepModal}
                currentMessageModal={currentMessageModal}
                handleAddTokenToMetamask={handleAddTokenToMetamask}
                messageError={messageError}
                txId = {txId}
            />
                        )}                  
            </ModalDefault>

            <ModalDefault
              isOpen={connectModal}
              closeModal={handleCloseModal}
              closeButton={handleCloseModal}>
            <>
                <div className="px-3 d-flex flex-column gap-3">
                    <span className="ff-normal">{t('mint.connect')}</span>

                    <div className="d-flex gap-2">
                        <button
                            className="btn btn-secondary w-50"
                            onClick={() => navigate('/')}
                        >
                        {t('mint.cancel')}
                        </button>

                        <button
                            className="btn btn-primary w-50"
                            onClick={() => handleConnect()}
                        >
                           {t('mint.connectOrCreate')}
                        </button>
                    </div>
                </div>
                <ConnectModal showModal={showModal} handleToggleModal={handleToggleModal} label={t('mint.connectLabel')} setShowModal={setShowModal}/>
            </>
            </ModalDefault>
        </>
    );
};
