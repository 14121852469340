import { useEffect, useState } from "react";

import {Link, Outlet, useNavigate} from "react-router-dom";
import { HeaderPage } from "../../components/HeaderPage";
import { FilterStatusUser } from "../../components/FormFilters";
import {useUsers} from "../../hooks/useApi";

import { useQuery } from 'react-query'
import {Toast} from "../../components/Alert";

const UsersAdmin = () => {

    const [data, setData] = useState([])
    const [filterStatus, setFilterStatus] = useState('')

    const api = useUsers()
    const navigate = useNavigate()

    const { isFetching } = useQuery(["users_list", filterStatus],
        () => {
            return api.listUsers(filterStatus);
        }, {
            onSuccess: (response) => {
                setData(response.data)
            },
            onError: () => {
                Toast.fire({
                    icon: 'error',
                    title: 'Falha na requisição'
                })
            },
            retry: false,
          //  refetchOnWindowFocus: true
        }
    )


    const handleFilterStatus = (value) => {
        setFilterStatus(value)
    }

    return (
        <div className="container">
            <Outlet />

            <HeaderPage
                label="Usuários"
                icon="bi bi-people"
                description=""
            />

            <div className="card mb-3 border-0 shadow-sm p-3">
                <div className="row g-3 justify-content-between">
                    <div className="col-12 col-sm-8 col-md-3">
                        <FilterStatusUser onChange={handleFilterStatus} />
                    </div>

                    <div className="col-12 col-sm-8 col-md-3 text-end">
                        <button type="button" className="btn btn-success" onClick={() => navigate('add')}>
                            <i className="bi bi-plus"/> Adicionar usuário
                        </button>
                    </div>
                </div>
            </div>

            <div className="card border-0 shadow-sm">
                <div className="">
                    <table className="table mt-1">
                        <thead>
                            <tr>
                                <th className="py-3 ps-3">Nome</th>
                                <th className="py-3">Email</th>
                                <th className="py-3">Status</th>
                                <th className="py-3"></th>
                            </tr>
                        </thead>
                        <tbody>

                        { isFetching &&
                            <tr>
                                <td colSpan={5} className="text-center py-3">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        }

                        { !data.length && !isFetching &&
                            <tr>
                                <td colSpan={5} className="text-center py-3">Nenhum registro encontrado</td>
                            </tr>
                        }

                        { !isFetching && data?.map(item =>
                            <tr key={item.id}  valign="middle">
                                <td className="ps-3">
                                    <span className="fw-bolder"> {item.name} </span>
                                </td>
                                <td>
                                    { item.email }
                                </td>
                                <td>
                                    { item.status
                                        ? <span className="badge text-bg-success">Ativo</span>
                                        : <span className="badge text-bg-danger">Inativo</span>
                                    }
                                </td>
                                <td>
                                    <Link to={`edit/${item.id}`} className="btn btn-sm btn-outline-primary">
                                        <i className="bi bi-pencil-fill"/>
                                    </Link>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default UsersAdmin