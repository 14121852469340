import styled from 'styled-components';

export const ContentCreator = styled.div`
    .card {
        background-color: #1e1e1e;
        border-radius: 20px;
    }
    
    .card-image {
        position: relative;
    }
    
    .card-image .avatar-creator {
        position: absolute;
        bottom: -30px;
        justify-content: center;
    }
    
    .card-image .avatar-creator img {
        width: 80px;
        height:80px;
        border: 3px solid #1e1e1e;
    }
    
    .card-image img {
        height: 180px;
        object-fit: cover;
    }

    .card .avatar-creator {
        width:100%;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 50%;
    }

    .card .avatar-creator span {
        font-size: 1.2rem;
    }
    
    .avatar-creator img {
        border-radius: 50%;
        object-fit: cover;
        width: 2.5em;
        height: 2.5em;
    }
    
    .image-collection {
        width: 100%;
        height: 15rem;
        object-fit: cover;
        border: 2px solid #FFFFFF;
    }
   
`;
