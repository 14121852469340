import styled from "styled-components";

export const ButtonConnectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img{
        width: 20px;
        height: 20px;
    }
`