import React, {useState, useEffect} from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cards";

import { EffectCards } from "swiper";
import {CarouselContainer, ShareAndEarnButton} from "./styles";
import {Link} from "react-router-dom";

import {getCollectionsHome} from "../../../../services/collection.service";
import {networkParams} from "../../../../web3/networks/networks";
import { useApp } from "web3/providers/AppContext";
import ModalDefault from "components/ModalDefault";
import { useTranslation } from 'react-i18next';
import { Share } from "components/Share";

export const CarouselCards = () => {
    const { t, i18n } = useTranslation();
    const [collections, setCollections] = useState([])
    const { wallet, connectWallet } = useApp();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const requestCollections = async () => {
            const response = await getCollectionsHome()
            setCollections(response);
        }
        requestCollections()

    },[])

    const toggleModal = async () => {
        if (!wallet) {
          const connection = await connectWallet();
          if (connection.address) {
            setIsOpen(!isOpen);
          }
        } else {
          setIsOpen(!isOpen);
        }
      };

      const closeModal = () => {
        setIsOpen(false);
      };

    return (
        <CarouselContainer className="mx-0" style={{height:'200px'}}>
            <Swiper
                effect={"cards"}
                loop={true}
                grabCursor={true}
                modules={[EffectCards]}
                cardsEffect={{
                    perSlideRotate: 0,
                    perSlideOffset: 15,
                }}
                initialSlide={3}
                allowSlidePrev={true}
                allowSlideNext={false}
                centeredSlides={false}

                className="mySwiper"
            >
                { collections?.map((collection) => (
                <React.Fragment key={collection.id} >
                    <SwiperSlide key={collection.id}>
                        <div className="card-collection w-100">
                            <div style={{width: "100%"}} >
                                <img
                                    src={collection.symbolImage} alt=""
                                    style={{height: "415px", width: "100%", objectFit: "cover", borderRadius: "20px"}}
                                />
                            </div>
                            <div className=" px-2 d-flex justify-content-between align-items-center text-black">
                                <div style={{marginTop:'-3px'}}>
                                    <span style={{ fontSize: "15px"}}>{collection.name}</span>
                                    <p className="ff-bold" style={{marginTop: "-12px"}}>
                                        {collection.nftEtherValue} { networkParams[collection.network].crypto}
                                    </p>
                                </div>
                                <ShareAndEarnButton
                                    onClick={toggleModal}
                                >
                                    share and earn
                                </ShareAndEarnButton>
                            </div>
                        </div>
                        <ModalDefault
                        isOpen={isOpen}
                        closeModal={closeModal}
                        label={t('share')}
                        centered={true}
                        size="sm"
                    >
                        <Share collectionId={collection.id} />
                        
                    </ModalDefault>
                    </SwiperSlide>
                </React.Fragment>
                ))}

            </Swiper>
        </CarouselContainer>
    )
}