import styled from 'styled-components'


export const ContentSidebarAccount = styled.div`

    .offcanvas {
        margin: 20px;
        border-radius: 20px;
    }
    
    @media (max-width: 600px) {   
        .offcanvas {
            margin: 0;
            border-radius: 0;
        }
    }
    
    .avatar {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-color: #4158D0;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    }
   
    .avatar img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        border: 2px solid #FFF;
    }

`

export const ListMenuSideBar = styled.ul`
    list-style: none;

    li {
        margin: 1rem 0;
    }
    
     li:first-child {
        margin-top: 0;
    }
    
    li a {
        text-decoration: none;
        color: #333;
        
        font-size: 1.5rem;
        font-family: 'Figtree Font Bold';
        
        display: flex;
        gap: 10px;
        align-items: center;
        
        line-height: 22px;
        
        transition: margin-left 0.5s;
        
    }
    
    li a:hover {
        margin-left: 10px;
    }
    
    li a i {
        transition: color 0.6s;
    }
`