import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { limitarString, truncateAddress } from "../../../../../utils/utils";
import { useCollection } from "../../../../hooks/useApi";
import { useQueryClient } from "react-query";
import { Toast } from "../../../../components/Alert";
import {getCollection} from "services/collection.service"
import { fontSize } from "@mui/system";

export const FormCollection = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
  const api = useCollection();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const request = async () => {
      setLoading(true);
      const response = await getCollection(id, false);
      console.log(response);
      setStatus(response.status);
      setData(response);
    };
    request().then(() => setLoading(false));
  }, []);

  const saveCollection = async () => {
    const dataUpdate = {
      collection: id,
      status: status,
      collectionFB: collectionFirestore,
    };

    await api.updateCollection(dataUpdate).then((r) => {
      

      Toast.fire({
        icon: "success",
        title: "Coleção alterada com sucesso!",
      });

      queryClient.invalidateQueries(["collection_list"]);
      navigate(`/admin/collections`);
    });
  };

  if (loading) return "Aguarde...";

  const network = () => {
    const networkMap = {
      "1": "Etherium",
      "137": "Polygon",
      "80001": "Mumbai"
    };
  
    return networkMap[data?.network];
  };

  
  
  return (
    <>
      <div className="d-flex gap-3 align-items-center">
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <img
            src={data?.symbolImage}
            alt={data?.name}
            width={150}
            height={150}
            style={{ objectFit: "cover" }}
            className="rounded-3"
          />
          <h3 style={{ fontSize: "14px" }}>Dados do criador:</h3>

          <div
            style={{
              fontSize: "13px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <p className="mb-0">
              Email:
              <span className="fw-bolder"> {data?.creator?.email}</span>
            </p>
            <p className="mb-0">
              Número:
              <span className="fw-bolder"> {data?.creator?.celular}</span>
            </p>
            <p className="mb-0">
              Nome:
              <span className="fw-bolder"> {data?.creator?.name}</span>
            </p>
            <p className="mb-0">
              Nickname:
              <span className="fw-bolder"> {data?.creator?.nickname}</span>
            </p>
            <p className="mb-0">
              Data de nascimento:
              <span className="fw-bolder"> {data?.creator?.birth_date}</span>
            </p>
            <p className="mb-0">
              Celular:
              <span className="fw-bolder"> {data?.creator?.celular}</span>
            </p>
            <p className="mb-0">
              Cpf:
              <span className="fw-bolder"> {data?.creator?.cpf}</span>
            </p>
            <p className="mb-0">
              Discord:
              <a
                className="fw-bolder"
                target="_blank"
                href={data?.creator?.discord}
              >
                {" "}
                {data?.creator?.discord}
              </a>
            </p>
            <p className="mb-0">
              Instagram:
              <a
                className="fw-bolder"
                target="_blank"
                href={data?.creator?.instagram}
              >
                {" "}
                {data?.creator?.instagram}
              </a>
            </p>
            <p className="mb-0">
              Site:
              <a
                className="fw-bolder"
                target="_blank"
                href={data?.creator?.site}
              >
                {" "}
                {data?.creator?.site}
              </a>
            </p>
            <p className="mb-0">
              Twitter:
              <a
                className="fw-bolder"
                target="_blank"
                href={data?.creator?.twitter}
              >
                {" "}
                {data?.creator?.twitter}
              </a>
            </p>
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px", flexDirection: "column", fontSize:'14px' }}>
          <p className="mb-0">
            Contrato:
            <span className="fw-bolder">
              {" "}
              {truncateAddress(data?.contract)}
            </span>
          </p>
          <p className="mb-0">
            Nome da coleção:
            <span className="fw-bolder"> {data?.name}</span>
          </p>
          <p className="mb-0">
            Network:
            <span className="fw-bolder"> {network()}</span>
          </p>
          <p className="mb-0">
            Símbolo:
            <span className="fw-bolder"> {data?.symbol}</span>
          </p>
          <p className="mb-0">
            Preço:
            <span className="fw-bolder"> {data?.nftEtherValue}</span>
          </p>
          <p className="mb-0">
            Itens:
            <span className="fw-bolder"> {data?.images?.length}</span>
          </p>
          <p className="mb-0">
            Criador Venda Primária:
            <span className="fw-bolder"> {data?.wallet_creator_first_sale}</span>
          </p>
          <p className="mb-0">
            Criador Venda Secundária:
            <span className="fw-bolder"> {data?.wallet_creator_second_sale}</span>
          </p>
          <p className="mb-0">
            Carteira Secudária Venda Primária:
            <span className="fw-bolder"> {data?.wallet_artist_first_sale}</span>
          </p>
          <p className="mb-0">
            Carteira Secudária Venda Secundária:
            <span className="fw-bolder"> {data?.wallet_artist_second_sale}</span>
          </p>
          <p className="mb-0">
            Benefícios:
            <span className="fw-bolder"> {data?.benefits}</span>
          </p>
          <p className="mb-0">
            Razões para acreditar:
             {
                data?.reasons_to_believe_files?.map((reason) => (
                    <div style={{display:'flex',fontSize:'11px',gap:'10px'}}>
                    <p>{reason.name}</p>
                    <a href={reason.url}>{limitarString(reason.url,40)}</a>
                    </div>
                ))
            }
          </p>
          <p className="mb-0">
            Discord:
            <span className="fw-bolder"> {data?.discord}</span>
          </p>
          <p className="mb-0">
            Instagram:
            <span className="fw-bolder"> {data?.instagram}</span>
          </p> <p className="mb-0">
            Twitter:
            <span className="fw-bolder"> {data?.twitter}</span>
          </p>
          <p className="mb-0">
            Site:
            <span className="fw-bolder"> {data?.site}</span>
          </p>
          <p className="mb-0">
            Status:
            <span className="fw-bolder"> {data?.status}</span>
         </p>
            <p className="mb-0">
              Descrição Curta:
              <span className="fw-bolder"> {data?.shortDescription}</span>
            </p>
          <p className="mb-0">
            Descrição:
            <span className="fw-bolder" >
              {" "}
              {data?.description}
            </span>
          </p>
        </div>
      </div>

      <div
        style={{
          overflowX: "scroll",
          display: "flex",
          gap: "12px",
        }}
      >
        {data?.images?.map((item, index) => (
          <img
            key={index}
            src={item.url}
            width={150}
            height={150}
            style={{ objectFit: "cover" }}
            className="rounded-3 my-2"
          />
        ))}
      </div>

      <div className="card p-3">
        <div className="d-flex align-items-center justify-content-between">
          <label className="form-check-label" htmlFor="active">
            Status
          </label>
          <div className="form-check form-switch">
            <select
              className="form-select"
              defaultValue={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Selecione</option>
              <option value="approved">Aprovar</option>
              <option value="denied">Negar</option>
            </select>
          </div>
        </div>
      </div>

      <button className="btn btn-primary" onClick={() => saveCollection()}>
        Salvar
      </button>
    </>
  );
};
