import styled from 'styled-components';

export const ListNFTContainer = styled.div `
    max-height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
    
    padding: 1rem;
`;


