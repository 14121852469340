import { useEffect, useState } from "react";
import { getCollections, getCollectionsAdmin } from "../../../services/collection.service";
import { Link, Outlet } from "react-router-dom";
import {HeaderPage} from "../../components/HeaderPage";
import {FilterQuery, FilterStatusCollection} from "../../components/FormFilters";
import {useQuery} from "react-query";
import {Toast} from "../../components/Alert";
import {useCollection} from "../../hooks/useApi";


const CollectionsAdmin = () => {

    const [collections, setCollections] = useState([])
    const [loading, setLoading] = useState(false)
    const [filterStatus, setFilterStatus] = useState('')
    const [filter, setFilter] = useState('')
    const [filteredCollections, setFilteredCollections] = useState()
    const [isAdmin, setIsAdmin] = useState(true);

    

    const api = useCollection()

    useEffect(() => {
        const request = async () => {
            setLoading(true)
            const response = await getCollections('', filter, filterStatus,true);
            setCollections(response)
        }
        request().then( () => setLoading(false) )
    }, [filter, filterStatus])


    const handleFilterStatus = (value) => {
        setFilterStatus(value);
        if(value === 'all' || value === '') {
            setFilteredCollections(collections);
            return;
        }
        filterCollections(value, filter);
    }
    
    const handleFilter = (value) => {
        setFilter(value);
        filterCollections(filterStatus, value);
    }
    
    const filterCollections = (status, text) => {
        const filtered = collections
            .filter(item => item.status === status || status === 'all' || status === '')
            .filter(item =>  item.name.toLowerCase().includes(text.toLowerCase())) ;
        setFilteredCollections(filtered);
    }

    console.log("Abc->",collections);

    return (
        <div className="container-fluid">
            <Outlet />

            <HeaderPage
                label="Coleções"
                icon="bi bi-files"
                description="Ative e desative as coleções"
            />

            <div className="card mb-3 border-0 shadow-sm p-3">
                <div className="row justify-content-between">
                    <div className="col-12 col-sm-8 col-md-4">
                        <FilterQuery value={filter} onChange={handleFilter} />
                    </div>
                    <div className="col-12 col-sm-8 col-md-3">
                       <FilterStatusCollection  onChange={handleFilterStatus} />
                    </div>
                </div>
            </div>

            <div className="card border-0 shadow-sm">
                <div className="">
                    <table className="table mt-1">
                        <thead>
                            <tr>
                                <th className="py-3">#</th>
                                <th className="py-3" colSpan={2}>Projeto</th>
                                <th className="py-3">Autor</th>
                                <th className="py-3">Status</th>
                                <th className="py-3">Ação</th>
                            </tr>
                        </thead>
                        <tbody>

                        { loading &&
                            <tr>
                                <td colSpan={5} className="text-center py-3">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        }


                        { !filteredCollections?.length && !collections?.length  && !loading &&
                            <tr>
                                <td colSpan={5} className="text-center py-3">Nenhum registro encontrado</td>
                            </tr>
                        }

                        {filteredCollections ? (
                            <>
                            { !loading && filteredCollections?.map((item, index) =>
                                <tr key={item.id}  valign="middle">
                                    <td>{index + 1}</td>
                                    <td style={{width: "80px"}} className="ps-3">
                                        <img
                                            src={item.symbolImage}
                                            className="img-fluid rounded-2"
                                            style={{height: "50px", width: "50px", objectFit: "cover"}}
                                        />
                                    </td>
                                    <td>
                                        <div className="d-grid">
                                            <span className="fw-bolder"> {item.name} </span>
                                            <span> {item.symbol} </span>
                                        </div>
    
                                    </td>
                                    <td>
                                        {item.creator?.name}
                                    </td>
                                    <td>
                                        { item.status === 'approved'
                                            && <span className="badge text-bg-success">Aprovado</span> }
    
                                        { item.status === 'denied'
                                            && <span className="badge text-bg-danger">Negado</span> }
    
                                        { item.status === 'submitted'
                                            && <span className="badge text-bg-info">Aguardando aprovação</span> }
    
                                        { item.status === 'blank'
                                            && <span className="badge text-bg-warning">Não finalizado</span> }
                                    </td>
                                    <td>
                                        <Link to={`edit/${item.id}`} className="btn btn-sm btn-outline-primary">
                                            <i className="bi bi-pencil-fill"/>
                                        </Link>
                                    </td>
                                </tr>
                            )}
                            </>
                        ) : (
                            <>
                            { !loading && collections?.map((item, index) =>
                                <tr key={item.id}  valign="middle">
                                    <td>{index + 1}</td>
                                    <td style={{width: "80px"}} className="ps-3">
                                        <img
                                            src={item.symbolImage}
                                            className="img-fluid rounded-2"
                                            style={{height: "50px", width: "50px", objectFit: "cover"}}
                                        />
                                    </td>
                                    <td>
                                        <div className="d-grid">
                                            <span className="fw-bolder"> {item.name} </span>
                                            <span> {item.symbol} </span>
                                        </div>
    
                                    </td>
                                    <td>
                                        {item.creator?.name}
                                    </td>
                                    <td>
                                        { item.status === 'approved'
                                            && <span className="badge text-bg-success">Aprovado</span> }
    
                                        { item.status === 'denied'
                                            && <span className="badge text-bg-danger">Negado</span> }
    
                                        { item.status === 'submitted'
                                            && <span className="badge text-bg-info">Aguardando aprovação</span> }
    
                                        { item.status === 'blank'
                                            && <span className="badge text-bg-warning">Não finalizado</span> }
                                    </td>
                                    <td>
                                        <Link to={`edit/${item.id}`} className="btn btn-sm btn-outline-primary">
                                            <i className="bi bi-pencil-fill"/>
                                        </Link>
                                    </td>
                                </tr>
                            )}
                            </>
                        )}

                        
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default CollectionsAdmin