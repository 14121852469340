import {Formik, Field, Form} from 'formik';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useCategories} from "../../../../hooks/useApi";
import {useMutation, useQueryClient} from "react-query";
import {Toast} from "../../../../components/Alert";

export const FormCategory = () => {

    const [category, setCategory] = useState(null)

    const {id} = useParams()
    const navigate = useNavigate()
    const api = useCategories()
    const queryClient = useQueryClient()

    useEffect(() => {
        const request = async () => {
            const response = await api.getCategory(id)
            setCategory(response)
        }

        request()

    }, [id])

    const create = async (values) => {
        createCategoryMutation.mutate(values)
    }

    const update = async (values) => {
        const dataUpdate = {
            'id': id,
            'name': values.name,
            'icon': values.icon,
            'home': values.home,
            'status' : values.status
        }
        updateCategoryMutation.mutate(dataUpdate)
    }

    const updateCategoryMutation = useMutation(async (data) => {
            return api.updateCategory(data)
        }, {
            onSuccess: (res) => {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria alterada com sucesso!'
                })
                queryClient.invalidateQueries(["categories_list"])
                navigate(`/admin/categories`);
            },
            onError: (err) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Falha na requisição'
                })
            }
        }
    )

    const createCategoryMutation = useMutation(async (data) => {
            return api.createCategory(data)
        }, {
            onSuccess: (res) => {
                if (!res.id) {
                    Toast.fire({
                        icon: 'error',
                        title: res.error
                    })
                    return
                }

                Toast.fire({
                    icon: 'success',
                    title: 'Categoria criada com sucesso!'
                })
                queryClient.invalidateQueries(["categories_list"])
                navigate(`/admin/categories`);
            },
            onError: (err) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Falha na requisição'
                })
            }
        }
    )

    const icons = [
        "bi bi-card-image",
        "bi bi-image",
        "bi bi-images",
        "bi bi-file-earmark-music",
        "bi bi-file-music",
        "bi bi-music-note",
        "bi bi-music-note-beamed",
        "bi bi-music-note-list",
        "bi bi-camera-video",
        "bi bi-camera-reels",
        "bi bi-brush",
        "bi bi-globe",
        "bi bi-tornado",
        "bi bi-ticket"
    ]

    return (
        <div className="px-4">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: category ? category?.name : '',
                    icon: category?.icon ?? '',
                    home: category?.home ?? false,
                    status: category?.status ?? false
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    

                    id
                        ? update(values)
                        : create(values)
                }}
            >
                {
                    ({isSubmitting, setFieldValue, values}) => (
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nome</label>
                                <div className="input-group">
                                    <Field
                                        id="name"
                                        name="name"
                                        placeholder=""
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>

                            <label className="form-label">Ícone da categoria</label>
                            <div className="dropdown">
                                <a className="btn btn-secondary text-start border w-100" href="#" role="button"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="d-flex justify-content-between">
                                    {values.icon ? <i className={ values?.icon } /> : 'Selecione um ícone' }
                                     <i className="bi bi-chevron-down"/>
                                    </span>
                                </a>

                                <ul className="dropdown-menu">
                                    <div className="row px-3">
                                        { icons.map((icon, index) =>
                                            <div className="col-3">
                                                <div key={index} className="d-flex align-items-center gap-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        role="switch"
                                                        id="icon"
                                                        name="icon"
                                                        value={icon}
                                                        onChange={(e) => setFieldValue('icon', e.target.value )}
                                                    />
                                                    <i className={`${icon} fs-5`}/>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </ul>
                            </div>

                            <div className="card p-3 mt-3">
                                <div className="d-flex justify-content-between">
                                    <label className="form-check-label" htmlFor="active">
                                        Exibir categoria na home
                                    </label>
                                    <div className="form-check form-switch">
                                        <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="home"
                                            name="home"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="card p-3 mt-3">
                                <div className="d-flex justify-content-between">
                                    <label className="form-check-label" htmlFor="active">
                                        Ativar categoria
                                    </label>
                                    <div className="form-check form-switch">
                                        <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="status"
                                            name="status"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="pt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    disabled={isSubmitting}
                                >
                                    { isSubmitting
                                        ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Aguarde...</span>
                                          </div>
                                        : 'Salvar'
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}