import React, {createContext, useContext, useEffect, useState} from "react";

import {useApi} from "../../hooks/useApi";

export const AuthContext = createContext(0);

export const AuthProvider = (props) => {

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)

    const api = useApi()

    useEffect(() => {
        const validateToken = async () => {

            const storageToken = localStorage.getItem('authToken');

            if (storageToken) {
                setLoading(true)
                const response = await api.getUser(storageToken)
                if (response.success) {
                    setUser({
                        name: response.data.name,
                        email: response.data.email,
                    })
                    setLoading(false)
                } else {
                   setUser(null)
                }

            }
        }
        validateToken().then(() => {setLoading(false)});
    }, [])

    const login = async (values) => {

        const email = values.email
        const password = values.password

        return await api.signin(email, password)
            .then((response) => {
                if (response.success) {
                    setLoading(true)
                    setUser({
                        name: response.data.name,
                        email: response.data.email,
                    })

                    localStorage.setItem("authToken", response.data.id);

                    setLoading(false)
                    return true
                }
                return false
            })
            .catch(() => {
                return false
            })

    }

    function logout() {
        localStorage.setItem("authToken", '');
        setUser(null)
    }

    if (loading) { return <></>}

    return (
        <AuthContext.Provider
            value={{
                login:login,
                logout:logout,
                user: user
            }}>
            { props.children }
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);
