import React, {useState} from 'react';
import { ModalAdmin } from '../../../../components/Modal';

const Sales = ({ dados, loading }) => {
  const urlMarketplace = process.env.REACT_APP_URL;

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({})

  const infoOwner = (creatorName, address, email, phone, googleWallet = false, cpf, nft, collection, purchased, pag) => {
    setModalOpen(true);
    setModalData({
      name: creatorName,
      address: address,
      email: email,
      phone: phone,
      googleWallet: googleWallet,
      cpf: cpf,
      nft: nft,
      collection: collection,
      purchased: purchased,
      pag: pag
    })
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }
  
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Item</th>
            <th>Projeto</th>
            <th>Pagamento</th>
            <th>Valor</th>
            <th>Comprado por</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={7} className="text-center py-3">
                  <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                  </div>
              </td>
            </tr>
          ) : (
            dados.map((item, index) => (
              
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.collectionName} <small><a className="small" href={`${urlMarketplace}${item.collectionId}`} target="_blank" rel="noopener noreferrer"><i className="bi bi-box-arrow-up-right"></i></a></small></td>
                <td>{(item.pix) ? 'Pix': 'Crypto'}</td>
                <td>
                  {(item.pix) ? (
                    <>
                    {parseFloat(item.realValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </>
                  ) : (
                    <>
                    {item.nftValue} {parseInt(item.network) === 80001 || parseInt(item.network) === 137 ? 'MATIC' : 'ETH'}
                    </>
                  )}
                  </td>
                <td><strong className='cursor-pointer' onClick={() => infoOwner(item.CreatorName, item.CreatorWallet, item.CreatorEmail, item.CreatorPhone, item.CreatorGoogleWallet, item.CreatorCpf, item.name, item.collectionName, item.purchased, index.pix)}>{!item.CreatorName ? (item.CreatorNickname) : (item.CreatorName)}</strong></td>            
                <td>{item.purchased}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {isModalOpen && (
        <ModalAdmin isOpen={isModalOpen} location="dashboard" closeModal={handleCloseModal} label="Dados do comprador">
          <h4 className="fw-semibold m-0">{modalData.name}</h4>
          <p className='m-0'>{modalData.address} <small>{modalData.googleWallet ? 'Google' : 'Metamask'}</small></p>
          <p className='m-0 text-underline'>Comprou o item <strong>{modalData.nft}</strong> da coleção <strong>{modalData.collection}</strong> pagando com <strong>{modalData.pag ? 'PIX' : 'crypto'}</strong> em {modalData.purchased}.</p>
          <hr className='border-secondary' />
          <ul className='m-0 ps-3'>
            <li className='pb-1'>CPF: {modalData.cpf}</li>
            <li className='pb-1'>E-mail: {modalData.email}</li>
            <li>Telefone: {modalData.phone}</li>
          </ul>
        </ModalAdmin>
      )}
    </>
  );
};

export default Sales;