import * as S from '../styles'
import { useState } from 'react'
import { useApp } from "web3/providers/AppContext";
import {generateEmailCode} from "../../../services/pix.service";
import { ErrorStep } from './errorStep';

export const  SecondStepPix = ({changeStep,setEmailValue,emailValue,closeModal}) => {
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [invalidEmailApi, setInvalidEmailApi] = useState(false)

  const { wallet, creator } = useApp();

  const sendEmailCode = async () => {
    
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) { 
      setIsValidEmail(false)
      
      return;
    }
    const email = {
      email: emailValue
    }
    const response = await generateEmailCode(email)
    if(!response.success){
      setInvalidEmailApi(true)
      return;
    }
    setIsValidEmail(true)
    changeStep();
  }

if(invalidEmailApi){
  return(
    <ErrorStep errorMsg={"Problema ao enviar Email"} buttonLabel={'Refazer compra'} changeStep={closeModal}/>
  )
}

return(
    <S.CheckoutContainer>
      <S.EmailDetails>
        <div>
        <strong>
        Confirme o endereço da sua carteira.
        </strong>
       {' '} Em seguida, insira um endereço de e-mail para receber o código de verificação It's Ramp.
        </div>
      </S.EmailDetails>

      <S.InputContainer>
        <label htmlFor="wallet">Carteira de destino</label>
        <input type="text" id="wallet" style={{padding:'12px'}}  value={wallet} disabled/>

        <label htmlFor="email">E-mail</label>
        <input type="text" id="email" style={{padding:'12px'}}  onChange={(e) => setEmailValue(e.target.value)} placeholder='exemplo@exemplo.com' value={emailValue}/>
       { !isValidEmail && <span className='error'>Email invalido</span>} 
      </S.InputContainer>

      <S.ContinueButton onClick={() => sendEmailCode()}>
        Continuar
      </S.ContinueButton>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}