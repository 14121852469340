import {Button, PopoverBody, UncontrolledPopover} from "reactstrap";
import Swal from "sweetalert2";
import { useRef, useState} from "react";
import {BannerContent, PlaceholderBanner, PlaceholderImage, ProfileContent} from "./styles";
import {Formik, Form, Field} from 'formik';
import InputMask from 'react-input-mask';

import { formSchema, validateName} from './utils'

import {useApp} from "../../../../web3/providers/AppContext";
import {updateCreator} from "../../../../services/creator.service";
import {TabTitle, uploadFile} from "../../../../utils/utils";
import {swalCustom} from "../../../../components/SweetAlert";
import useAuth from "hooks/useAuth";
import { useTranslation } from 'react-i18next';

const FormCreate = ({ closeModal }) => {
    TabTitle("Crowd | Perfil")
    const { t, i18n } = useTranslation();

    const { creator,wallet } = useApp()
    const {googleAccount} = useAuth()

    const [bannerProfile, setBannerProfile] = useState([]);
    const [imageProfile, setImageProfile] = useState([]);

    const inputFile = useRef(null);
    const inputFileBanner = useRef(null);

    function handleChangeImageProfile(e) {
        setImageProfile(e.target.files);
    }

    function handleChangeImageBanner(e) {
        setBannerProfile(e.target.files);
    }

    const PopoverInfo = (props) => {

        const supportedTypes = 'JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF.'

        return (
            <UncontrolledPopover
                placement="bottom"
                target={props.target}
                trigger="focus"
            >
                <PopoverBody>
                    <div className="d-grid">
                        <small>{t('supportedFormats')} <b> {props.supported ?? supportedTypes} </b></small>
                        <small>{t('maxSize')}  <b>100 MB</b></small>
                        <small>{t('fileLimits')}  <b>{props.limit}</b></small>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        )
    }

    const RequireMessage = ({message}) => <span
        className="text-danger small"> {message ?? t('requiredField')} </span>

        

    return (
        <ProfileContent>
            <Formik
                enableReinitialize={true}
                initialValues={{
                name: creator.name ?? '',
                nickname: creator.nickname ?? '',
                biography: creator.biography ?? '',
                birth_date: creator.birth_date ?? '',
                cpf: creator.cpf ?? '',
                celular: creator.celular ?? '',
                site: creator.site ?? '',
                discord: creator.discord ?? '',
                twitter: creator.twitter ?? '',
                instagram: creator.instagram ?? '',
                email: creator.email ?? '',
                address: wallet,
                }}
                validationSchema={formSchema}
                onSubmit={ async (values) => {
                    
                    Swal.fire({
                        title: t('privateKey.waiting'),
                        html: t('mint.processingRequest'),
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    })
                    const folderBucket = "creators/" + creator.address + "/";                    
                    if (imageProfile.length > 0) {
                        values['profile_avatar'] = await uploadFile(imageProfile[0], folderBucket, "avatar")
                    }

                    if (bannerProfile.length > 0) {
                        values['profile_banner'] = await uploadFile(bannerProfile[0], folderBucket, "banner")
                    }

                    updateCreator(values).then((r) => {
                            if (!r.success) {
                                swalCustom.fire({
                                    title: 'Oops!',
                                    html: r.error,
                                    icon: 'error',
                                    confirmButtonText: t('privateKey.closeButton'),
                                })                                
                                return;
                            }
                            swalCustom.fire({
                                icon: 'success',
                                title: t('success'),
                                html: t('userUpdate'),
                                allowOutsideClick: false,
                                
                            })
                    })
                    closeModal()
                }}
            >
                {({
                      errors,
                      touched,
                      values,
                      setFieldValue
                  }) => (
                    <Form>
                        <div className="row mt-5">
                            <div className="col-12 col-lg-4">
                                <div className="card rounded-5">
                                    <div className="card-body rounded-5 py-5 px-4">
                                        <h4>
                                            {t('profileImg')}
                                            <Button
                                                id="infoCollection"
                                                type="button"
                                                className="link bg-transparent border-0"
                                            >
                                                <i className="fa-sharp fa-solid fa-circle-info text-info fs-5 ms-2"/>
                                            </Button>
                                        </h4>
                                        <PopoverInfo target="infoCollection" limit="1"/>

                                        <div className="image-profile mt-4">

                                            { imageProfile.length > 0 &&
                                                <img
                                                    src={ URL.createObjectURL(imageProfile[0]) }
                                                    className="img-fluid rounded-circle"
                                                    alt="Imagem do perfil"
                                                    onClick={() => inputFile.current.click()}
                                                />
                                            }

                                            { !imageProfile.length && creator.profile_avatar &&
                                                <img
                                                    src={creator.profile_avatar}
                                                    className="img-fluid rounded-circle"
                                                    alt="Imagem do perfil"
                                                    onClick={() => inputFile.current.click()}
                                                />
                                            }

                                            { !imageProfile.length && !creator.profile_avatar &&
                                                <PlaceholderImage onClick={() => inputFile.current.click()}>
                                                    <i className="bi bi-person-fill"/>
                                                </PlaceholderImage>
                                            }
                                        </div>

                                        <div className="mt-5">
                                            <input
                                                className="btn btn-primary"
                                                type="file"
                                                onChange={handleChangeImageProfile}
                                                ref={inputFile}
                                            />

                                            <button
                                                className="btn btn-primary w-100"
                                                onClick={() => inputFile.current.click()}
                                                type="button"
                                            >
                                                 {t('changeImg')}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-7">
                                <div className="card rounded-5">
                                    <div className="card-body  rounded-5 border-0 p-5">
                                        <h4>
                                        {t('profileBanner')}
                                            <Button
                                                id="infoBannerCollection"
                                                type="button"
                                                className="link bg-transparent border-0"
                                            >
                                                <i className="fa-sharp fa-solid fa-circle-info text-info fs-5 ms-2"/>
                                            </Button>
                                        </h4>
                                        <PopoverInfo target="infoBannerCollection" limit="1" supported="JPG, PNG"/>

                                        <BannerContent>

                                            { bannerProfile.length > 0 &&
                                                <img
                                                    src={URL.createObjectURL(bannerProfile[0])}
                                                    width={"100%"}
                                                    className="banner-profile"
                                                    alt=""
                                                />
                                            }

                                            { !bannerProfile.length && creator.profile_banner &&
                                                <img
                                                    src={creator.profile_banner}
                                                    width={"100%"}
                                                    className="banner-profile"
                                                    alt=""
                                                />
                                            }

                                            { !bannerProfile.length && !creator.profile_banner &&
                                                <PlaceholderBanner>
                                                    <i className="bi bi-file-image"/>
                                                </PlaceholderBanner>
                                            }

                                            <input
                                                className="btn btn-primary "
                                                type="file"
                                                onChange={handleChangeImageBanner}
                                                ref={inputFileBanner}
                                            />

                                            <button
                                                className="btn btn-primary teste"
                                                onClick={() => inputFileBanner.current.click()}
                                                type="button"
                                            >
                                                Alterar imagem
                                            </button>
                                        </BannerContent>


                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label">  {t('fullName')} </label>
                                                    <Field
                                                        className="form-control"
                                                        name="name"                                                        
                                                    />
                                                    {errors.name && touched.name &&
                                                        <RequireMessage message={errors.name}/>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> {t('userName')} </label>
                                                    <Field
                                                        className="form-control"
                                                        name="nickname"
                                                    />
                                                    {errors.nickname && touched.nickname &&
                                                        <RequireMessage message={errors.nickname}/>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label">{t('birthDate')} </label>
                                                    <InputMask
                                                        className="form-control"
                                                        mask="99/99/9999"
                                                        name="birth_date"
                                                        type="text"
                                                        maskChar={null}
                                                        value={values.birth_date}
                                                        onChange={(e) => setFieldValue('birth_date', e.target.value)}
                                                    />
                                                    {errors.birth_date && touched.birth_date &&
                                                        <RequireMessage message={errors.birth_date}/>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> CPF </label>
                                                    <InputMask
                                                        className="form-control"
                                                        mask="999.999.999-99"
                                                        name="cpf"
                                                        type="text"
                                                        maskChar={null}
                                                        value={values.cpf}
                                                        onChange={(e) => setFieldValue('cpf', e.target.value)}
                                                    />
                                                       {errors.cpf && touched.cpf &&
                                                        <RequireMessage message={errors.cpf}/>}
                                                </div>
                                            </div>

                                        
                                        </div>

                                        <div className="input-control mb-2">
                                            <label className="form-label"> {t('biograph')} </label>
                                            <Field
                                                as="textarea"
                                                className="form-control"
                                                name="biography"
                                            />
                                            {errors.biography && touched.biography && <RequireMessage/>}
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> E-mail </label>
                                                    <Field
                                                        disabled={googleAccount}
                                                        className="form-control"
                                                        name="email"
                                                    />
                                                       {errors.email && touched.email &&
                                                        <RequireMessage message={errors.email}/>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> {t('cellphone')} </label>
                                                    <InputMask
                                                        className="form-control"
                                                        mask="(99) 99999-9999"
                                                        name="celular"
                                                        type="text"
                                                        maskChar={null}
                                                        value={values.celular}
                                                        onChange={(e) => setFieldValue('celular', e.target.value)}
                                                    />
                                                     {errors.celular && touched.celular &&
                                                        <RequireMessage message={errors.celular}/>}
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className="mt-3"> {t('socialMedias')}</h5>

                                        <div className="input-control mb-2">
                                            <label className="form-label">  {t('yourSite')}</label>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">
                                                    <i className="bi bi-globe"/>
                                                </span>
                                                <Field
                                                    className="form-control"
                                                    name="site"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Twitter </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">
                                                            <i className="bi bi-twitter" />
                                                        </span>
                                                        <Field
                                                            className="form-control"
                                                            name="twitter"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Discord </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">
                                                            <i className="bi bi-discord" />
                                                        </span>
                                                        <Field
                                                            className="form-control"
                                                            name="discord"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Instagram </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">
                                                            <i className="bi bi-instagram" />
                                                        </span>
                                                        <Field
                                                            className="form-control"
                                                            name="instagram"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 pt-4 border-top">
                                            <button className="btn btn-lg btn-primary w-100" type="submit">
                                               {t('save')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </ProfileContent>
    )
}

export default FormCreate