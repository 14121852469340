import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;


export const ModalContent = styled.div`
  position: relative;
  width: 500px;
  height: 430px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
`;

export const CloseButton = styled.button`
  position: absolute;
  color: black;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
`

export const ModalTextsContainer = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
align-items: center;
`

export const ModalButtonsContainer = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`