import {createGlobalStyle} from 'styled-components';


const AdminStyles = createGlobalStyle`
:root {
    --header-height: 3rem;
    --nav-width: 70px;
    --first-color: ${({theme}) => theme.primary};
    --first-color-light: #AFA5D9;
    --white-color: #F7F6FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}

*, ::before, ::after {
    box-sizing: border-box
}

body {
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .5s;
    background-color: ${({theme}) => theme.body};
}

a {
    text-decoration: none
}

.cursor-pointer{
    cursor: pointer !important;
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: ${({theme}) => theme.bg200};
    z-index: var(--z-fixed);
    transition: .5s
}

.header_toggle {
    color: #FFF;
    font-size: 1.5rem;
    cursor: pointer
}

.icon-user {
    color: #FFFFFF;
    font-size: 2rem;
}

.header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}

.header_img img {
    width: 40px
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: ${({theme}) => theme.bgNav};
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden
}


.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;
}



.nav_link {
    position: relative;
    color: ${({theme}) => theme.color};
    margin-bottom: 0rem;
    transition: .3s;
}

.nav_link i { 
    transition: .5s all;   
    transition-delay: 0s;
}

.nav_link:hover {
    color: ${({theme}) => theme.color};
}

.nav_link.active {
    color: var(--first-color);
}

.nav_icon {
    color: #d6d6d6;
    font-size: 1.5rem
}

.nav_link.active .nav_icon {
    color: var(--first-color);
}

.nav_link:hover i {
    color: var(--first-color);
        transition-delay: 0s;
}

.show {
    left: 0
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem);
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 3px;
    height: 40px;
    background-color: var(--first-color);
}

.height-100 {
    height: 100vh
}

.bg-header-page {
    background-color: ${({theme}) => theme.bg100};
}

.text-header {
    color: ${({theme}) => theme.color};
}

.table tr {
    background-color: ${({theme}) => theme.bg100};
    color: ${({theme}) => theme.color};
    border-color: ${({theme}) => theme.border};
}

.table.table-hover tr:hover {
    color: ${({theme}) => theme.color};
}

.modal-content {
    background-color: ${({theme}) => theme.bg100};
    color: ${({theme}) => theme.color};
}

.modal-footer, .modal-header {
    border-color: ${({theme}) => theme.border};
}

.card {
    background-color: ${({theme}) => theme.bg100};
}

.card-body {
    background-color: ${({theme}) => theme.bg100};
}
    
.text-blue {
    color: #112d4e;
}

.form-select, .form-control, .form-control:active, .form-control:focus {
    background-color: ${({theme}) => theme.bg100};
    color: ${({theme}) => theme.color};
}

.circle-icon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.bg100};
    color: ${({theme}) => theme.color};
    display: flex;
    justify-content: center;
    align-items: center;
    place-content:center;
}

@media screen and (max-width: 767px) {

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 2rem
    }
    
    .l-navbar {
        
        padding: 0;
    }
    
    .nav_name {
        display: none;
    }

    .nav-brand {
        text-align: center;
        padding: 0.9rem 1rem;
        background-color: ${({theme}) => theme.bg200};
        height: 4rem;
    }
}

@media screen and (min-width: 768px) {
    body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem)
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    .header_img {
        width: 40px;
        height: 40px
    }

    .header_img img {
        width: 45px
    }

    .l-navbar {
        left: 0;
        padding: 0;
    }

    .nav-brand {
        text-align: center;
        padding: 0.9rem 1.5rem;
        background-color: ${({theme}) => theme.bg200};
        height: 4rem;
    }
    
    .nav_list{
        margin-top: 10px;
        left: 0;
    }

    .l-navbar.show {
        width: calc(var(--nav-width) + 156px);
    }
    
    .content-pd {
        padding-left: calc(var(--nav-width) + 80px)
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 80px);
        transition: .3s;
    }
}

.l-navbar:not(.show) .nav-brand {
    padding: 0.75rem 1.2rem;
    text-align: left;
}

.l-navbar:not(.show) .nav_link span{
    display: none;
    transition: .3s;
}

.btn.btn-primary {
    color: #FFFFFF !important;
}

.hide-btn {
    .btn-close {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .modal.show {
        width: 100%;
    }
    
    .modal-backdrop {
        width: 100%;
    }
}

`;

export default AdminStyles;
