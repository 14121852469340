import FormProfile from "./components/FormProfile";
import { useEffect, useState } from "react";
import { ProfileContent } from "./styles";
import { useApp } from "../../web3/providers/AppContext";
import { ListNFT } from "components/ListNFT";
import ListCollection from "components/ListCollection/ListCollection";
import EmptyMessage from "components/EmptyMessage/EmptyMessage";
import {useParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const Profile = () => {
    const { creator, layoutHeader } = useApp();
    const { t, i18n } = useTranslation();

    const { tab } = useParams()
    const [tabActive, setTabActive] = useState(tab)

    const enableNfts = creator?.nfts?.length > 0 && tabActive  === 'my-nfts'
   

    useEffect(() => {
        layoutHeader(2);
    }, [layoutHeader]);



    return (
        <ProfileContent>
            <div className="container my-5">
                <div className="d-flex justify-content-center mt-5">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                             onClick={() => setTabActive('my-profile')}
                                className={`nav-link ${tab === 'creator' ? 'active' : ''}`}
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false">
                                <i className="bi bi-person fs-5" /> {t("profile")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                onClick={() => setTabActive('my-projects')}
                                className={`nav-link ${tab === 'my-projects' ? 'active' : ''}`}
                                id="pills-collections-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-collections"
                                type="button"
                                role="tab"
                                aria-controls="pills-collections"
                                aria-selected="false">
                                <i className="bi bi-tag fs-5" /> {t("myProjects")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                onClick={() => setTabActive('my-nfts')}
                                className={`nav-link ${tab === 'my-nfts' ? 'active' : ''}`}
                                id="pills-my-nfts-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-my-nfts"
                                type="button"
                                role="tab"
                                aria-controls="pills-my-nfts"
                                aria-selected="false">
                                <i className="bi bi-grid fs-5" /> {t("myNft")}
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className={`tab-pane fade ${tab === 'creator' ? 'show active' : ''}`}
                        id="pills-profile" role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <FormProfile />
                    </div>
                    <div className={`tab-pane fade ${tab === 'my-projects' ? 'show active' : ''}`}
                         id="pills-collections"
                         role="tabpanel"
                         aria-labelledby="pills-collections-tab"
                    >
                        {creator?.collections?.length > 0 ? <ListCollection collections={creator?.collections} /> : <EmptyMessage message={t('withoutProjects')} />}
                    </div>
                </div>

                <div className={`tab-pane fade ${tab === 'my-nfts' ? 'show active' : ''}`}
                     id="pills-my-nfts" role="tabpanel"
                     aria-labelledby="pills-my-nfts-tab"
                >
                    { enableNfts ? <ListNFT isProfile nfts={creator?.nfts} /> : <EmptyMessage message={t('withoutNfts')} />}
                </div>

            </div>
        </ProfileContent>
    );
};
