import styled from 'styled-components';

export const CardCollect = styled.div`
   
    .card {
        background: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 20px;
        transition: transform 0.5s;
        z-index: 1;
    }
    
    .card:hover {
        transform: translate(0, -8px);
    }
    
    .card-content-text {
        background: #FFFFFF;
        color: ${props => props.theme.primary};
    }

    
    .avatar img {
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        width: 2.5em;
        height: 2.5em;
        object-fit: cover;
    }
    
    .image-collection {
        width: 100%;
        height: 5rem;
        object-fit: cover;
        border: 2px solid #FFFFFF;
    }
    
    .chain {
        position: absolute;
        left: 1rem;
        top: 1rem;
        background-color: #3333334a;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        opacity: 0;
                
        display: flex;
        align-items: center;
        justify-content: center;
        
        transition: opacity 0.8s;
    }
    
    :hover {
        .chain {
            opacity: 1;
        }
    }
    
   
`;

export const CardContentText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    height: ${props => props.height ?? 'auto'};
    
    background: #FFFFFF;
    color: ${props => props.theme.black};
   
    border-radius: 25px;
`