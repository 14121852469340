import {Fragment, useEffect, useState} from "react";
import {getCategories} from "../../services/categories.service";
import {CategoriesContainer} from "./styles";
import { useTranslation } from 'react-i18next';

export const FilterCategories = ({ handleFilter, filter}) => {

    const [categories, setCategories] = useState([])
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const requestCategories = async () => {
            const response = await getCategories()
            setCategories(response.data)
        };
        requestCategories()
    }, []);

    const categoryTranslated = {
        "DAO":'DAO',
        'Arte': t('artC'),
        'Impacto':t('impactC'),
        'Fotografia':t('photographyC'),
        'Comunidade':t('comunityC'),
    }


    return (
        <CategoriesContainer>
            <input
                type="radio"
                className="btn-check"
                name="categories"
                id="all"
                value="all"
                autoComplete="off"
                onClick={(e) => handleFilter(e.target.value)}
            />
            <label
                className={`btn-check-custom mx-3 lh-lg 
                    ${!filter ? 'active-tab' : filter === "all" ? 'active-tab' : ''}`}
                htmlFor="all"
                role="button"
            >
                {t('allC')}
            </label>

            { categories.map((category, index) => (
                <Fragment key={index}>
                    <input
                        type="radio"
                        className="btn-check"
                        name="categories"
                        id={category.id}
                        value={category.id}
                        autoComplete="off"
                        onClick={(e) => handleFilter(e.target.value)}
                    />
                    <label
                        className={`btn-check-custom mx-2 lh-lg 
                                            ${category.id === filter
                            ? 'active-tab'
                            : ''
                        }`}
                        htmlFor={category.id}
                        role="button"
                    >
                        {categoryTranslated[category.name]}
                    </label>
                </Fragment>
            ))}
        </CategoriesContainer>
    )
}