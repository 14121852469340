import {ListNFTContainer} from "./styles";
import { CardNFTPreview } from "../CardNFTPreview";

export const ListNFTPreview = ({ nfts, network }) => {
    return (
        <ListNFTContainer>
            <div className="row g-3">
                {nfts?.map((nft, index) =>
                    <div key={index} className="col-12 col-sm-6 col-lg-4">
                        <CardNFTPreview nft={nft} network={network} />
                    </div>
                )}
            </div>
        </ListNFTContainer>
    );
};
