import * as Yup from 'yup';
import {
    validateDate,
    validateMinAmountPerWallet,
    validateMinCrowdFund,
    validateNameCollection,
    validateRoyaltiesWallets,
    validateSalesWallets,
    validateTotalFirstSale,
    validateTotalSecondSale,
    validateWallet
} from "./utils";

const messageRequired = "requiredField";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    Yup.object().shape({
        categories: Yup.array().min(1, "selectCategories").required(messageRequired),
        name: Yup.string()
        .required('requiredField')
        .test('name-validate', 'nameCollectionNoNumbers', function(value) {
          return validateNameCollection(value);
        }),    
        symbol: Yup.string().required(messageRequired),
        description: Yup.string().required(messageRequired).max(700, 'max700chars'),
        short_description: Yup.string().required(messageRequired).max(75, 'max75chars'),
        network: Yup.string().required("selectNetwork"),
        value: Yup.string().required(messageRequired),
    }),
     Yup.object().shape({
         banner_collection: Yup.array().min(1, "selectImgBanner"),
         image_symbol: Yup.array().min(1, "selectImgCollection"),
         imagesNft: Yup.array().min(1, "selectAtLeastOneImg")
    }),
    Yup.object().shape({
        amount_wallet: Yup.string().required(messageRequired)
            .test('amount-per-wallet-validate', 'errorMaxMintDef', function(value) {
                const { parent } = this;
                return validateMinAmountPerWallet(parent.amount , value)
        }),
        wallet_creator: Yup.string().required(messageRequired)
            .test('wallet-validate', 'invalidWallet', function(value){
                return validateWallet(value)
        }),
        wallet_artist: Yup.string().test('wallet-validate', 'invalidWallet', function(value){                
                if(value === undefined) {
                    return true;
                }
                return validateWallet(value)
        }),
        wallet_creator_first_sale: Yup.number().required(messageRequired)
            .test('sale-validate', 'valueNotPermited', function(value){
                return validateSalesWallets(value)
        }),
        wallet_creator_second_sale: Yup.number().required(messageRequired)
            .test('royalties-validate', 'valueNotPermited', function(value){
                return validateRoyaltiesWallets(value)
        }),
        wallet_artist_first_sale: Yup.number().required(messageRequired)
            .test('sale-validate', 'valueNotPermited', function(value){
                return validateSalesWallets(value)
        }),
        wallet_artist_second_sale: Yup.number().required(messageRequired)
            .test('royalties-validate', 'valueNotPermited', function(value){
                return validateRoyaltiesWallets(value)
        }),
        crowd_fund_first_sale: Yup.number().required(messageRequired)
            .test('royalties-validate', 'valueNotPermited', function(value){
                return validateMinCrowdFund(value)
        }),
        total_percentage: Yup.number().required(messageRequired)
            .test('total-validate', "invalidValue", function(value) {
                return validateTotalFirstSale(value)
        }),
        total_percentage_second: Yup.number().required(messageRequired)
            .test('total-secondary-validate', "invalidValue", function(value) {
                return validateTotalSecondSale(value)
        }),

        whilelist_file: Yup.mixed()
            .test('file-validate', 'selectFile', function(value) {
                const { parent } = this;
                if (parent.whitelist && !value) {
                    return false
                }
                return true
        }),

        begin_waitlist_date: Yup.string()
            .test('date-validate', 'invalidDate', function(value) {
                const { parent } = this;
                if (parent.whitelist) {
                    if(!value) {
                        return false
                    }
                    return validateDate(value)
                }
                return true
        }),
        end_waitlist_date: Yup.string()
            .test('date-validate', 'invalidDate', function(value) {
                const { parent } = this;
                if (parent.whitelist) {
                    if(!value) {
                        return false
                    }
                    return validateDate(value)
                }
                return true
        }),
        start_public_sale: Yup.string()
            .test('date-validate', 'invalidDate', function(value) {
                const { parent } = this;
                if (parent.whitelist) {
                    if(!value) {
                        return false
                    }
                    return validateDate(value)
                }
                return true
        }),
        waitlist_price: Yup.number()
            .test('price-validate', messageRequired, function(value) {
                const { parent } = this;
                if (parent.whitelist) {
                    if(!value) {
                        return false
                    }
                }
                return true
        }),
        quantity_freemint: Yup.number()
            .test('free-mint-validate', messageRequired, function(value) {
                const { parent } = this;
                if (parent.freemint) {
                    if(!value) {
                        return false
                    }
                }
                return true
        }),
    }),
];

