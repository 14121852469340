import styled from 'styled-components';

export const Content = styled.div `
    background-color: #161616;
`;

export const CarouselContainer = styled.div `
    
    margin-top: 0;

    .swiper {
        padding-top: 100px;
    }
    
    .swiper, swiper-container {
        padding: 30px 35px;
    }
    
    @media screen and (max-width: 400px) {
        .swiper, swiper-container {
            padding: 30px ;
        }
    }
    
    .swiper-button-next svg, .swiper-button-prev svg {
        height: 50px;
        color: ${props => props.theme.black};
    }
        
    .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
      content: none;
    }

    .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
        content: none;    
    }
`;
