import { useState } from 'react';

import { CardCollect, CardContentText } from "./styles";
import { useApp } from "web3/providers/AppContext";
import { Link } from "react-router-dom";
import { networkParams } from "web3/networks/networks";
import ModalDefault from "components/ModalDefault";
import {Share} from "components/Share";

import EthereumImg from "assets/img/svg/ethereum.svg";
import PolygonImg from "assets/img/svg/polygon.svg";

import { UncontrolledTooltip } from 'reactstrap';
import {truncateAddress} from "utils/utils";
import { useTranslation } from 'react-i18next';

const CardCollection = ({ collection }) => {
    const { symbolImage, name, id } = collection;
    const { t, i18n } = useTranslation();
    const { wallet, connectWallet } = useApp();
    const network = networkParams[collection.network].crypto;

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = async () => {
        if(!wallet) {
            const connection = await connectWallet();
            if(connection.address) {
                setOpenModal(!openModal)
            }
        } else {
            setOpenModal(!openModal)
        }
    };

    const closeModal = () => {
        setOpenModal(false)
    }

    return (
        <CardCollect>

            <div className="card">
                <div className="card-image mx-2 mt-2">
                    <Link to={`/collection/${id}`}>
                        <div className="position-relative">
                            <img src={symbolImage} className="img-fluid rounded-4 border-3 w-100" alt="" style={{ height: "200px", objectFit: "cover" }} />

                            <UncontrolledTooltip placement="right" target={`chain_${id}`}>
                                Rede: { networkParams[collection.network].network_name}
                            </UncontrolledTooltip>

                            <span className="chain" role="button" id={`chain_${id}`}>
                                <img src={network === 'ETH' ? EthereumImg : PolygonImg} width={25} alt="" />
                            </span>
                        </div>
                    </Link>
                </div>

                <div className="card-body d-grid gap-0 py-2">
                    <Link className="ff-bold text-decoration-none text-nowrap text-black mb-0" to={`/collection/${id}`}>
                        {name.length > 20 ? name.substr(0,20)+ '...' : name}
                    </Link>
                    <CardContentText className="py-1">
                        {/*<span className="ff-normal">{collection.nftEtherValue} {network}</span>*/}
                        <span className="ff-normal">
                            {collection.creator?.nickname
                                ? '@'+ collection.creator.nickname
                                : truncateAddress(collection.wallet)}
                        </span>
                        {/*<div>*/}
                        {/*    <button*/}
                        {/*        className="btn btn-outline-secondary rounded-pill "*/}
                        {/*        onClick={handleOpenModal}*/}
                        {/*    >*/}
                        {/*        Share and earn*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </CardContentText>
                </div>
            </div>
            <ModalDefault
                isOpen={openModal}
                closeModal={closeModal}
                label={t('share')}
                centered={true}
                size="sm"
            >
                <Share collection={collection.collection} />
            </ModalDefault>
        </CardCollect>
    );
};
export default CardCollection;
