import CardCollection from "components/CardCollection";
import React from "react";
import { PlaceholderCards } from "components/Placeholder";

const ListCollection = ({ loading, collections, creator }) => {
    

    return (
        <div className="col-12 col-xl-12 mt-4">
            <div className="row mb-5 g-4">
                {loading && <PlaceholderCards />}
                {!loading &&
                    collections?.map(collection => {
                        if (collection.status !== "blank") {
                            return (
                                <React.Fragment key={"collection-" + (collection?.id ? collection.id : collection)}>
                              
                                <div key={"container-collection-" + (collection?.id ? collection.id : collection)} className="col-12 col-md-6 col-xl-4">
                                  <CardCollection key={"collection-" + (collection?.id ? collection.id : collection)} collection={collection} creator={creator ? creator : collection.creator} />
                                </div>
                            
                                </React.Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
            </div>
        </div>
    );
};
export default ListCollection;
