export const networkParams = {
    1: {
        key: "3ac68be0e4f44833bce00d483f3bd104",
        endpoint: "https://mainnet.infura.io/v3/",
        url: "https://mainnet.infura.io/v3/3ac68be0e4f44833bce00d483f3bd104",
        scan: "https://etherscan.io/tx/",
        address: "https://etherscan.io/address/",
        chainId: 1,
        crypto: "ETH",
        network_name: "Ethereum Mainnet",
        name_select: 'Ethereum',
        rpc_urls: "https://mainnet.infura.io/v3/",
        block_explorer_urls: "https://etherscan.io",
        decimal: 18,
        ramp_network_name: "ETH",
        production: (process.env.REACT_APP_ENVIRONMENT  === 'dev' || process.env.REACT_APP_ENVIRONMENT  === 'prod' ) ? true : false
    },
    5: {
      key: "3ac68be0e4f44833bce00d483f3bd104",
      endpoint: "https://goerli.infura.io/v3/",
      url: "https://goerli.infura.io/v3/3ac68be0e4f44833bce00d483f3bd104",
      scan: "https://goerli.etherscan.io/tx/",
      address: "https://goerli.etherscan.io/address/",
      chainId: 5,
      crypto: "ETH",
      network_name: "Goerli Testnet",
      name_select: 'Goerli',
      rpc_urls: "https://goerli.infura.io/v3/",
      block_explorer_urls: "https://goerli.etherscan.io",
      decimal: 18,
      ramp_network_name: "GOERLI_TEST",
      production: (process.env.REACT_APP_ENVIRONMENT  === 'dev') ? true : false
    },
    137: {
      key: "3ac68be0e4f44833bce00d483f3bd104",
      endpoint: "https://polygon-mainnet.infura.io/v3/",
      url: "https://polygon-mainnet.infura.io/v3/3ac68be0e4f44833bce00d483f3bd104",
      scan: "https://polygonscan.com/tx/",
      address: "https://polygonscan.com/address/",
      chainId: 137,
      crypto: "MATIC",
      network_name: "Polygon",
      name_select: 'Polygon',
      rpc_urls: "https://polygon-rpc.com",
      block_explorer_urls: "https://polygonscan.com",
      decimal: 18,
      ramp_network_name: "MATIC",
      production: (process.env.REACT_APP_ENVIRONMENT  === 'dev' || process.env.REACT_APP_ENVIRONMENT  === 'prod' ) ? true : false
    },
    80001: {
      key: "3ac68be0e4f44833bce00d483f3bd104",
      endpoint: "https://polygon-mumbai.infura.io/v3/",
      url: "https://polygon-mumbai.infura.io/v3/3ac68be0e4f44833bce00d483f3bd104",
      scan: "https://mumbai.polygonscan.com/tx/",
      address: "https://mumbai.polygonscan.com/address/",
      chainId: 80001,
      crypto: "MATIC",
      network_name: "Mumbai Polygon",
      name_select: 'Mumbai',
      rpc_urls: "https://rpc-mumbai.maticvigil.com",
      block_explorer_urls: "https://mumbai.polygonscan.com",
      decimal: 18,
      ramp_network_name: "MUMBAI_TEST",
      production: (process.env.REACT_APP_ENVIRONMENT === 'dev') ? true : false
    }
};