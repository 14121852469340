import {Button, PopoverBody, UncontrolledPopover} from "reactstrap";

import { Field } from "formik";

import { FileInput } from "components/FileInput";
import { ModalApp } from "components/Modal";

import React, {useState} from "react";
import {RequireMessage} from "../utils";

import {RenameFiles} from "../../../../../components/RenameFiles";
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    img {
        transition: 0.5s;
    }
    
    .overlay {
        opacity: 0;
        transition: 0.2s;
    }
    
    &:hover {
        .overlay { 
         opacity: 1;
        }
        
        img { 
         opacity: 0.35;
        }
    }
`

export const StepTwo = ({formik}) => {
    const { t, i18n } = useTranslation();

    //const [imageCollection, setImageCollection] = useState([]);
    //const [bannerCollection, setBannerCollection] = useState([]);
    //const [imagesNft, setImagesNft] = useState([]);
    const [openModal, setOpenModal] = useState(false);


   // const [errorBannerCollection, setErrorBannerCollection] = useState(null);
    const { setFieldValue, values, errors, touched, setFieldError, setFieldTouched } = formik

    const removeFile = (input) => {
        setFieldValue(input, [])
    }

    const handleModal = () => setOpenModal(!openModal);

    const PopoverInfo = props => {
        const supportedTypes = "JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF.";

        return (
            <UncontrolledPopover placement="bottom" target={props.target} trigger="focus">
                <PopoverBody>
                    <div className="d-grid">
                        <small>
                            {t('supportedFormats')}: <b> {props.supported ?? supportedTypes} </b>
                        </small>
                        <small>
                            {t('maxSize')}: <b>100 MB</b>
                        </small>
                        <small>
                            {t('fileLimits')}: <b>{props.limit}</b>
                        </small>
                        <small>
                            {t('proportion')}: <b>{props.proportion}</b>
                        </small>
                       {props.tip && ( 
                       <small>
                           <strong>{t('msgExplicationImgProfile')}</strong>
                        </small>)}
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        );
    };

    return (
        <div id="pills-images" className="px-4">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <h4>
                       {t('collectionBanner')}
                        <Button id="infoBannerCollection" type="button" className="link bg-transparent border-0">
                            <i className="fa-sharp fa-solid fa-circle-info text-info fs-5" />
                        </Button>
                    </h4>
                    <PopoverInfo target="infoBannerCollection" limit="1" supported="JPG, PNG" proportion='1350x400' />

                    { values.banner_collection.length > 0 ?
                        <ImageContainer>
                            <img
                                src={URL.createObjectURL(values.banner_collection[0])}
                                height={100}
                                style={{width: "100%", height: "152px", objectFit: "cover"}}
                                className="rounded-2 position-relative"
                                alt=""
                            />
                            <div className="overlay position-absolute">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => removeFile('banner_collection')}
                                >
                                    <i className="bi bi-trash" />
                                </button>
                            </div>
                        </ImageContainer>
                        :
                        <FileInput
                            name="banner_collection"
                            //label=""
                            limit="1"
                            //setImage={setBannerCollection}
                            multiple={false}
                           // type="banner"
                            files={values.banner_collection}
                            collectionName={values.name}
                            handleModal={handleModal}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            setFieldTouched={setFieldTouched}
                        />
                    }

                    {errors.banner_collection && touched.banner_collection && <RequireMessage message={t(errors.banner_collection)} />}
                </div>

                <div className="col-12 col-lg-6">
                    <h4>
                    {t('collectionSymbol')}
                        <Button id="infoCollection" type="button" className="link bg-transparent border-0">
                            <i className="fa-sharp fa-solid fa-circle-info text-info fs-5" />
                        </Button>
                    </h4>
                    <PopoverInfo tip proportion='512x512' target="infoCollection" limit="1" />

                    {values.image_symbol.length > 0 ?
                        <ImageContainer>
                            <img
                                src={URL.createObjectURL(values.image_symbol[0])}
                                style={{width: "100%", height: "152px", objectFit: "cover"}}
                                className="rounded-2 position-relative"
                                alt=""
                            />

                            <div className="overlay position-absolute">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => removeFile('image_symbol')}
                                >
                                    <i className="bi bi-trash" />
                                </button>
                            </div>
                        </ImageContainer>
                        :
                        <FileInput
                            name="image_symbol"
                           // label=""
                            limit="1"
                            //setImage={setImageCollection}
                            multiple={false}
                            files={values.image_symbol}
                            // collectionName={values.name}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            setFieldTouched={setFieldTouched}
                        />
                    }

                    { errors.image_symbol && touched.image_symbol  && <RequireMessage message={t(errors.image_symbol)} />}
                </div>
            </div>

            <hr className="my-3" />

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <div>
                    <h4 className="mb-0">
                        {t('collectionNfts')}
                        <Button id="infoNFT" type="button" className="link bg-transparent border-0">
                            <i className="fa-sharp fa-solid fa-circle-info text-info fs-5" />
                        </Button>
                    </h4>
                    <PopoverInfo target="infoNFT" limit="5000" supported="JPG, PNG, MP4" />
                </div>

                {values.imagesNft.length > 0 &&
                    <div>
                        <button className="btn btn-primary" type="button" onClick={handleModal}>
                            {t('editFiles')}
                        </button>
                    </div>
                }
            </div>

            <FileInput
                name="imagesNft"
               // label=""
                limit="5000"
               // setImage={setImagesNft}
                renameImages={true}
                collectionName={values.name}
                multiple={true}
                //type="nft"
                files={values.imagesNft}
                priceNft={values.value}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                setFieldTouched={setFieldTouched}
                handleModal={handleModal}
                showList={true}
            />

            { errors.imagesNft && touched.imagesNft  && <RequireMessage message={t(errors.imagesNft)} />}

            <ModalApp isOpen={openModal} closeModal={handleModal} label={t('nameQuantity')} centered={false} size="xl">
                <RenameFiles
                    fileList={values.imagesNft}
                    setFieldValue={setFieldValue}
                    toggle={handleModal}
                />
            </ModalApp>
        </div>
    )

}
