import {useEffect} from "react";

import {Link, useParams} from "react-router-dom";

import {useCollection} from "../../web3/providers/CollectionContext";
import {useApp} from "../../web3/providers/AppContext";
import { TabTitle } from "../../utils/utils";

import {ButtonMint} from "../../components/ButtonMint";
import {calcPriceBrlNft, truncateAddress} from "../../utils/utils";
import { networkParams } from "web3/networks/networks";
import EtherscanLogo from "assets/img/etherscan-logo.svg";
import PolygonscanLogo from "assets/img/polygon-logo.svg";
import ModalDefault from "../../components/ModalDefault";
import {Share} from "../../components/Share";
import {useState} from "react";
import {CollectionDetailContainer} from "./styles";
import { limitarString } from "../../utils/utils";
import {db, firestoreDb} from "../../firebase";
import { useTranslation } from 'react-i18next';
import {doc, onSnapshot, collection, getDoc, where, query, getDocs, orderBy, startAt, limit} from "firebase/firestore";

export const CollectionDetail = () => {
    const { t, i18n } = useTranslation();
    const userLogged = useApp()

    const {layoutHeader, connectWallet} = userLogged

    const {collectionData, nft, crypto} = useCollection()
    
    const { name, description, reasonsToBelieve, nftEtherValue, creator, wallet, contract, reasons_to_believe_files, crowd_fund_first_sale } = collectionData

    const { collection, id } = useParams();

    
    
    const [openModal, setOpenModal] = useState(false)
    const [currentStepModal, setCurrentStepModal] = useState(null)
    const [isOpenToBuy, setIsOpenToBuy] = useState();
    TabTitle(`Collection | ${nft.name ? nft.name : ''}`);


    const handleOpenModal = async () => {

        if (!userLogged.wallet) {
            const connection = await connectWallet();
            if (connection.address) {
                setOpenModal(!openModal)
            }
        } else {
            setOpenModal(!openModal)
        }
        setOpenModal(!openModal)
    }

    useEffect(() => {
        layoutHeader(2)
    }, [layoutHeader])

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, 'images', nft.imageId), (snapshot) => {
          const data = snapshot.data();
          if (data) {
            if(data.locked) {
                setIsOpenToBuy(true);
            } else {
                if(data.status === 1){
                    
                    setIsOpenToBuy(true);
                    return
                }
                setIsOpenToBuy(false);
            }
            
          }
        });
      
        // Retornar uma função de limpeza para parar o listener quando o componente for desmontado
        return () => {
          unsubscribe();
        };
      }, []);
      

    const files = nft?.reasons_to_believe_files?.length > 0 && nft.reasons_to_believe_files.map((file) => {
        const ext = file.name.split('.')
        return { ...file, ext: ext[1]}
    })

    const amount = 1
    const BRL = calcPriceBrlNft(amount, crypto, nftEtherValue)
    const NameCreator = (creator && creator.nickname) ? `@${creator.nickname}` : truncateAddress(wallet);

    return (
        <CollectionDetailContainer>
            <div className="container my-5 py-5">
                <div className="row g-5">
                    <div className="col-12 col-lg-6">
                        <div className="info-collection">
                        <span className="ff-bold">
                            <Link className="text-decoration-none" to={`/collection/${collection}`}>{name}</Link>
                        </span>
                        </div>
                        <div className="nft-title mt-2">
                            <h1 className="m-0 ff-bold">{nft?.name}</h1>
                        </div>

                        <Link className="text-decoration-none text-black"
                              to={`/creator/${creator.nickname ? creator.nickname : wallet}`}>
                            <div className="current-owner mt-4 d-flex align-items-center ">
                                {creator.profile_avatar
                                    ? <img src={creator.profile_avatar}/>
                                    : <span className="avatar-default"/>
                                }

                                <div className="ms-3 d-flex flex-column">
                                    <span className="ff-bold">{t('createdBy')}</span>
                                    <span className="ff-bold">{NameCreator}</span>
                                </div>
                            </div>
                        </Link>

                        <div className="nft-infos w-100 my-5">
                            <h5 className="ff-bold fs-2">{t('description')}</h5>
                            <p className="ff-normal">{nft.description}</p>
                        </div>

                        {nft?.traits !== undefined && Object.keys(nft?.traits).length > 0 && (
                            <div className="nft-infos w-100 my-5">
                            <h5 className="ff-bold fs-2">Traits</h5>
                            <div style={{display:'flex',gap:'1rem'}}>
                                {Object.keys(nft.traits).map((trait) => (
                                
                                <div style={{display:'flex',alignItems:'center',flexDirection:'column',background:'rgba(18, 18, 18, 0.04)',width:'200px',borderRadius:'8px',paddingTop:'14px'}}>
                                    <p style={{margin:'0',fontFamily:'Figtree Font',fontSize:'14px'}}>{trait.toUpperCase()}</p>
                                    <p className="ff-bold"> {nft.traits[trait]} </p>
                                </div>
                                )
                                )}
                                </div>
                            </div>
                        )}

                        {nft?.external_url !== undefined && (
                            <div className="nft-infos w-100 my-5">
                                <h5 className="ff-bold fs-2">{t('externalUrlTitle')}</h5>
                                <Link to={nft.external_url} target="_blank" className="text-black" >
                                    <i className="bi bi-pin-map"></i> {t('externalUrl')}
                                </Link>
                            </div>
                        )}

                        <div className="nft-infos my-5">
                            <h5 className="ff-bold fs-2">{t('reasonsToBelieve')}</h5>
                            <p className="ff-normal">{nft.reasonsToBelieve}</p>
                            <div className="ff-normal justify-content-between align-items-center" style={{display:'flex',gap:'20px', flexWrap:"wrap"}}>
                            <div className="row gy-3">
                                    
                                            { files.length > 0 &&
                                            files.map((element) =>
                                            <div className="col-6 col-md-4 col-xl-3">
                                                <Link to={element.url} target="_blank" className="text-black d-flex flex-column justify-content-center align-items-center" >
                                                    
                                                    <i className={`bi ${element.ext === 'pdf' ? "bi-file-pdf"
                                                        : " bi-file-image"} display-4`} />
                                                        <p style={{margin:'0',fontFamily:'Figtree Font',fontSize:'12px'}}>{limitarString(element.name, 20)}</p>
                                                        
                                                </Link>
                                            </div>
                                        )}
                                    
                            </div>
                                
                            </div>
                            
                        </div>
                        <div className="nft-infos w-100 my-5">
                            <h5 className="ff-bold fs-2">{t('benefits')}</h5>
                            <p className="ff-normal">{collectionData.benefits}</p>
                         
                            
                        </div>
                    { /*
                        <div className="mt-5">
                            <span className="ff-bold">Tags</span>
                        </div>
                            */ }
                    </div>

                    <div className="col-12 col-lg-6">
                        <div className="nft-media w-100">
                            <div className="w-100 d-flex justify-content-center">
                                <img
                                    src={nft.url}
                                    width={'100%'}
                                    className="rounded-4"
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-column mt-3">
                            <div className="mx-3 d-flex flex-column">
                                <span className="ff-bold fs-5">{t('price')}</span>
                                <span className="ff-bold fs-3">{nftEtherValue} {crypto}</span>
                                <span className="ff-bold fs-3">{BRL}</span>
                            </div>

                            <div className="d-flex flex-column gap-3 mt-3">
                                <ButtonMint
                                    amount={amount}
                                    priceBRL={BRL}
                                    isOpenToBuy={isOpenToBuy}
                                    setIsOpenToBuy={setIsOpenToBuy}
                                >
                                     {t('buy')}
                                </ButtonMint>

                                <button
                                    className="btn btn-lg btn-outline-secondary rounded-pill"
                                    onClick={() => handleOpenModal()}
                                >
                                    share and earn
                                </button>
                            </div>
                        </div>

                        <div className="mx-3 mt-5">
                            <div className="mb-4">
                                <span className="fs-1 ff-bold" style={{lineHeight:'35px'}}>{t('donationToFund')} {nft.crowd_fund_first_sale}%</span>
                            </div>

                            <div className="d-flex align-items-center gap-3">
                                {networkParams[nft.network].crypto === 'MATIC'
                                    ? <img src={PolygonscanLogo} alt="" width="30"/>
                                    : <img src={EtherscanLogo} alt="" width="30"/>
                                }
                                <Link to={networkParams[nft.network].address+nft.contract} target="_blank"
                                      className="ff-bold text-black fs-5">{t('seeOnBlockchain')} </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <ModalDefault
                isOpen={openModal}
                closeModal={handleOpenModal}
                label={t('share')}
                centered={true}
                size="sm"
            >
                <Share collection={collection}/>
            </ModalDefault>
        </CollectionDetailContainer>
    )
}