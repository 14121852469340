import styled from 'styled-components'

export const ShareAndEarnButton = styled.button`
    font-size: 14px;
    border: 1px solid #000;
    border-radius: 16px;
    background-color: transparent;
    color: #000;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 10px;
    margin-bottom: 0.5rem !important;

    &:hover{
        background-color: #000;
        color: #fff;
    }

    transition: all 0.2s ease-in-out;
`

export const CarouselContainer = styled.div`

margin-top: 100px;

.swiper {
  width: 380px;
  height: 480px;
}

.swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      background-color: #FFF;
      border-radius: 20px;
}

.card-collection {
      border: 1px solid black;
      padding: 10px;
      border-radius: 20px;
      width: 100%;
      height: 480px;
}

.card-collection img {
    height: 415px;
    width: 100%; 
    objectFit: cover; 
    borderRadius: 20px;
}

@media only screen and (max-width: 599px) {
   
    .swiper {
      width: 330px;
      height: 480px;
      padding: 0 20px;
    }
    
    .card-collection img {
        height: 390px !important;
        width: 330px 
        objectFit: cover; 
        borderRadius: 20px;
    }
    
    .swiper .card-collection .label-card {
        flex-direction: column;
    }
    
    .swiper-slide:nth-child(1) {
        opacity: 1 !important;
        margin-top: 80px !important;
    }
    
    .swiper-slide:nth-child(2) {
        margin-top: 40px !important;
    }
    
    .swiper-slide:nth-child(3) {
        margin-top: 0px !important;
    }
    
    .swiper-slide:nth-child(3).swiper-slide-next {
        margin-top: 80px !important;  
        transform: translate3d(calc(-100px + -200%), 0px, -200px) rotateZ(0deg) scale(1) !important;
        z-index: 2 !important;
    }

    .swiper-slide:nth-child(2).swiper-slide-visible {
        margin-top: 0 !important;
        translate3d(-500px, 0px, 0px) rotateZ(0deg) scale(1);
        z-index: 1;
    }
    
    .swiper-slide:nth-child(1).swiper-slide-prev {
        margin-top: 40px !important;
        translate3d(-760px, 0px, 0px) rotateZ(0deg) scale(1);
        z-index: 3 !important;
    }
    
    
    .swiper-slide:nth-child(1).swiper-slide-visible {
        margin-top: 0 !important;
        transform: translate3d(calc(0px + 10%), 0px, -200px) rotateZ(0deg) scale(1) !important;
        z-index: 3 !important;
    }
    
    .swiper-slide:nth-child(3)  {
        margin-top: 40px !important;
        transform: translate3d(calc(0px + -200%), 0px, -200px) rotateZ(0deg) scale(1) !important;
        z-index: 2 !important;
    }
    
    .swiper-slide:nth-child(2).swiper-slide-next {
        margin-top: 80px !important;
        transform: translate3d(calc(0px + -110%), 0px, -200px) rotateZ(0deg) scale(1) !important;
        z-index: 1 !important;
    }
}

@media only screen and (min-width: 600px) {
   .swiper-slide:nth-child(1) {
    opacity: 1 !important;
    margin-top: 80px !important;
}

.swiper-slide:nth-child(2) {
    margin-top: 40px !important;
}

.swiper-slide:nth-child(3) {
    margin-top: 0px !important;
}

.swiper-slide:nth-child(3).swiper-slide-next {
    margin-top: 80px !important;  
    transform: translate3d(calc(0px + -225%), 0px, -200px) rotateZ(0deg) scale(1) !important;
    z-index: 2 !important;
}

.swiper-slide:nth-child(2).swiper-slide-visible {
    margin-top: 0 !important;
    translate3d(-760px, 0px, 0px) rotateZ(0deg) scale(1);
    z-index: 1;
}

.swiper-slide:nth-child(1).swiper-slide-prev {
    margin-top: 40px !important;
    translate3d(-760px, 0px, 0px) rotateZ(0deg) scale(1);
    z-index: 3 !important;
}


.swiper-slide:nth-child(1).swiper-slide-visible {
    margin-top: 0 !important;
    translate3d(-760px, 0px, 0px) rotateZ(0deg) scale(1);
    z-index: 3 !important;
}

.swiper-slide:nth-child(3)  {
    margin-top: 40px !important;
    transform: translate3d(calc(0px + -215%), 0px, -200px) rotateZ(0deg) scale(1) !important;
    z-index: 2 !important;
}

.swiper-slide:nth-child(2).swiper-slide-next {
    margin-top: 80px !important;
    transform: translate3d(calc(0px + -130%), 0px, -200px) rotateZ(0deg) scale(1) !important;
    z-index: 1 !important;
}
}



.swiper-3d .swiper-wrapper {
    transform-style: initial !important;
}

.swiper-3d .swiper-slide-shadow {
    background: none;
}

`