import React, {useState} from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    CircularProgress
} from '@mui/material';
import {Formik, Form} from 'formik';

import validationSchema from './validationSchema';
import formInitialValues from './formInitialValues';

import {StepOne} from "./Steps/StepOne";
import {StepTwo} from "./Steps/StepTwo";
import {StepThree} from "./Steps/StepThree";
import {PreviewCollection} from "../Preview";
import Web3 from "web3";

import Swal from "sweetalert2";

import {useApp} from "web3/providers/AppContext";
import {readContract, SetContractUri, switchNetwork} from "web3/contracts/functions";
import {compiler, contractUri, updateCollection, dataToFirestore } from "services/contract.service";

import {
    checkGasForDeploy,
    CSVToArray,
    sendImagesToFirestore,
    transformDate,
    sendFilesToFirestore,
    prepareData,
} from "./utils";
import {uploadFile} from "utils/utils";
import { TabTitle } from "../../../../utils/utils";


import keccak256 from "keccak256";
import {MerkleTree} from "merkletreejs";

import ModalDefault from "components/ModalDefault";
import {ProcessDeploy} from "../ProcessDeploy";
import { getCreator } from 'services/creator.service';
import { networkParams } from 'web3/networks/networks';
import { useTranslation } from 'react-i18next';

const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;

function _renderStepContent(step, formik) {
    switch (step) {
        case 0:
            return <StepOne formik={formik}/>;
        case 1:
            return <StepTwo formik={formik}/>;
        case 2:
            return <StepThree formik={formik}/>;
        default:
            return <div>Not Found</div>;
    }
}

export default function CheckoutPage() {
    const { t, i18n } = useTranslation();
    const steps = [t('generalInformations'), t('imgs'), t('contractInformations')];
    TabTitle("Crowd | " + t('createYourProject'));

    const stepsModal = [
        t('termOfUse'),
        t('wait'),
        t('almostThere'),
        t('success'),
        t('error'),
        t('waitingForConfirmation')
    ];


    const [activeStep, setActiveStep] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [currentStepModal, setCurrentStepModal] = useState(null)
    const [currentMessageModal, setCurrentMessageModal] = useState(null)
    const [counter, setCounter] = useState(null)

    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const {signer, wallet, connection, getBalance,updateDataCreator} = useApp();

    function handleHideModal() {
        setShowModal(false);
    }

    function handleConfirmModal(formik) {
        formik.setFieldValue('terms', true)
        setCurrentStepModal(1)
        setCurrentMessageModal(0)

        _submitForm(formik.values, formik)
    }

    function handleCancelModal(formik) {
        formik.setSubmitting(false);
        setCurrentStepModal(1)
        setCurrentMessageModal(0)
        setShowModal(false);
    }

    async function loadWhiteList(data) {
        
        if (!data.whitelist) {
            const date = new Date();
            const timestamp = Math.floor(date.getTime() / 1000.0);
            return {
                rootKey: "0x0000000000000000000000000000000000000000000000000000000000000000",
                addresses: [],
                begin_waitlist_date: timestamp,
                end_waitlist_date: timestamp,
                start_public_sale: timestamp,
                nftValue: parseFloat(data.value)
            };
        }

        let text = await data.whilelist_file.text();

        text = text.replace(/(\r\n|\n|\r)/gm, "");
        text = text.replace(" ", "");
        const addresses = CSVToArray(text);

        if (addresses.includes("")) {
            Swal.fire({
                title: "Oops!",
                icon: "warning",
                html: t('emptyWallets'),
                showConfirmButton: true,
                timer: 10000,
            });
            return false;
        }

        if (addresses.length < 40) {
            Swal.fire({
                title: "Oops!",
                icon: "warning",
                html: t('minWlWallets'),
                showConfirmButton: true,
                timer: 10000,
            });
            return false;
        }
        const leaves = addresses.map(x => keccak256(x));

        const tree = new MerkleTree(leaves, keccak256, {sortPairs: true});

        const buf2hex = x => "0x" + x.toString("hex");

        const rootKey = buf2hex(tree.getRoot());

        return {
            rootKey: rootKey,
            addresses: addresses,
            begin_waitlist_date: transformDate(data.begin_waitlist_date),
            end_waitlist_date: transformDate(data.end_waitlist_date),
            start_public_sale: transformDate(data.start_public_sale),
            nftValue: parseFloat(data.waitlist_price)
        };
    }

    async function _submitForm(data, action) {

        let deployConnection = connection;
        let walletDeploy = wallet;
        let providerDeploy;
        let signerDeploy = signer;

        walletDeploy = walletDeploy.toLowerCase().replace(/\s+/g, '');
        providerDeploy = await switchNetwork(deployConnection, data.network, setCurrentMessageModal, 4);

        if (!providerDeploy) {
            setCurrentStepModal(4)
            setCurrentMessageModal(4)
            return;
        }

        let userBalance = await getBalance(providerDeploy, wallet)

        setCurrentMessageModal(0)

        let balanceForDeploy;

        try {
            balanceForDeploy = await checkGasForDeploy(providerDeploy, userBalance);
        } catch (e) {
            setCurrentStepModal(4)
            setCurrentMessageModal(10)
        }

        if (!balanceForDeploy) {
            setCurrentStepModal(4)
            setCurrentMessageModal(5)
            return;
        }

        setCurrentStepModal(2)
        setCurrentMessageModal(1)

        try {
            

            setCurrentMessageModal(2)

            const folder = Math.floor(Math.random() * 100000);
            const folderBucket = "nfts/" + walletDeploy + "/" + folder + "/";

            const images = await sendImagesToFirestore(data.imagesNft, folderBucket);
            const symbol = await uploadFile(data.image_symbol[0], folderBucket);
            const banner = await uploadFile(data.banner_collection[0], folderBucket);

            const reasons_to_believe_files = await sendFilesToFirestore(data.file_reasons_to_believe, folderBucket);

            const whitelist = await loadWhiteList(data);
            const royalties = data.total_percentage_second * 100;

            const settings = prepareData(data, whitelist, royalties, symbol, images, banner, walletDeploy, reasons_to_believe_files);

            
            const idCollection = await dataToFirestore(settings,setCounter,data.url);
            const response = await compiler(JSON.stringify(settings));
            
            const contractAbi = response.abi;
            const contractByteCode = response.bytecode;

            setCurrentStepModal(5)
            setCurrentMessageModal(6)

            //new Web3.providers
            const web3Gas = new Web3(new Web3.providers.HttpProvider(networkParams[data.network].url));
            var web3Deploy = new Web3(connection);
            const gasPrice = await web3Gas.eth.getGasPrice();
            web3Deploy.eth.transactionConfirmationBlocks = 300;
            const myContract = new web3Deploy.eth.Contract(contractAbi, walletDeploy, {from: walletDeploy});
          
            myContract.deploy({
                data: contractByteCode,
                arguments: [
                    whitelist.rootKey
                ]
            }).send({
                    from: walletDeploy, 
                    gas: 6070964,
                    gasPrice:gasPrice
                }, function(error, transactionHash){
                      console.log(error, transactionHash);
                })
                .on('error', function(error){
                    console.log("Ocorreu um erro1: ", error);
                    setCounter(0);
                    setCurrentStepModal(4)
                    setCurrentMessageModal(9)
                })
                .on('transactionHash', function(transactionHash) {
                    setCurrentMessageModal(10)
                })
                .on('receipt', function(receipt) {
                    setCurrentMessageModal(11)
                })                
                .then(async function(newContractInstance) {    
                    
                    const new_contract = newContractInstance.options.address;

                    const ipfs = await contractUri({
                        name: data.name,
                        description: data.description,
                        symbolImage: symbol,
                        sellerFeePoints: royalties,
                        wallet: new_contract,
                        collection: idCollection.id,
                        collectionFB: collectionFirestore,
                    });

                    setCurrentStepModal(5)
                    setCurrentMessageModal(7)

                    const gasPriceContractUri = await providerDeploy.getGasPrice();

                    const contractFor = readContract(signerDeploy, new_contract);
                    await SetContractUri(contractFor, ipfs.ipfs, gasPriceContractUri);

                    setCurrentStepModal(2)
                    setCurrentMessageModal(8)

                    await updateCollection({
                        collection: idCollection.id,
                        contract: new_contract,
                        status: "submitted", // approved,
                        collectionFB: collectionFirestore,
                    });

                    setCurrentStepModal(3)
                    setCurrentMessageModal(3)

                    const updatedCreator = await getCreator(wallet)
                    updateDataCreator(updatedCreator) 
    
                    
                });

        } catch (e) {
            console.log("Ocorreu um erro2: ", e);
            setCounter(0);
            setCurrentStepModal(4)
            setCurrentMessageModal(9)
        }
    }

    const _tryAgainAction = (formik) => {
        setCurrentStepModal(1)
        setCurrentMessageModal(0)

        _handleSubmit(formik.values, formik)
    }

    async function _handleSubmit(values, actions) {
        if (isLastStep) {
            if (values.terms) {

                if (!showModal) {
                    setShowModal(true);
                }

                _submitForm(values, actions);

                return
            }

            setShowModal(true);
            setCurrentStepModal(0)
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    return (
        <React.Fragment>
           <h1 className="fs-1 fw-bolder mb-5">{t('createYourProject')}</h1>
            {activeStep === steps.length ? (
                <h1>Mensagem de sucesso</h1>
            ) : (
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {(formik) => (
                        <Form id="formCreate">
                            <div className="row g-4">
                                <div className="col-12 col-xl-6">
                                    <div className="card">
                                        <Stepper activeStep={activeStep} className="mb-4 p-4">
                                            {steps.map((label) => (
                                                <Step
                                                    key={label}
                                                    sx={{
                                                        ".MuiSvgIcon-root": {
                                                            width: "2rem",
                                                        },
                                                        ".MuiSvgIcon-root.Mui-active": {
                                                            padding: "0",
                                                            margin: "0",
                                                            fill: "#ee2b7a",
                                                        },
                                                        ".MuiSvgIcon-root.Mui-completed": {
                                                            fill: "#000"
                                                        },
                                                    }}
                                                >
                                                    <StepLabel>
                                                        {label}
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>

                                        {_renderStepContent(activeStep, formik)}

                                        <div className="d-flex justify-content-between mt-4 p-4">
                                            <div>
                                                {activeStep !== 0 && (
                                                    <button
                                                        onClick={_handleBack}
                                                        className="btn btn-secondary btn-lg"
                                                        type="button"
                                                    >
                                                        {t('back')}
                                                    </button>
                                                )}
                                            </div>
                                            <div>
                                                <button
                                                    disabled={formik.isSubmitting}
                                                    type="submit"
                                                    className="btn btn-secondary btn-lg"
                                                    onClick={() => window.scrollTo(0, 0)}
                                                >
                                                    { formik.isSubmitting ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    )
                                                        : isLastStep ? t('publish') : t('next')
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6">
                                    <PreviewCollection data={formik.values}/>
                                </div>
                            </div>

                            <ModalDefault isOpen={showModal} closeModal={handleHideModal}
                                          label={stepsModal[currentStepModal]} backdrop="static">
                                <ProcessDeploy
                                    currentStepModal={currentStepModal}
                                    currentMessageModal={currentMessageModal}
                                    formik={formik}
                                    tryAgainAction={_tryAgainAction}
                                    handleCancelModal={handleCancelModal}
                                    handleConfirmModal={handleConfirmModal}
                                    counter={counter}
                                />
                            </ModalDefault>

                        </Form>
                    )}
                </Formik>
            )}
        </React.Fragment>
    );
}