import Styled from 'styled-components'

export const PreviewContainer = Styled.div`

    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;

    padding-bottom: 5rem;

    .banner-collection {
        position: relative;
        display: grid;
        width: 100%;
        height: 300px;
        background: #333;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
    }
    
    .banner-collection img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
    }
    
    .image-collection {
        bottom: -50px;
        position: absolute;
    }
    
    .image-collection-default {
        
        width: 150px;
        height: 150px;
        border-radius: 20px;
        border: 4px solid #FFFFFF;
    }
    
    .image-collection img {
        width: 150px;
        height: 150px;
        border-radius: 20px;
        border: 4px solid #FFFFFF;
    }
    
    .icons {
        gap: 10px;
    }
        
    .icons a {
        height: 30px;
        width: 30px;
        text-decoration: none;
    }    
        
    .date-format::first-letter {
        text-transform: uppercase;
    }
    
    .categories span:after {
        content: " · ";
        margin: 0 3px;
    }
    
    .categories span:last-child:after {
        content: "";
    }
     
     .categories span:first-child{
        margin-left: 5px;
    }
    
`;