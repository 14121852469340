import { createGlobalStyle } from 'styled-components';

import FigtreeFont from "../assets/fonts/Figtree-VariableFont_wght.ttf";
import FigtreeFontItalic from "../assets/fonts/Figtree-Italic-VariableFont_wght.ttf";
import FigtreeFontMedium from "../assets/fonts/static/Figtree-Medium.ttf";
import FigtreeFontBold from "../assets/fonts/static/Figtree-Bold.ttf";
import FigtreeFontLight from "../assets/fonts/static/Figtree-Light.ttf";

import LogoBlack from "../assets/img/logo-crowd-black.png";
import LogoWhite from "../assets/img/logo-crowd.png";

const GlobalStyle = createGlobalStyle`

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    width: 100%;
    height: 100%
}
  
body {
    margin: 0;
    padding: 0;
    background: #ffffff;
    color: ${props => props.theme.black};
    font-family: Open-Sans, Helvetica, Sans-Serif;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'Figtree Font';
    src: url(${FigtreeFont});
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Figtree Font Italic';
    src: url(${FigtreeFontItalic});
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Figtree Font Light';
    src: url(${FigtreeFontLight});
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Figtree Font Medium';
    src: url(${FigtreeFontMedium});
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Figtree Font Bold';
    src: url(${FigtreeFontBold});
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

.ff-normal {
    font-family: 'Figtree Font';
}

.ff-italic {
    font-family: 'Figtree Font Italic';
}

.ff-medium {
    font-family: 'Figtree Font Medium';
}

.ff-bold {
    font-family: 'Figtree Font Bold';
}

.ff-light {
    font-family: 'Figtree Font Light';
}
  
.wrapper {
    background-color: #ffffff;
    color: ${props => props.theme.black};
    font-family: Open-Sans, Helvetica, Sans-Serif;
    overflow-x: hidden;
    padding-top: 92px;
    padding-bottom: 0;
    z-index: 0;
    min-height: 80vh;
    overflow: hidden;
}
  
.container-page {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}


.form-label {
    font-family: 'Figtree Font Medium';
    font-size: 14px;
}

.text-custom {
    font-family: 'Figtree Font';
    font-size: 1.5rem;
}

.label-custom {
    font-family: 'Figtree Font Bold';
    font-size: 2rem;
    position: relative;
    line-height: 1.23;
    width: auto;
    margin-bottom: 3rem;
}

.heart-icon{
    position: absolute;
    left: 50%;
}

a {
    color: ${props => props.theme['pink-500']};
    text-decoration: underline;
}

@media(max-width: 767px){
    .heart-icon{
        left: 50%;
        transform: translateX(-50px);
        bottom: -120px;
    }
}


//#################### Font Awesome ####################
    .fa-2x-custom {
        font-size: 1.5em;
    }
//#################### Font Awesome ####################  


.form-control, .form-select {
    font-family: 'Figtree Font';
    padding: 0.675rem 0.75rem !important;
    border-radius: 8px;
}

//#################### Colors ####################

    .bg-pink {
        background-color: ${({theme}) => theme['pink-500']};
        color: #FFF;
    }
    
    .text-blue-200 {
        color-color: ${({theme}) => theme['blue-200']};
    }
  
  .bg-grey-300 {
    background-color: ${({theme}) => theme.grey300};
  } 
   
  .bg-grey-800 {
    background-color: ${({theme}) => theme.grey800};
  }
  
  .bg-grey-900 {
    background-color: ${({theme}) => theme.grey900};
  }
  
  .text-grey-800 {
    color: ${({theme}) => theme.grey800};
  }
  
  .text-blue-200 {
    color: ${({theme}) => theme.blue200};
  }
  
//#################### Colors ####################

  .form-control.rounded-start-pill {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px; 
  }
  
  .rounded-end-pill {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; 
  }
  
//#################### Header ####################
  
  .navbar-dark {
    padding: 10px 0 20px;
    z-index: 2;
    font-weight: 500;
  }
  
  .navbar-default {
    margin-top: 0 !important;
    background-color: ${props => props.theme.black};
    transition:  backdrop-filter 0s, margin-top 0.6s, background-color 0.4s, transform 1s;
  }
  
   .navbar-default .navbar-brand {
    background-image: URL(${LogoWhite});
    background-repeat: no-repeat;
    height: 36px;
    width: 200px;
  }
  
  .navbar-bg {
    background-color: transparent;
    color: ${props => props.theme.black};
    transition:  backdrop-filter 0s, margin-top 0.6s, background-color 0.4s, transform 1s;
  }  
  
  .navbar-bg .nav-link {
    color: ${props => props.theme.black};
  }
  
  .navbar-bg .navbar-brand {
    background-image: URL(${LogoBlack});
    background-repeat: no-repeat;
    height: 36px;
    width: 200px;
  }
  
  .navbar-bg-50 {
    //margin-top: 0 !important;
    background-color: ${props => props.theme.black};
    z-index: 99;
    backdrop-filter: blur(20px);
    
    transition:  backdrop-filter 0s, margin-top 0.6s, background-color 0.4s, transform 1s;
  }
  
  .navbar-bg-50 .navbar-brand {
    background-image: URL(${LogoWhite});
    background-repeat: no-repeat;
    height: 36px;
    width: 200px;
  }
  
  .navbar-bg-50 .nav-link,
  .navbar-default .nav-link {
    color: ${props => props.theme.white};
  }
    
   .navbar-collapse.collapse.show ,.navbar-collapse.collapsing {
    transition: .4s cubic-bezier(.25, .46, .45, .94);
    padding-top: 20px;
    margin-top: 20px;
    border-radius: 20px;
    backdrop-filter: blur(100px);
  }
 
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 25px;
        padding-left: 25px;
    }
  }
  
   @media (max-width: 992px){
    .navbar-bg {
        margin-top: 0;
    }
   }
  
  
  @media (max-width: 991px) {
    .navbar-nav {
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }
  
    .navbar-expand-lg .navbar-nav .nav-link {
        font-size: 20px;
        padding-right: 25px;
        padding-left: 25px;
    }
    
    .search-form {
        width: 100%;
        margin: 40px auto 20px;
    }
    
    .header-action {
        border-radius: 25px;
    }
    
  }
  


//#################### Header ####################


  
//#################### Buttons ####################

    .btn {
        text-transform: lowercase;
    }

    .btn-primary,
    .btn-primary:hover, 
    .btn-primary:active, 
    .btn-primary:focus {
        background-color: ${props => props.theme['pink-500']} !important;
        border-color: ${props => props.theme['pink-500']} !important;
        color: #FFF;
    }
    
    .btn-secondary,
    .btn-secondary:active
    .btn-secondary:focus {
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.black};
        border-color: ${props => props.theme.black};
    }
    
    .btn-secondary:disabled {
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.black};
        border-color: ${props => props.theme.black};
        opacity: 0.50;
    }
    
    .btn-secondary:hover {
       color: ${props => props.theme.white};
       background-color: ${props => props.theme.black};
               border-color: ${props => props.theme.black};

       opacity: 0.90;
    }
    
    .btn-outline-secondary,
    .btn-outline-secondary:active
    .btn-outline-secondary:focus {
        color: ${props => props.theme.black};
        background-color: transparent;
        border-color: ${props => props.theme.black};
    }
    
    .btn-outline-secondary:hover {
       color: ${props => props.theme.white};
       background-color: ${props => props.theme.black};
       border-color: ${props => props.theme.black};

    }
    
    .btn-success{
        color: #FFFFFF;
        background-color: ${props => props.theme['green-500']};
        border-color: ${props => props.theme['green-500']};
    }

    .btn-outline-default:hover, btn-outline-default:blur {
        color: #333333;
    }
    
    .btn-primary-custom  {
      background-color: ${({theme}) => theme.primary};
      color: #ffffff;
      border-radius: 50px;
      outline: 2px solid;
      outline-color: rgba(255, 102, 175,1);
      outline-offset: 5px;
      transition: all 600ms cubic-bezier(0.2, 0, 0, 0.8);
    }
    
     .btn-primary-custom:hover,
     .btn-primary-custom:focus {
      background-color: ${({theme}) => theme.primary};
      color: #333333;
      outline-color: rgba(71, 126, 232, 0);
      outline-offset: 15px;
    }
    
    .btn-secondary-custom {
      background-color: ${({theme}) => theme.secondary};
      color: #333;
      border-radius: 50px;
      outline: 2px solid;
      outline-color: ${({theme}) => theme.secondary};
      outline-offset: 5px;
     transition: all 600ms cubic-bezier(0.2, 0, 0, 0.8);
    }
    
     .btn-secondary-custom:hover,
     .btn-secondary-custom:focus {
      background-color: ${({theme}) => theme.secondary};
      color: #333333;
      outline-color: rgba(71, 126, 232, 0);
      outline-offset: 15px;
    }
   
    .btn-dark {
      border-radius: 50px;
    }
    
    .btn-card {
        width: 100%;
    
        border: 1px solid #000;
        border-radius: 50rem;
        font-size: 14px;
        font-weight: 600;
    }
    
    .btn-card:hover {
        border: 1px solid #000;
    }
    
    .input-group .btn-primary {
      border-radius: 50px;
      z-index: 1;
    }
    
    .btn-connect {
        width: 220px;
        height: 50px;
        padding: 5px 15px;
        font-size: 18px !important;
        display: flex;
        align-items: center;
        justify-content: center
    }
    
    .btn-connect span {
        text-align: center;
        line-height: 20px;
    }
    
    .btn-connect svg {
        height: 1.3rem;
    }
    
    .btn.bg-white {
        font-weight: 600;
    }
    
    .btn.bg-white:hover {
        color: ${props => props.theme.black};
        opacity: 0.95;
    }
    
    .btn-account {
        display: block;
        height: 30px;
        width: 30px;
        border-radius: 50px;
        background-color: #ffffff;
        border: 3px solid #000000;
        outline: 2px solid #ffffff;
        margin-bottom: 4px;
    }

    .navbar-toggler-icon{
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28238, 43, 122, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") ;
    }

// #################### Buttons ####################

// #################### NAV-TABS-HOME ####################
.contents .nav-tabs, 
.marketplace .nav-tabs {
  position: relative;
  border: none;
}



// #################### NAV-TABS-HOME ####################


//#################### Modal ####################

.modal-content {
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
}

.modal-header {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.modal-body {
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

//#################### Modal ####################

    .h1-title {
      color: #FFFFFF;
      font-size: 2.725rem;
      z-index: 99;
      position: relative;
    }
    
    .h1-title-content {
      position: absolute;
      font-weight: bold;
      margin-top: -110px;
      z-index: 0;
      left: -50px;
      color: #262626;
      font-size: 8rem;
    }
    
    .h1-title-default {
      position: absolute;
      font-weight: bold;
      margin-top: -120px;
      z-index: 0;
      color: #262626;
      font-size: 8rem;
    }

    @media (max-width: 576px) {
        .h1-title-content {
            font-size: 4.5rem;
            margin-top: -90px;
        }
        
        .h1-title-default {
            font-size: 4.5rem;
            margin-top: -80px;
        }
    }
    
    .modal-title {
        color: #333;
    }
    
    hr {
        margin: 0.5rem 0;
    }
    

    .input-search-icon {
        position: absolute;
        right: 0;
        top: 3px;
    }
    
    .border-custom {
        border-radius: 20px;
    }
    
    .mt-custom {
        margin-top: 0;
    }
    
    @media (max-width: 768px) {
    
        .hero-home .hero-image {
            height: 70vh;
        }
    
        .mt-custom {
            margin-top: 0px;
        }
    
        .border-end {
            border-right: 0;
        }
    }
    
    .btn-close {
        opacity: 1;
        font-size: 1rem !important;
    }
    
    .btn-close:focus {
         box-shadow: none !important;
    }
        
    *::-webkit-scrollbar {
      width: 12px;               /* width of the entire scrollbar */
    }
    
    *::-webkit-scrollbar-track {
      background: #d6d6d6;        /* color of the tracking area */
    }
    
    *::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.black};    /* color of the scroll thumb */
      border-radius: 0;       /* roundness of the scroll thumb */
      border:0;  /* creates padding around scroll thumb */
    }
    
    .bg-primary-opacity {
        background-color: ${props => props.theme.primaryOpacity};
    }
    
    .fs-7 {
        font-size: 0.875rem;
    }
    
    .social-link {
      height: 35px;
      width: 35px;
      border-radius: 50%;
    
      border: 2px solid #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .social-link svg {
      font-size: 16px;
      color: #fff;
    } 
    
    .social-link-black {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    
      border: 2px solid #000;;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .social-link-black svg {
      font-size: 22px;
      color: #000;
    }   
    
    .avatar-default {
        display: block;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-color: #4158D0;
        background-image: linear-gradient(43deg,#4158D0 0%,#C850C0 46%,#FFCC70 100%);
    }   
    
    .bg-default {
        background-color: #4158D0;
        background-image: linear-gradient(43deg,#4158D0 0%,#C850C0 46%,#FFCC70 100%);
    }
    
    .icons {
        display: flex;
        gap: 20px;
    } 
    
    .icons a {
       display: flex;
       justify-content: center;
       align-items: center;
       
       border: 1px solid ${props => props.theme.black};  
       border-radius: 50%;
       height: 40px;
       width: 40px;
       text-decoration: none;
    }
    
     .icons a i {
       color: ${props => props.theme.black};  
    }
    
    .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button:not(.collapsed) {
        border-top-right-radius: 8px !important;
        border-top-left-radius: 8px !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    
    .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
        border-radius: 8px !important;
    }
    
    .accordion-flush .accordion-item {
     border-radius: 8px !important;
    }
    
    .accordion {
        border-top-right-radius: 8px !important;
        border-top-left-radius: 8px !important;
        border-radius: 8px !important;
    }
    
    .accordion-item {
        border-bottom-right-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
    
    .accordion-button {
        font-family: 'Figtree Font Medium';
        border-top-right-radius: 8px !important;
        border-top-left-radius: 8px !important;
    }
    
    
    .accordion-button:not(.collapsed) {
        background-color: ${props => props.theme.black};  
        color: ${props => props.theme.white};  
    }
    
     svg.MuiSvgIcon-root {
            height: 2rem;
            width: 2rem;
     }
    
     @media (max-width: 600px) {
         .MuiStepper-root  {
            align-items: unset !important;
         }
         
         svg.MuiSvgIcon-root {
            height: 2rem;
            width: 2rem;
         }
     
        .css-ascpo7-MuiStepLabel-root {
            align-items: unset;
            flex-direction: column;
            text-align: center;
            gap: 5px;
        }
    }
    
    .form-switch .form-check-input {
        width: 2.5em;
        height: 1.5rem;
    }
    
    #WEB3_CONNECT_MODAL_ID {
     z-index: 9999;
     position: absolute;
    }

    .swal2-actions {
        width: 100%;
    }

    .swal2-confirm.swal2-styled {
        max-width: 100% !important;
        width: 100% !important;
        margin-top: 15px !important;
        background-color: #EE2A7A !important;
        border: none !important;
        outline: none !important;
        color: #FFF !important;
        padding: 15px !important;
        border-radius: 20px !important;
        box-shadow: none !important;
        font-size: 16px !important;
    }
`;

export default GlobalStyle;
