import {useEffect} from "react";
import FormCreate from "./components/FormCreate";
import {useApp} from "../../web3/providers/AppContext";
import ModalDefault from "../../components/ModalDefault";
import React, {useState} from "react";
import {getCreator} from "../../services/creator.service";

import {FormProfileComponent} from "components/FormProfile"
import {useNavigate} from "react-router-dom";
import useAuth from "hooks/useAuth";
import { useTranslation } from 'react-i18next';

export const CreateNftPage = () => {
    const { t, i18n } = useTranslation();

    const [showProfileModal,setShowProfileModal] = useState(false);
    const {googleAccount} = useAuth()

    const { layoutHeader, wallet, connectWallet, creator,disconnectGoogle} = useApp()
    const navigate = useNavigate()

    const handleConnect = async () => {
        disconnectGoogle()
        const connect = await connectWallet()

        if (connect.address) {
            const response =  await fetchCreator(connect.address)

            if(!response) {
                setShowProfileModal(false)
            }
        }
    }

    const fetchCreator = async (walletAddress = wallet) => {

        if (!walletAddress || googleAccount ) {
            setShowProfileModal(true)
            const response = await connectWallet()
            walletAddress = response.address
        }

        const creator = await getCreator(walletAddress)

        if(!creator.completedProfile){
            setShowProfileModal(true)
            return true
        }

        setShowProfileModal(false)
        return false
    }

    const fetchUser = async (walletAddress = wallet) => {

        if (!walletAddress) {
            setShowProfileModal(true)
        }

        if (googleAccount) {
            setShowProfileModal(true)
            return true
        }

        const creator = await getCreator(walletAddress)

        if(creator.isGoogleWallet){
            setShowProfileModal(true)
            return true
        }

        if(!creator.completedProfile){
            setShowProfileModal(true)
            return true
        }

        setShowProfileModal(false)
        return false
    }

    useEffect(() => {
        fetchUser()
    }, [wallet])

    useEffect(() => {
        layoutHeader(2)
    }, [layoutHeader])

    function handleHideProfileModal() {
        setShowProfileModal(false);
    }


    return (
        <div className="container my-5">
            <FormCreate />

            <ModalDefault
                isOpen={showProfileModal}
                closeModal={handleHideProfileModal}
                label={!wallet ? t('connectLabel') : !creator.completedProfile ? t('infoCreator') : t('privateKey.waiting')}
                size={!wallet || googleAccount ? 'md' : !creator.completedProfile ? 'lg' : 'md' }
                centered
                backdrop="static"
                closeButton={false}
                keyboard={false}
            >
                { googleAccount || !wallet
                    ? <div className="px-3 d-flex flex-column gap-3">
                        <span className="ff-normal">{googleAccount ? t('errorCreateProjectGoogle') : t('mint.connect')}</span>

                        <div className="d-flex gap-2">
                            <button
                                className="btn btn-secondary w-50"
                                onClick={() => navigate('/')}
                            >
                               {t('cancel')}
                            </button>

                            <button
                                className="btn btn-primary w-50"
                                onClick={() => handleConnect()}
                            >
                                {t('mint.connectOrCreate')}
                            </button>
                        </div>

                     </div>

                    : !creator.completedProfile && <FormProfileComponent subTitle={t('completeRegisterToCreate')} closeModal={handleHideProfileModal} />
                }
            </ModalDefault>
        </div>
    )
}
