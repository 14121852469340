import styled from "styled-components";

export const ProfileContent = styled.div`

.image-profile {
    display: flex;
    justify-content: center;  
}

.image-profile img {
    height: 200px;
    width: 200px;
    object-fit: cover;
    cursor: pointer;
}

.banner-profile {
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
}

input[type='file'] {
  display: none;
}

.form-label {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.05rem;
}


.form-switch .form-check-input {
    width: 2.4em;
    height: 1.4em;
}

.form-check-input {
    background-color: #585858
}

.form-check-input:checked {
    background-color: ${({theme}) => theme.primary};
}
.accordion-button {
    background-color: #FFF;
    color: ${({theme}) => theme.primary};
    box-shadow: none;
}

.accordion-button:focus {
    border-color: #ffffff;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 15px;
}

.accordion-button:not(.collapsed) {
    background-color: #424242 !important;
    color: ${({theme}) => theme.primary};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.accordion-flush .accordion-item:last-child {
    border-radius: 20px;
}

.accordion-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.css-13cymwt-control {
    background-color: hsl(0deg 0% 18%);
    border-color: hsl(0deg 0% 38%);
    border-radius: 6px;
}
`

export const PlaceholderImage = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    height: 200px;
    width: 200px;
    color: #707070;
    border: 3px dashed #bdbdbd;
    border-radius: 999px;
    font-size: 14px;
    cursor: pointer;
    padding: auto;
    transition: 300ms linear; 
    
    i {
        font-size: 4rem;
    }
    
    &:hover {
        border-color: #333;
        color: #333;
    }
    
`;

export const BannerContent = styled.div`
    position: relative;
    margin-bottom: 2rem;
    
    img { 
        position: relative;
    }
    
    button {
        position: absolute;
        top: 20px;
        right: 20px;
   }
`

export const PlaceholderBanner = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 250px;
    color: #707070;
    border: 3px dashed #bdbdbd;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    padding: auto;
    transition: 300ms linear;
    
    i {
        font-size: 4rem;
    }
    
    &:hover {
        border-color: #333;
        color: #333;
    }
`;
