import { truncateAddress } from "../../utils/utils";
import { useApp } from "web3/providers/AppContext";
import Metamask from "../../assets/img/svg/metamask.svg";
import {ButtonConnectContainer} from "./styles";

const ButtonConnect = ({closeModal}) => {

    const { wallet, connectWallet, disconnectWallet, buttonText } = useApp()

    const hanldeConnect = () => {
        connectWallet()
        closeModal(false)
    }

    return (
        <>
            { !wallet ? (
                <button className="btn btn-lg btn-primary rounded-pill btn-connect ff-normal" type="button" onClick={hanldeConnect}>
                   <ButtonConnectContainer>
                    <span>{buttonText}</span> <img src={Metamask} alt="Metamask icon" />
                    </ButtonConnectContainer>
                </button>
            ) : (
                <button className="btn btn-lg btn-primary rounded-pill btn-connect ff-normal" type="button" onClick={disconnectWallet} >
                    <span>{ truncateAddress(wallet) }</span>
                </button>
            )}
        </>
    );
}
export default ButtonConnect
