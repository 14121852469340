import React, {createContext, useContext, useEffect, useState, useCallback} from "react";

import { Loading } from "components/Loading";
import {ethers} from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import {createCreator, getCreator,createCreatorGoogle} from "../../services/creator.service";
import {getPrice} from "../../utils/utils";
import {useLocation} from "react-router-dom";
import {signInWithPopup} from "firebase/auth"
import {provider as googleProvider,auth} from '../../firebase'
import {swalCustom} from "../../components/SweetAlert";
import Web3 from "web3";
import useAuth from "hooks/useAuth";
import { useTranslation } from 'react-i18next';

export const AppContext = createContext(0);

const { formatEther } = ethers.utils;


export const AppProvider = (props) => {

    const [loading, setLoading] = useState(true)
    const [provider, setProvider] = useState();
    const [wallet, setWallet] = useState("");
    const [walletAddress,setWalletAddress] = useState("")
    const [connection, setConnection] = useState();
    const [balance, setBalance] = useState(0);
    const [signer1, setSigner] = useState({})
    const [creator, setCreator] = useState({})
    const [network, setNetwork] = useState()
    const { t, i18n } = useTranslation();
    const [buttonText, setButtonText] = useState(t('connect'));//I18n
    const [register,setRegister] = useState(false)
    const {setGoogleAccount} = useAuth()
   

    const { pathname } = useLocation();

    const [opacity, setOpacity] = useState(1);

    function layoutHeader(status) {
        setOpacity(status)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const priceCoin = localStorage.getItem('price')

    useEffect(() => {
        const request = () => {
            updatePrice()
            getUpdatedPrice()
        }

        request()
    },[])

    const updatePrice = () => {
        setInterval(() => {
            getUpdatedPrice()
        }, 60000 * 2)
    }

    const getUpdatedPrice = () => {
        const responseETH = getPrice('ETH')
        const responseMatic = getPrice('Matic')

        Promise.all([responseETH, responseMatic])
            .then(function(result) {
                localStorage.setItem('price', JSON.stringify({
                    ETH: result[0],
                    MATIC: result[1]
                }))
            })
    }


    const providerOptions = {
        injected: {
            display: {
                description: t('connectYourWallet')
            }
        },
        walletconnect: {
            package: WalletConnectProvider, // required
            options: {
                appName: 'multiple-contexts-web3modal', // Required
                rpc: {
                    137: "https://rpc-mainnet.maticvigil.com/",
                    80001: "https://rpc-mumbai.maticvigil.com/",
                },
                infuraId: "3ac68be0e4f44833bce00d483f3bd104",
                qrcodeModalOptions: {
                    mobileLinks: [
                        "metamask",
                        "argent",
                        "trust",
                        "imtoken",
                        "pillar",
                    ]
                },
            },
            display: {                
                description: "Digitalize com o WalletConnect para se conectar"
            },
        },
    };

    const getBalance = useCallback(async (provider, address) => {
      const response = await provider.getBalance(address)
        return formatEther(response)
    }, [])

    const updateCreator = useCallback(async (data) => {
        setCreator(data)
    }, [])

    const connectWallet = async () => {
        const web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions
        });
        
        try {
            const connection = await web3Modal.connect();
            setButtonText(t('connecting'));
            const provider = new ethers.providers.Web3Provider(connection, "any");

            const signer = provider.getSigner(0);
            setSigner(signer)

            const address = await signer.getAddress();
            const balance = await provider.getBalance(address);
            const network = await provider.getNetwork();
            
            setWalletAddress(address)
            setNetwork(network.chainId);

            const creator = await getCreator(address)
            if (Object.prototype.hasOwnProperty.call(creator, "id")) {
                setCreator(creator)
            } else {
                await createCreator(address)
                const newCreator = await getCreator(address)
                setCreator(newCreator);
            }

            setWallet(address.toLocaleLowerCase());
            setConnection(connection);
            setProvider(provider);
            setBalance(formatEther(balance));
            return {
                address,
                signer,
                creator,
                provider,
                connection,
                balance
            };
        } catch (error) {
            console.error('error', error);
        }
    };

    const disconnectWallet = () => {
        localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
        localStorage.removeItem("googleWallet")
        setButtonText(t('connect'));
        setWallet("");
        setCreator(null)
        setProvider(undefined);
        setBalance(0);
    };

    const disconnectGoogle = async () => {
        disconnectWallet()
        setGoogleAccount(null)
        setRegister(false)
        await auth.signOut()
    }


    const handleGoogleLogin = async () => {

        
        try{


        const result = await signInWithPopup(auth,googleProvider)

        if(result.user){
            const {displayName,photoURL,uid,email} = result.user
            
            if(!displayName || !photoURL){
                throw new Error('Missing information from Google Account')
            }

            const creator = await getCreator(email)
            setButtonText(t('connecting'));

            if (Object.prototype.hasOwnProperty.call(creator, "id")) {
                setCreator(creator)
                setGoogleAccount({
                    id:uid,
                    email: email,
                    name:displayName,
                    avatar:photoURL
                })
            } else {
                const cretorCreate = await createCreatorGoogle(email)
                setButtonText(t('connecting'));
                if(!cretorCreate.success){
                    swalCustom.fire({
                        title: 'Oops!',
                        html: t('emailAlreadyGoogle'),
                        icon: 'error',
                        confirmButtonText: 'Fechar',
                    })
                    setButtonText(t('connect'));
                    throw new Error('O e-mail informado pertence a outra conta.')
                }
                const newCreator = await getCreator(email)
                setButtonText(t('connecting'));
                setCreator(newCreator);
                setGoogleAccount({
                    id:uid,
                    email: email,
                    name:displayName,
                    avatar:photoURL
                })
                setWallet(newCreator.address);
                setRegister(true)
                localStorage.setItem('googleWallet',newCreator.address)

                return{
                    creator,
                    address: newCreator.address
                }
                
            }

            const address = await creator.address.toLocaleLowerCase();
            setWallet(address);
            localStorage.setItem('googleWallet',address)

            return{
                creator,
                address
            }
        }

    }catch(error){
        console.log(error,error)
    }

    }

    useEffect(()=>{

        auth.onAuthStateChanged(user => {
            if(user && localStorage.getItem('googleWallet')){
            
                const fetchCreator = async () => {

                    const {displayName,photoURL,email,uid} = user

                    setGoogleAccount({
                        id:uid,
                        email: email,
                        name:displayName,
                        avatar:photoURL
                    })


                    if(!displayName || !photoURL){
                        throw new Error('Missing information from Google Account')
                    }

                    const creator = await getCreator(email)

                    if (Object.prototype.hasOwnProperty.call(creator, "id")) {
                        setCreator(creator)
                    } else {
                        await createCreatorGoogle(email)
                        const newCreator = await getCreator(email)
                        setCreator(newCreator);
                    }
            
                    const address = await creator.address.toLocaleLowerCase();
            
                    setWallet(address);
                    return{
                        creator,
                        address
                    }
                }

                fetchCreator()
                
            }
        })

    },[])

    useEffect(() => {
        if (connection && provider) {
            connection.on("accountsChanged", async ([currentAccount]) => {
                
                const balance = await provider.getBalance(currentAccount);
                setWallet(currentAccount.toLocaleLowerCase());
                setBalance(formatEther(balance));
                const creator = await getCreator(currentAccount);
                
                if (Object.prototype.hasOwnProperty.call(creator, "id")) {
                    setCreator(creator)
                } else {
                    await createCreator(currentAccount)
                    const newCreator = await getCreator(currentAccount)
                    setCreator(newCreator);
                }
            });
            connection.on("chainChanged", async (chain) => {
                
                const network = Web3.utils.hexToNumber(chain);
                setNetwork(network);
                const balance = await provider.getBalance(wallet);
                setWallet(wallet.toLocaleLowerCase());
                setBalance(formatEther(balance));
            });

            connection.on("connect", (info) => {
                console.log(info);
            });

            connection.on(
                "disconnect",
                (error) => {
                    console.log(error);
                }
            );
            cancelLoading()
        }

        if (!connection && !provider) {
            cancelLoading()
        }

    }, [connection, provider]);

    useEffect(() => {
        const local = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER");
        
        if (local !== null) {
            setLoading(true)
            connectWallet().then(() => cancelLoading());
        }

    }, []);

    const cancelLoading = () => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    if (loading) return <Loading />

    return (
        <AppContext.Provider
            value={{
                creator,
                register,
                setRegister,
                handleGoogleLogin,
                disconnectGoogle,
                network: network,
                wallet: wallet,
                buttonText: buttonText,
                provider: provider,
                balance: balance,
                signer: signer1,
                opacity,
                connection: connection,
                layoutHeader,
                priceCoin: JSON.parse(priceCoin),
                connectWallet: connectWallet,
                disconnectWallet: disconnectWallet,
                getBalance: getBalance,
                walletAddress,
                updateDataCreator: updateCreator
            }}>
            {props.children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext);
